import { useAppSelector } from 'src/store/hooks'

const useNotification = () => {
  const notification = useAppSelector((state) => state.message)
  return { notification }
}
// const notificationState = useAppSelector((state) => state.message)
const NotificationHook = {
  // notificationState,
  useNotification,
}
export default NotificationHook
