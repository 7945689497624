import { HttpResponseSchema } from 'src/shared/http/data'
import { MetaSchema } from 'src/shared/http/data/meta-data'
import { z } from 'zod'

const module = 'sub-theme'

export const SubThemeItemSchema = z.object({
  id: z.number().nullable().default(null),
  code: z.string().default(''),
  name: z.string().default(''),
  description: z.string().default(''),
})
export const SubThemeSchema = z.object({
  data: z.array(SubThemeItemSchema).default([]),
  meta: MetaSchema.default({}),
  http: HttpResponseSchema.default({}),
})
// export type TSubThemeList = {
//   payload: {
//     data: any[]
//   }
// }
export type TSubThemeList = z.infer<typeof SubThemeSchema>
export type TSubThemeItem = z.infer<typeof SubThemeItemSchema>
export const ENDPOINT = {
  SUB_THEME: `${module}`,
}

export const STATE = {
  SUB_THEME: 'sub-theme',
}
