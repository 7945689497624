import { ConferenceItemSchema } from 'src/pages/conference/data'
import { HttpResponseSchema } from 'src/shared/http/data'
import { z } from 'zod'
import { UserAccountStatusSchema } from './account-status.data'

const module = 'users'
const moduleAddress = 'address-book'

export const RoleSchema = z.object({
  id: z.number().nullable().default(null),
  createdAt: z.string().default(''),
  updatedAt: z.string().optional().default(''),
  active: z.boolean().default(false),
  code: z.string().default(''),
  name: z.string().default(''),
  description: z.string().default(''),
  conference: ConferenceItemSchema.default({}),
  userAccountStatus: UserAccountStatusSchema.default({}),
})

export const UserProfileSchema = z.object({
  id: z.number().nullable().default(null),
  createdAt: z.string().optional().default(''),
  updatedAt: z.string().optional().default(''),
  type: z.string().default(''),
  code: z.string().default(''),
  firstName: z.string().default(''),
  lastName: z.string().default(''),
  phone: z.string().optional().default(''),
  email: z.string().default(''),
  affiliation: z.string().optional().default(''),
})

export const AddressSchema = z.object({
  id: z.number().nullable().default(null),
  createdAt: z.string().optional().default(''),
  updatedAt: z.string().optional().default(''),
  label: z.string().optional().default(''),
  addressLine1: z.string().optional().default(''),
  addressLine2: z.string().optional().default(''),
  addressLine3: z.string().optional().default(''),
  city: z.string().default(''),
  postcode: z.string().default(''),
  state: z.string().default(''),
  country: z.string().default(''),
})

export const UserSchema = z.object({
  id: z.number().nullable().default(null),
  createdAt: z.string().default(''),
  updatedAt: z.string().optional().default(''),
  user: UserProfileSchema.default({}),
  role: z.array(RoleSchema).default([]),
  address: z.array(AddressSchema).default([]),
  activeRole: RoleSchema.default({}),
  http: HttpResponseSchema.default({}),
})

export type TRole = z.infer<typeof RoleSchema>
export type TUser = z.infer<typeof UserSchema>
export type TAddress = z.infer<typeof AddressSchema>
export const UserState: TUser = UserSchema.parse({})

export const STATE = {
  USER_DETAILS: 'user/profile',
  USER_ROLES: 'user/roles',
  USER_ACCOUNT_STATUS_LIST: 'user/user-account-status/list',
  USER_ADDRESS: 'user/address',
  USER_ACCOUNT: 'user/account',
  USER_ACCOUNT_UPDATE: 'user/account/update',
  USER_PASSWORD: 'user/update-password',
  USER_ACCOUNT_DELETE: 'user/account/delete',
}

export const ENDPOINT = {
  USER_DETAILS: `${module}`,
  USER_ROLES: `${module}/user-role`,
  USER_ACCOUNT_STATUS_LIST: `user-account-status`,
  USER_ADDRESS: `${moduleAddress}`,
  USER_PASSWORD: `${module}/:id/update-password`,
}
