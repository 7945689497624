import { zodResolver } from '@hookform/resolvers/zod'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { EvaluationItemSchema, TSubmissionItem } from '../../data'
import Button from 'src/packages/core/button'
import { useAppDispatch, useAppSelector } from 'src/store/hooks'
import { getAbstractSubmissionScore, getFullPaperSubmissionScore, updateFullPaperSubmissionScore, updateSubmissionScore } from '../../state'
import { ROUTE_PATH, SHARED } from 'src/shared/data'
import { useNavigate } from 'react-router-dom'
import NotificationBar from 'src/packages/product/notification'
import NotificationHook from 'src/shared/service/notification.hook'
import { setToast } from '@/packages/product/toast/state'
import { finishLoading, startLoading } from '@/packages/product/full-page-loader/state'

type TEvaluationSectionProps = {
  submission: TSubmissionItem
  paperType: string
  notification?: any
}

const EvaluationSection = ({ submission, paperType }: TEvaluationSectionProps) => {
  const { abstractScore, fullPaperScore } = useAppSelector((state) => state.submission)
  const dispatch = useAppDispatch()
  const { useNotification } = NotificationHook
  const { notification } = useNotification()

  const navigate = useNavigate()

  const defaultFormValues = {
    paperId: submission.id ?? 0,
    score: 0,
    description: '',
  }

  useEffect(() => {
    const getScore = async () => {
      if (!submission.id) return
      const scores: any = await (paperType === SHARED.PAPER_TYPE.ABS
        ? dispatch(getAbstractSubmissionScore(submission.id))
        : dispatch(getFullPaperSubmissionScore(submission.id)))
      if (scores.payload) {
        const newDefaultFormValues = {
          paperId: scores.payload.paper?.id,
          score: scores.payload.score,
          description: scores.payload.description,
        }
        reset(newDefaultFormValues)
      }
    }
    getScore()
    console.log('notificaiton nin', notification)
  }, [submission.id])

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm({ resolver: zodResolver(EvaluationItemSchema), defaultValues: defaultFormValues })

  const styles = {
    title: 'font-bold text-2xl pt-8 pb-4',
    caption: 'text-sm text-gray-500',
  }

  const onSubmit = async (data: any) => {
    if (!submission || !data) return
    console.log('submit', data)
    dispatch(startLoading())
    const result = await (paperType === SHARED.PAPER_TYPE.ABS
      ? dispatch(updateSubmissionScore(data))
      : dispatch(updateFullPaperSubmissionScore(data)))
    console.log('full res', result)
    if (result.meta.requestStatus === 'fulfilled') {
      dispatch(setToast({ message: 'Paper updated successfully', variant: 'success' }))
      navigate(ROUTE_PATH.SUBMISSION_LIST)
    } else {
      dispatch(setToast({ message: 'Paper update failed', variant: 'error' }))
    }
    dispatch(finishLoading())
  }

  const handleDisable = () => {
    if (paperType === SHARED.PAPER_TYPE.ABS) {
      return (
        abstractScore?.reviewStatus?.code === SHARED.REVIEW_STATUS.REVIEWED ||
        abstractScore?.reviewStatus?.code === SHARED.REVIEW_STATUS.ACCEPTED ||
        abstractScore?.reviewStatus?.code === SHARED.REVIEW_STATUS.REJECTED
      )
    } else {
      return (
        fullPaperScore?.reviewStatus?.code === SHARED.REVIEW_STATUS.REVIEWED ||
        fullPaperScore?.reviewStatus?.code === SHARED.REVIEW_STATUS.ACCEPTED ||
        fullPaperScore?.reviewStatus?.code === SHARED.REVIEW_STATUS.REJECTED
      )
    }
  }

  return (
    <>
      {notification && <NotificationBar notification={notification} includeOrigin="submission-score" />}
      <h2 className={styles.title}>Evaluation</h2>
      <p className={styles.caption}>
        Please provide a detailed review, including a justification for your scores.
        <br /> Both the score and the review text are required.
      </p>
      <form className="mt-4 rounded-md" onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col">
          <input type="hidden" {...register('paperId', { valueAsNumber: true })} value={defaultFormValues.paperId} />
          <label htmlFor="score" className="font-bold">
            Percentage
          </label>
          <div className="flex text-black">
            <input
              type="number"
              className="w-1/6 px-4 py-2 mt-2 border border-gray-300 rounded-md disabled:bg-slate-100 "
              min={0}
              max={100}
              {...register('score', { valueAsNumber: true })}
              defaultValue={defaultFormValues.score}
              disabled={handleDisable()}
            />
            <span className="flex items-center ml-2">%</span>
            <br />
          </div>
          <div>{errors.score && <span className="text-red-600 text-sm border-0">{errors.score.message}</span>}</div>
        </div>
        <div className="flex flex-col mt-4">
          <label htmlFor="description" className="font-bold">
            Detailed Review
          </label>
          <textarea
            className="w-full px-4 py-2 mt-2 border border-gray-300 rounded-md h-36 disabled:bg-slate-100 text-black"
            placeholder="Write your detailed review"
            {...register('description')}
            defaultValue={defaultFormValues.description}
            disabled={handleDisable()}
          />
        </div>
        <div>{errors.description && <span className="text-red-600 text-sm border-0">{errors.description.message}</span>}</div>
        <div className="flex mt-4">
          {!handleDisable() && (
            <Button type="submit" className="mt-6">
              Save
            </Button>
          )}
        </div>
      </form>
    </>
  )
}

export default EvaluationSection
