const countWords = (str: string): number => {
  if(!str) return 0
  // Trim the string to remove leading and trailing white space
  const trimmedStr = str.trim()

  // Split the trimmed string into an array of words
  const words = trimmedStr.split(/\s+/)

  // Return the number of words in the array
  return words.length
}
const StringHelper = {
  countWords,
}
export default StringHelper
