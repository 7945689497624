import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'src/store/hooks'
import { TReviewerProfile } from '../data'
import { getReviewerDetail } from '../state'
import { setMessage } from 'src/packages/product/notification/state'
import { useMatch } from 'react-router-dom'
import { ROUTE_PATH, SHARED } from 'src/shared/data'
import { HTTP_STATUS } from 'src/shared/http/data'

const useHook = () => {
  const { id } = useParams<{ id: string }>()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [reviewer, setReviewer] = useState<TReviewerProfile>()
  const params = useParams()
  const matchPath = useMatch(ROUTE_PATH.REVIEWER_ADD)
  const useNotification = useAppSelector((state) => state.message)
  const { http } = useAppSelector((state) => state.reviewer)

  const useFetchData = () => {
    useEffect(() => {
      let reviewer: any
      const getReviewerDetailAsync = async () => {
        if (!id) return dispatch(setMessage('No id found'))
        reviewer = await dispatch(getReviewerDetail(+id))
        // if (!reviewer.payload) dispatch(setMessage('No records found'))
        console.log('reviewer payload', http)
        if (http.status === HTTP_STATUS.SUCCESS) setReviewer(reviewer.payload)
      }
      if (!matchPath) getReviewerDetailAsync()
      return () => {
        reviewer
      }
    }, [dispatch])
  }

  const useBack = () => {
    navigate(-1) // Navigate back to the previous page
  }

  return { useFetchData, useBack, reviewer, params, useNotification, matchPath }
}

export const ReviewerDetailHook = {
  useHook,
}
