import React, { useEffect, useMemo, useState } from 'react'
import { levelColor } from 'src/shared/helper/level.helper'
import { useAppDispatch, useAppSelector } from 'src/store/hooks'
import { getReviewerAssignmentList } from '../../state'
import moment from 'moment'
import { SHARED } from 'src/shared/data'
type ReviewSummarySectionProps = {
  paperType: string
}

const ReviewSummarySection = ({ paperType }: ReviewSummarySectionProps) => {
  const { reviewingSubmission, reviewerAssignmentList } = useAppSelector((state) => state.submission)
  const [filteredReviewerList, setFilteredReviewerList] = useState([] as any[])
  const dispatch = useAppDispatch()
  useEffect(() => {
    if (reviewingSubmission?.id) {
      dispatch(getReviewerAssignmentList({ paperId: reviewingSubmission.id, type: 'matching' }))
    }
  }, [reviewingSubmission])

  useEffect(() => {
    const res = reviewerAssignmentList.filter((item) => {
      return item.review.some((review) => review.paperType.code === paperType)
    })

    setFilteredReviewerList(res)
  }, [paperType, reviewerAssignmentList])
  const styles = {
    table: 'w-full mt-6 border border-gray-200 rounded-lg border-separate text-black',
    title: 'font-bold text-2xl pt-8 pb-4',
    tableRow: 'border border-gray-100',
    tableData: 'p-4 border-b border-gray-100 align-top text-left text-black',
  }

  const abstractLevel = (type: 'score' | 'level') => {
    if (!reviewingSubmission) return null
    const { review, absTotalScore, absTotalLevel } = reviewingSubmission
    if (review?.length === 0) return null
    console.log('get abstract level', reviewingSubmission, absTotalScore)

    return type === 'level' ? levelColor(absTotalLevel!) : `${absTotalScore}%`
  }

  return (
    <>
      {paperType === SHARED.PAPER_TYPE.ABS && (
        <table className={`${styles.table}`}>
          <tbody>
            <tr>
              <td className={`font-bold w-1/6 ${styles.tableData}`}>Title</td>
              <td className={`${styles.tableData}`}>{reviewingSubmission.title}</td>
            </tr>
            <tr>
              <td className={`font-bold w-1/6 ${styles.tableData}`}>Abstract</td>
              <td className={`${styles.tableData}`}>{reviewingSubmission.abstract}</td>
            </tr>
            <tr>
              <td className={`font-bold w-1/6 ${styles.tableData}`}>Level</td>
              <td className={`${styles.tableData}`}>{abstractLevel('level')}</td>
            </tr>
            <tr>
              <td className={`font-bold w-1/6 ${styles.tableData}`}>Average Score</td>
              <td className={`${styles.tableData}`}>{abstractLevel('score')}</td>
            </tr>
          </tbody>
        </table>
      )}

      <h2 className="text-2xl font-bold pt-10 text-black">Summary of Received Reviews and Comments</h2>
      {/* <p className=" text-black">Reviews superseded by other reviews are shown in grey color in the table. All times are ICT.</p> */}
      {filteredReviewerList.map((assignment, index) => {
        return (
          <table key={index} className={`${styles.table}`}>
            <tbody>
              <tr>
                <td className={`font-bold w-1/6 ${styles.tableData}`}>Reviewer</td>
                <td>
                  {assignment.firstName} {assignment.lastName}
                </td>
              </tr>
              <tr>
                <td className={`font-bold w-1/6 ${styles.tableData}`}>Time</td>
                <td>
                  {assignment.review.map((review: any, index: number) => {
                    if (review.paperType.code === paperType) {
                      return (
                        <div key={index}>
                          <p>{review.completed_date ? moment(review.completed_date).format('MMM DD YYYY, hh:mm A') : '-'}</p>
                        </div>
                      )
                    }
                  })}
                </td>
              </tr>
              <tr>
                <td className={`font-bold w-1/6 ${styles.tableData}`}>Score</td>
                <td>
                  {assignment.review.map((review: any, index: number) => {
                    if (review.paperType.code === paperType) {
                      return (
                        <div key={index}>
                          <p>{review.score ? review.score + '%' : '-'}</p>
                        </div>
                      )
                    }
                  })}
                </td>
              </tr>
              <tr>
                <td className={`font-bold w-1/6 ${styles.tableData}`}>Detailed Review</td>
                <td>
                  {assignment.review.map((review: any, index: number) => {
                    if (review.paperType.code === paperType) {
                      return (
                        <div key={index}>
                          <p>{review.description}</p>
                        </div>
                      )
                    }
                  })}
                </td>
              </tr>
            </tbody>
          </table>
        )
      })}
    </>
  )
}

export default ReviewSummarySection
