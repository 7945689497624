import React, { PropsWithChildren, memo, useState } from 'react'
import { NavLink } from 'react-router-dom'
import Icon from '@hubz/core-icon'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline'
import { TMenuList } from './data'

type SidebarProps = PropsWithChildren<{
  menus: TMenuList[]
  user: any
}>

const Sidebar = ({ menus, user }: SidebarProps) => {
  const [open, setOpen] = useState(true)
  const [menuChildOpen, setMenuChildOpen] = useState(false)
  const hasChildren = (menu: any) => {
    return menu.children && menu.children.length > 0
  }

  const renderMenuItem = (menu: any, i: number) => {
    const isExpanded = menuChildOpen && (!menu.children || menu.children.every((child: any) => !child.active))

    return (
      <div key={i}>
        {/* collapsable menu item*/}
        {hasChildren(menu) ? (
          <div
            className={`${menu?.margin ? 'mt-5' : ''}
              group flex items-center text-sm  gap-3.5 font-medium p-3 text-black hover:text-primary hover:bg-primary-600 rounded-md cursor-pointer`}
            onClick={() => setMenuChildOpen(!menuChildOpen)}
          >
            <div className="flex items-center">{menu.icon}</div>
            <h2
              style={{ transitionDelay: `${i + 2}0ms` }}
              className={`whitespace-pre duration-500 ${!open && 'opacity-0 translate-x-28 overflow-hidden'}`}
            >
              {menu.name}
            </h2>
            <Icon css="justify-self-end" icon={isExpanded ? <ChevronUpIcon className="w-5" /> : <ChevronDownIcon className="w-5" />} />
          </div>
        ) : (
          <NavLink
            to={menu.link}
            className={({ isActive }) => `${isActive ? 'bg-primary text-white hover:bg-opacity-70' : ''} ${menu?.margin ? 'mt-5' : ''}
              group flex items-center text-sm  gap-3.5 font-medium p-3 text-black hover:text-white  hover:bg-primary/90 rounded-md`}
          >
            <div className="flex items-center">{menu.icon}</div>
            <h2
              style={{ transitionDelay: `${i + 2}0ms` }}
              className={`whitespace-pre duration-500 ${!open && 'opacity-0 translate-x-28 overflow-hidden'}`}
            >
              {menu.name}
            </h2>
          </NavLink>
        )}

        {/* Render child menus recursively */}
        {hasChildren(menu) && (
          <div className={`${isExpanded ? 'block' : 'hidden'} ml-8 pt-2`}>
            {menu.children.map((childMenu: any, j: any) => renderMenuItem(childMenu, j))}
          </div>
        )}
      </div>
    )
  }

  return (
    <section className="flex gap-6">
      <div className={`min-h-screen ${open ? 'w-72' : 'w-16'} duration-500 px-4`}>
        <div className="py-3 flex ">
          <img src={require('src/shared/assets/seaair_logo.png')} className="logo w-40" />
        </div>
        <div>
          {user && (
            <div className="flex items-center justify-between py-3 pl-4">
              <div className="gap-3">
                <h2 className="text-md font-medium">
                  {user.user.firstName} {user.user.lastName}
                </h2>
                <p className="text-sm text-gray-400">{user.role.name}</p>
              </div>
            </div>
          )}
        </div>
        <div className="mt-4 flex flex-col gap-4 relative">{menus?.map((menu, i) => renderMenuItem(menu, i))}</div>
        <div className="absolute bottom-0 left-0 p-4 text-sm text-gray-400">v{process.env.REACT_APP_VERSION}</div>
      </div>
    </section>
  )
}

export default memo(Sidebar)
