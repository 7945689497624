import React from 'react'
import EvaluationSection from './evaluation'
import { TSubmissionItem } from '../../data'
import FullPaperScoreGuidelineSection from './full-paper-score-guideline'
import { SHARED } from 'src/shared/data'
import { useAppSelector } from '@/store/hooks'

type TSubmissionAbstractSectionProps = {
  submission: TSubmissionItem
  notification?: any
}

const SubmissionFullPaperSection = ({ submission, notification }: TSubmissionAbstractSectionProps) => {
  const { fullPaperFiles } = useAppSelector((state) => state.submission)
  const styles = {
    title: 'font-bold text-2xl pt-8 pb-4',
    tableRow: 'border border-gray-100',
    tableData: 'p-4 border-b border-gray-100',
  }

  const hasFullPaper = Array.isArray(fullPaperFiles) && fullPaperFiles.length > 0

  return (
    <div>
      <table className="w-full mt-6 border border-gray-200 rounded-lg border-separate">
        <tbody>
          <tr>
            <td className={`w-1/6 font-bold ${styles.tableData}`}>Title</td>
            <td className={` ${styles.tableData} text-black`}>{submission.title}</td>
          </tr>
        </tbody>
      </table>
      <h2 className={styles.title}>SEAAIR Guidelines for evaluation of Quality of Paper</h2>
      <FullPaperScoreGuidelineSection />
      {hasFullPaper ? (
        <EvaluationSection submission={submission} paperType={SHARED.PAPER_TYPE.FP} notification={notification} />
      ) : (
        <h2 className="text-2xl font-bold pt-4">Pending author to submit full paper...</h2>
      )}
    </div>
  )
}

export default SubmissionFullPaperSection
