/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useEffect, useRef } from 'react'
import Form from '@hubz/core-form'
import Button from '@hubz/core-button'
import { ACCOUNT_INFO_FORM, ReviewerAccountInfoSchema, defaultValues } from '../../data/manage-reviewer-account.data'
import ProfileHook from '../../service/profile.hook'
import NotificationHook from 'src/shared/service/notification.hook'
import NotificationBar from 'src/packages/product/notification'

export const AccountInfoFormSection = ({ data }: any) => {
  const { useHook } = ProfileHook
  const { useFetchData, conference, roles, userAccountStatus, useUpdate, useCreate, useBack } = useHook()
  const { useNotification } = NotificationHook
  const { notification } = useNotification()

  useFetchData()

  const myRef = useRef(null)
  console.log('data', data, notification)
  const hasAddress = () => {
    return data?.addressBook && data?.addressBook.length > 0
  }
  if (typeof data == 'object') {
    defaultValues.roleManagement = [{ conference: '', role: '', userAccountStatus: '' }]
    defaultValues.firstName = data?.firstName
    defaultValues.lastName = data?.lastName
    defaultValues.email = data?.email
    defaultValues.phone = data?.phone
    defaultValues.gender = data?.gender
    defaultValues.addressLine1 = hasAddress() ? data?.addressBook[0]?.addressLine1 : ''
    defaultValues.addressLine2 = hasAddress() ? data?.addressBook[0]?.addressLine2 : ''
    defaultValues.city = hasAddress() ? data?.addressBook[0]?.city : ''
    defaultValues.postcode = hasAddress() ? data?.addressBook[0]?.postcode : ''
    defaultValues.country = hasAddress() ? data?.addressBook[0]?.country : ''
    defaultValues.affiliation = data?.affiliation
  }

  const rest = data?.userRole
    ? data.userRole.map((item: any) => {
        return {
          conference: item.conference,
          role: item.role,
          userAccountStatus: item.userAccountStatus,
        }
      })
    : []
  defaultValues.roleManagement = [...defaultValues.roleManagement, ...rest]

  useEffect(() => {
    if (myRef.current && notification) (myRef.current as HTMLElement)?.scrollIntoView({ behavior: 'smooth' })
  }, [notification])

  ACCOUNT_INFO_FORM.forEach((item) => {
    item.columns.forEach((column: any) => {
      if (column.type === 'dynamic') {
        column.options = { conference: conference, role: roles, userAccountStatus: userAccountStatus } // Fix: Add 'options' property to column object type definition
      }
    })
  })
  return (
    <div className="pb-10">
      <div ref={myRef} className="scroll-mt-2 mt-2"></div>
      <NotificationBar notification={notification} includeOrigin="user-account, reviewer" />
      <Form
        onSubmit={data ? useUpdate : useCreate}
        sections={ACCOUNT_INFO_FORM}
        button={<Button type="submit">Save</Button>}
        schema={ReviewerAccountInfoSchema}
        defaultValues={defaultValues}
      >
        <Button type="button" fill="outline" className="mr-6 mt-4" onClick={useBack}>
          Back
        </Button>
      </Form>
    </div>
  )
}
