import axios, { AxiosInstance, AxiosPromise } from 'axios'
import { ERROR_CODE, HTTP_STATUS, THttpConfig, THttpService } from '../data'
import store from 'src/store/store'
import { openAlertModal } from '@/packages/product/alert-dialog/state'
import { API_URL } from '@/shared/data'
import { ENDPOINT as AUTH_ENDPOINT } from '@/shared/auth/data'
import { forbiddenRoute, setAlert } from '@/packages/product/alert/state'

export class HttpService<T> implements THttpService<T> {
  private controller = new AbortController()
  // private axiosInstance = axios.create()
  private axiosInstance: AxiosInstance

  constructor() {
    this.axiosInstance = axios.create()
    this.addResponseInterceptor()
  }

  private addResponseInterceptor(): void {
    this.axiosInstance.interceptors.response.use(
      (response) => response,
      async (error) => {
        console.log('error', error.response)
        this.doHttpErrorProcess(error)

        return Promise.reject(error)
      }
    )
  }

  async get(url: string, config?: THttpConfig): AxiosPromise<T> {
    const defaultOptions = {
      withCredentials: true,
    }
    config = { ...defaultOptions, ...config, signal: this.controller.signal }
    return this.axiosInstance.get(url, config)
  }

  async post(url: string, data?: T, config?: THttpConfig): AxiosPromise<T> {
    const defaultOptions = {
      withCredentials: true,
    }
    config = { ...defaultOptions, ...config, signal: this.controller.signal }
    return this.axiosInstance.post(url, data, config)
  }

  async patch(url: string, data?: T, config?: THttpConfig): AxiosPromise<T> {
    const defaultOptions = {
      withCredentials: true,
    }
    config = { ...defaultOptions, ...config, signal: this.controller.signal }
    return this.axiosInstance.patch(url, data, config)
  }

  async delete(url: string, config?: THttpConfig): AxiosPromise<T> {
    const defaultOptions = {
      withCredentials: true,
    }
    config = { ...defaultOptions, ...config, signal: this.controller.signal }
    return this.axiosInstance.delete(url, config)
  }

  async getRefreshToken(error: any) {
    const setRefreshToken = await this.get(`${API_URL}/${AUTH_ENDPOINT.REFRESH_TOKEN}`)
    if ((setRefreshToken?.data as any)?.status?.toLowerCase() === HTTP_STATUS.SUCCESS) {
      // retry previous request
      return await this.axiosInstance.request(error.config)
    }
    return
  }

  doHttpErrorProcess(error: any) {
    // Token related validation
    if (error.response && error.response.data?.errorCode) {
      const errorCode = error.response.data.errorCode
      console.log('error code', errorCode)
      if (errorCode === ERROR_CODE.MISSING_AUTH_TOKEN) {
        return this.getRefreshToken(error)
      }
      if (
        errorCode === ERROR_CODE.TOKEN_INVALID ||
        errorCode === ERROR_CODE.TOKEN_EXPIRED ||
        errorCode === ERROR_CODE.INVALID_REFRESH_TOKEN ||
        errorCode === ERROR_CODE.MISSING_REFRESH_TOKEN ||
        errorCode === ERROR_CODE.MISSING_AUTH_TOKEN
      ) {
        console.log('error code', error.response.data)
        store.dispatch(openAlertModal({ open: true, ...error.response.data }))
      }
    }
    // Token related validation: end
    if (error?.response?.status === 403) {
      console.log('do 403')
      store.dispatch(setAlert({ variant: 'destructive', message: 'You are not authorized to access this resource', title: 'Forbidden' }))
      store.dispatch(forbiddenRoute({ status: 403, originPath: window.location.origin }))
    }
  }
}
