import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { setMessage } from '@hubz/product-notification/state'
import { STATE } from 'src/pages/login/data'
import { HTTP_STATUS } from 'src/shared/http/data'
import { AUTH_STATE, AuthState, TAuthState } from 'src/shared/auth/data'
import LoginService from 'src/pages/login/service'
import { AuthService } from '../service'
import { TSelectRole } from '../data/select-role.data'

export const doLogin = createAsyncThunk(STATE.LOCAL_LOGIN, async (data: any, thunkAPI) => {
  try {
    return (await LoginService.login(data)).data
  } catch (error: any) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    thunkAPI.dispatch(setMessage({ message: message, origin: 'login', status: 'error' }))
    return thunkAPI.rejectWithValue(message)
  }
})

export const setUserRole = createAsyncThunk(AUTH_STATE.AUTH_SET_ACTIVE_ROLE, async (data: TSelectRole, thunkAPI) => {
  try {
    return (await AuthService.selectRole(data)).data
  } catch (error: any) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    thunkAPI.dispatch(setMessage({ message: message, origin: 'user', status: 'error' }))
    return thunkAPI.rejectWithValue(message)
  }
})

export const setRefreshToken = createAsyncThunk(AUTH_STATE.AUTH_SET_REFRESH_TOKEN, async (_, thunkAPI) => {
  try {
    return (await AuthService.refreshToken()).data
  } catch (error: any) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    thunkAPI.dispatch(setMessage({ message: message, origin: 'auth', status: 'error' }))
    return thunkAPI.rejectWithValue(message)
  }
})

export const authSlice = createSlice({
  name: 'auth',
  initialState: AuthState,
  reducers: {
    logout: (state, action) => {
      console.log('logout', state, action)
      state.isAuth = false
      // return
    },
    triggerRefreshToken: (state) => {
      console.log('trigger refresh token', state)
      state.getRefreshToken = state.getRefreshToken ? state.getRefreshToken + 1 : state.getRefreshToken
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(doLogin.pending, (state) => {
        state.status = HTTP_STATUS.LOADING
      })
      .addCase(doLogin.fulfilled, (state: TAuthState, action: any) => {
        console.log('action', state, action.payload)
        state.message = action.payload.message
        state.status = HTTP_STATUS.SUCCESS
        state.isAuth = true
        state.id = action.payload.data.id
        state.iat = action.payload.data.iat
        console.log('state', state.isAuth)
      })
      .addCase(doLogin.rejected, (state, action) => {
        state.status = HTTP_STATUS.FAIL
        console.log('error msg', action.error)
        // state.error = action.error.message;
      })
      .addCase(setUserRole.fulfilled, (state: TAuthState, action: PayloadAction<any, any>) => {
        console.log('set selected user role', action)
        state.status = HTTP_STATUS.SUCCESS
      })
      .addCase(setUserRole.rejected, (state: TAuthState, action: PayloadAction<any, any>) => {
        console.log('set selected user role failed', action.payload)
        state.status = HTTP_STATUS.FAIL
      })
      .addCase(setRefreshToken.fulfilled, (state: TAuthState, action: PayloadAction<any, any>) => {
        console.log('refresh token', action)
        state.status = HTTP_STATUS.SUCCESS
        // state.getRefreshToken = false
      })
      .addCase(setRefreshToken.rejected, (state: TAuthState, action: PayloadAction<any, any>) => {
        console.log('refresh token failed', action.payload)
        state.message = action.payload.message
        state.isAuth = false
        state.status = HTTP_STATUS.FAIL
      })
  },
})
export const { logout, triggerRefreshToken } = authSlice.actions
const { reducer } = authSlice
export default reducer
