import { createAsyncThunk } from '@reduxjs/toolkit'
import { STATE } from '../data'
import SubThemeService from '../service'
import { setMessage } from 'src/packages/product/notification/state'

export const getSubThemeList = createAsyncThunk(STATE.SUB_THEME, async (_, thunkAPI) => {
  try {
    return (await SubThemeService.getSubThemeList()).data
  } catch (error: any) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    thunkAPI.dispatch(setMessage({ message: message, origin: 'sub-theme', status: 'error' }))
    return thunkAPI.rejectWithValue(message)
  }
})

export const getSubThemeListReducer = () => ({
  [`${STATE.SUB_THEME}/fulfilled`]: (state: any, action: any) => {
    state.subThemeList = action
  },
})
