import React from 'react'
import Button from 'src/packages/core/button'
import { Modal } from '@hubz/product-modal'
import { useAppSelector } from 'src/store/hooks'
import { TReviewerAssignmentItem, TReviewerAssignmentList, TSubmissionItem } from '../data'
import { SHARED } from 'src/shared/data'
import { XMarkIcon } from '@heroicons/react/24/outline'
import Icon from '@/packages/core/icon'

type TReviewerAssignmentModalProps = {
  handleAssigneeModalToggle: (item?: any) => void
  handleAssigneeSubmit: (paperId: number, reviewer: any) => void
  isOpen: boolean
  selectedPaper: TSubmissionItem
  useFormObject: any
}

const ReviewerAssignmentModal = ({
  isOpen,
  handleAssigneeModalToggle,
  handleAssigneeSubmit,
  selectedPaper,
  useFormObject,
}: TReviewerAssignmentModalProps) => {
  const { reviewerAssignmentList } = useAppSelector((state) => state.submission)
  const { register, handleSubmit, reset } = useFormObject
  const styles = {
    title: 'text-2xl font-bold',
    caption: 'text-sm text-danger',
    tableRow: 'border border-gray-100',
    tableData: 'p-2 border-b border-gray-100 text-left',
    checkbox: 'w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500',
    fullPaperAssign: 'text-primary-500',
  }
  const cleanArr = (arr: any[]) => {
    if (!arr) return
    const cleanedArr: any[] = arr?.filter(Boolean).map((item: any) => {
      if (Array.isArray(item)) {
        return cleanArr(item)
      }
      return item
    })
    return cleanedArr.filter((item) => item !== null)
  }
  const handleFormSubmit = (data: any) => {
    let reviewer = cleanArr(data.reviewer)
    reviewer = reviewer && reviewer?.length > 0 ? reviewer.flat() : []

    const selectedReviewersDetails = reviewer.map(({ userId, assignAbstract, assignFullPaper, adminOverride }: any) => ({
      userId: Number(userId),
      assignAbstract,
      assignFullPaper,
      adminOverride,
    }))
    console.log('selected reviewwer', selectedReviewersDetails)
    handleAssigneeSubmit(selectedPaper.id!, selectedReviewersDetails)
  }
  const checkIsAssigned = (reviewObj: TReviewerAssignmentList, paperType: string) => {
    if (reviewObj.review.length === 0) return false
    const reviewer = reviewObj.review.find((item) => {
      return item.paperType.code === paperType && !item.deletedAt
    })
    if (reviewer) return true
  }
  const checkIsAdminOverride = (reviewObj: TReviewerAssignmentList): boolean => {
    if (reviewObj.review.length === 0) return false
    for (let i = 0; i < reviewObj.review.length; i++) {
      if (reviewObj.review[i].adminOverride) return true
    }
    return false
  }
  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={handleAssigneeModalToggle}
        hideOnClickOutside={false}
        css="px-16 py-10"
        closeButtonLabel={<Icon icon={<XMarkIcon className="w-5 mr-2" />} />}
      >
        <h2 className={styles.title}>Assignment Details</h2>
        <p className={styles.caption}>* Red numbers are the number of pre-assignments that are not in the full paper status yet</p>
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <table className="w-full mt-6 border border-gray-200 rounded-lg border-separate">
            <thead>
              <tr className={styles.tableRow}>
                <th className={`w-1/6 font-bold ${styles.tableData}`}>Reviewer</th>
                <th className={`w-1/6 font-bold ${styles.tableData}`}>Assign Abstract</th>
                <th className={`w-1/6 font-bold ${styles.tableData}`}>Assign Full Paper</th>
                <th className={`w-1/6 font-bold ${styles.tableData}`}>Total Abstracts Assigned</th>
                <th className={`w-1/6 font-bold ${styles.tableData}`}>Total Full Papers Assigned</th>
                <th className={`w-1/6 font-bold ${styles.tableData}`}>Administrator Overwrites</th>
              </tr>
            </thead>
            <tbody>
              {reviewerAssignmentList.length > 0 &&
                reviewerAssignmentList.map((item: TReviewerAssignmentList, index: number) => (
                  <tr key={index} className={styles.tableRow}>
                    <td className="hidden">
                      <input type="hidden" {...register(`reviewer[${item.id}][${selectedPaper.id}].userId`)} value={item.id} />
                    </td>
                    <td className={`w-1/6 ${styles.tableData}`}>
                      {item.firstName} {item.lastName}
                    </td>
                    <td className={`w-1/6 ${styles.tableData}`}>
                      <input
                        type="checkbox"
                        className={styles.checkbox}
                        defaultChecked={checkIsAssigned(item, SHARED.PAPER_TYPE.ABS) ?? false}
                        {...register(`reviewer[${item.id}][${selectedPaper.id}].assignAbstract`)}
                      />
                    </td>
                    <td className={`w-1/6 ${styles.tableData}`}>
                      <input
                        type="checkbox"
                        className={styles.checkbox}
                        defaultChecked={checkIsAssigned(item, SHARED.PAPER_TYPE.FP) ?? false}
                        {...register(`reviewer[${item.id}][${selectedPaper.id}].assignFullPaper`)}
                      />
                    </td>
                    <td className={`w-1/6 ${styles.tableData}`}>{item.totalAbstractAssigned}</td>
                    <td className={`w-1/6 ${styles.tableData}`}>
                      {item.totalFullPaperAssigned} <span className={styles.fullPaperAssign}></span>
                    </td>
                    <td className={`w-1/6 ${styles.tableData}`}>
                      <input
                        type="checkbox"
                        className={styles.checkbox}
                        defaultChecked={checkIsAdminOverride(item) ?? false}
                        {...register(`reviewer[${item.id}][${selectedPaper.id}].adminOverride`)}
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          <Button
            onClick={(event) => {
              event.preventDefault()
              handleAssigneeModalToggle()
            }}
            type="button"
            fill="outline"
            className="mt-10 mr-2"
          >
            Cancel
          </Button>
          <Button type="submit">Save</Button>
        </form>
      </Modal>
    </>
  )
}

export default ReviewerAssignmentModal
