import { HttpResponseSchema } from 'src/shared/http/data'
import { z } from 'zod'

export const AccountPasswordSchema = z
  .object({
    password: z.string().min(6).default(''),
    passwordConfirm: z.string().min(6).default(''),
  })
  .merge(HttpResponseSchema)
  .superRefine(({ passwordConfirm, password }, ctx) => {
    if (passwordConfirm !== password) {
      ctx.addIssue({
        code: 'custom',
        message: 'The passwords did not match',
      })
    }
  })

export type TAccountPassword = z.infer<typeof AccountPasswordSchema>

export const ACCOUNT_PASSWORD_FORM = [
  {
    heading: 'Update Password',
    columns: [
      { name: 'password', type: 'password', label: 'New Password', required: true },
      { name: 'passwordConfirm', type: 'password', label: 'Confirm Password', required: true },
    ],
  },
]
