import { useEffect } from 'react'
import { useAppDispatch } from 'src/store/hooks'
import { getReviewerActivity } from '../state'

const useReviewerActivityHook = () => {
  const dispatch = useAppDispatch()

  const useFetchData = () => {
    useEffect(() => {
      let activity: any

      const getReviewerActivityAsync = async () => {
        activity = await dispatch(getReviewerActivity())
        // if (http.status === HTTP_STATUS.SUCCESS) setReviewerActivity(activity.payload)
      }
      getReviewerActivityAsync()
      return () => {
        activity
      }
    }, [])
  }

  return {
    useFetchData,
  }
}

const ReviewerActivityHook = {
  useReviewerActivityHook,
}

export default ReviewerActivityHook
