// import { useRouteError } from "react-router-dom"
import React from "react"
const ErrorPage = () => {
  // const error = useRouteError()

  return (
    <div>
      <h1> Ooops!</h1>
      <p>Sorry, an unexpected error occured.</p>
    </div>
  )
}

export default ErrorPage