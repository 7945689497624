import { HttpResponseSchema } from 'src/shared/http/data'
import { z } from 'zod'

export const STATE = {
  FORGOT_PASSWORD: 'forgotPassword',
}

export const ENDPOINT = {
  FORGOT_PASSWORD: 'users/forget-password/request',
}
export const ForgotPasswordFormSchema = z.object({
  email: z.string().email().nullable().default(null),
})

export const ForgotPasswordStateSchema = z.object({
  email: ForgotPasswordFormSchema.default({}),
  http: HttpResponseSchema.default({}),
  successTime: z.string().optional().default(''),
})

export type TForgotPassword = z.infer<typeof ForgotPasswordStateSchema>
type TForgotPasswordForm = z.infer<typeof ForgotPasswordFormSchema>
export type TForgotPasswordResponse = z.infer<typeof ForgotPasswordStateSchema>
export const ForgotPasswordForm: TForgotPasswordForm = ForgotPasswordFormSchema.parse({})
export const ForgotPasswordState: TForgotPassword = ForgotPasswordStateSchema.parse({})

export const FORGOT_PASSWORD_FORM = [
  {
    columns: [
      {
        name: 'email',
        type: 'email',
        label: 'Email',
      },
    ],
  },
]
