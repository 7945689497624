import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { Variants } from '..'

const alert = createSlice({
  name: 'alert',
  initialState: { message: '', title: '', variant: '', route: { status: 0, originPath: '' } }, // count of active requests
  reducers: {
    setAlert: (state, action: PayloadAction<{ message: string; title: string; variant: Variants }>) => {
      console.log('set alert', action, state)
      state.message = action.payload.message ?? ''
      state.title = action.payload.title ?? ''
      state.variant = action.payload.variant ?? ''
      return state
    },
    forbiddenRoute: (state, action: PayloadAction<{ originPath: string; status: number }>) => {
      console.log('forbidden resource', state, action.payload)
      // TODO: handle forbidden route to navigate back to origin path
      // state.route.originPath = action.payload.originPath
      // state.route.status = action.payload.status
    },
  },
})

export const { setAlert, forbiddenRoute } = alert.actions

export default alert.reducer
