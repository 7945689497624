import React from 'react'
import { LoadingSpinner } from './loading-spinner'
import { AlertDialog, AlertDialogContent } from '@radix-ui/react-alert-dialog'
import { useAppSelector } from 'src/store/hooks'

const FullPageLoader = () => {
  const isLoading = useAppSelector((state: any) => state.loader > 0)
  if (!isLoading) return null

  return (
    <AlertDialog open={isLoading}>
      <AlertDialogContent className="flex absolute h-screen w-screen justify-center items-center bg-black/70 z-50">
        <div className="m-auto w-sc w-3/4 p-5">
          <LoadingSpinner className="text-center m-auto" size={50} />
        </div>
      </AlertDialogContent>
    </AlertDialog>
  )
}

export default FullPageLoader
