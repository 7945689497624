import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { SubmissionDetailHook } from './service'
import { useForm, useFieldArray, Controller } from 'react-hook-form'
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable'
import { defaultValues } from './data'
import NotificationBar from 'src/packages/product/notification'
import NotificationHook from 'src/shared/service/notification.hook'
import DynamicAuthors from './views/dynamic-authors'
import StringHelper from 'src/shared/helper/string.helper'
import { useAppSelector } from '@/store/hooks'
import Button from '@/packages/core/button'
import { SHARED } from '@/shared/data'

const SubmissionDetailPage = () => {
  const { useHook } = SubmissionDetailHook
  const { subTheme, conference, country, useFetchData, useCreate, useUpdate, useUnmount, matchPath } = useHook()
  const { useNotification } = NotificationHook
  const { notification } = useNotification()
  const { reviewingSubmission } = useAppSelector((state) => state.submission)
  const [isNewSubmission, setIsNewSubmission] = useState(false)

  useFetchData()
  useUnmount()
  const [defaultFormValues, setDefaultFormValues] = useState<any>(defaultValues)

  const myRef = useRef(null)
  const { register, handleSubmit, control, setValue, reset } = useForm()

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'paperAuthor',
  })

  useEffect(() => {
    if (reviewingSubmission?.id) {
      setDefaultFormValues(reviewingSubmission)
    } else {
      setDefaultFormValues(defaultValues)
    }
    reset(defaultFormValues)
    setWordCount(StringHelper.countWords(defaultFormValues?.abstract))
    if (!matchPath) {
      setIsNewSubmission(false)
    } else {
      setIsNewSubmission(true)
    }
  }, [matchPath, reviewingSubmission, defaultFormValues])

  const handleCreatableSelectChange = useCallback(
    (name: any, selectedOptions: any) => {
      if (selectedOptions.length > 5) return

      const values = selectedOptions
        ? selectedOptions.map(
            (option: { label: string; value: string; __isNew__?: boolean; name?: string; code?: string; id?: number }) => {
              return { label: option.label ?? option.name, value: option.value ?? option.id }
            }
          )
        : []
      setValue(name, values)
    },
    [setValue]
  )
  const styles = {
    // ensure to reflect with form/render-columns.tsx styles
    form: 'mt-6 grid grid-cols-1 gap-y-4 gap-x-10',
    label: 'block text-md font-medium text-gray-500',
    input:
      'appearance-none text-slate-900 rounded-md block w-full px-3 h-12 sm:text-md focus:outline-none placeholder:text-slate-400 focus:ring-2 focus:ring-sky-500 ring-1 ring-slate-200 border-neutral',
    div: 'py-2',
    addAuthorForm: 'mt-6 grid grid-cols-2 gap-x-10 max-w-screen-md',
    caption: 'text-sm text-gray-500',
    checkboxLabel: 'inline-flex items-center cursor-pointer',
    title: 'font-bold text-2xl',
    tableRow: 'border border-gray-100',
    tableData: 'p-2 border-b border-gray-100 text-black',
  }

  const disableInput = useMemo(() => {
    const sub = defaultFormValues as any
    return (
      sub?.paperType?.code === SHARED.PAPER_TYPE.FP ||
      (sub?.paperStatus?.code !== SHARED.PAPER_STATUS.NEW && sub?.paperType?.code === SHARED.PAPER_TYPE.ABS)
    )
  }, [defaultFormValues])

  const [inputDisabled, setInputDisabled] = useState(false)
  const [wordCount, setWordCount] = useState(0)
  const [abstract, setAbstract] = useState('')

  const handleAbstractChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      const value = event.target.value
      const count = StringHelper.countWords(value)
      if (conference?.submission_abstract_length && count > conference?.submission_abstract_length) {
        setAbstract((previousValue) => previousValue)
      } else {
        setInputDisabled(false)
        setWordCount(count)
        setAbstract(value)
      }
    },
    [conference]
  )
  return (
    <>
      <div ref={myRef} className="scroll-mt-2 mt-2"></div>
      <NotificationBar notification={notification} includeOrigin="submission" />
      <h2 className={styles.title}>{!isNewSubmission ? defaultFormValues.title : 'New Submission'}</h2>
      {!isNewSubmission && (
        <table className="w-full mt-6 border border-gray-200 rounded-lg border-separate">
          <tbody>
            <tr className={styles.tableRow}>
              <td className={`w-1/6 font-bold ${styles.tableData}`}>Submitted</td>
              <td className={`w-5/6 ${styles.tableData}`}>{defaultFormValues ? defaultFormValues.submitted_date : ''}</td>
            </tr>
            <tr className={styles.tableRow}>
              <td className={`w-1/6 font-bold ${styles.tableData}`}>Last updated</td>
              <td className={`w-5/6 ${styles.tableData}`}>{defaultFormValues ? defaultFormValues.updatedAt : ''}</td>
            </tr>
          </tbody>
        </table>
      )}
      <form className="mt-4 rounded-md" onSubmit={handleSubmit(!isNewSubmission ? useUpdate : useCreate)}>
        <p className={styles.caption}>
          Contact the Technical Chair at &nbsp;
          <a href={`mailto:${conference?.email}`} className="underline text-primary-500">
            {`${conference?.email} `}
          </a>
          for all questions about processing your submission
        </p>
        <div className={styles.form}>
          <div className={styles.div}>
            <label htmlFor="title" className={styles.label}>
              Title
            </label>
            <input {...register('title')} className={styles.input} placeholder="Title of Paper" disabled={disableInput} />
          </div>

          <div className={styles.div}>
            <label htmlFor="paperSubTheme" className={styles.label}>
              Sub-theme
            </label>
            <Controller
              name="paperSubTheme"
              control={control}
              defaultValue={defaultFormValues?.paperSubTheme}
              render={({ field }) => (
                <Select
                  isSearchable={false}
                  options={subTheme}
                  placeholder="Select your sub-theme"
                  getOptionLabel={(option: any) => option.name}
                  getOptionValue={(option: any) => option.id}
                  isDisabled={disableInput}
                  {...field}
                />
              )}
            />
          </div>

          <div className={styles.div}>
            <label htmlFor="keyword" className="text-black">
              Keywords <span className="text-sm italic">(maximum of 5 keywords)</span>
            </label>
            <Controller
              name="keyword"
              control={control}
              defaultValue={defaultFormValues?.keyword}
              render={({ field }) => (
                <CreatableSelect
                  isClearable={true}
                  isMulti
                  getOptionLabel={(option: any) => option.name || option.label}
                  getOptionValue={(option: any) => option.id || option.value}
                  placeholder="Type something and press enter..."
                  isDisabled={disableInput}
                  {...field}
                  onChange={(selectedOptions) => handleCreatableSelectChange('keyword', selectedOptions)}
                />
              )}
            />
          </div>

          <div className={styles.div}>
            <label htmlFor="abstract" className={styles.label}>
              Abstract
            </label>
            <textarea
              rows={35}
              {...register('abstract')}
              className={`${styles.input} h-36 disabled:bg-gray-100`}
              placeholder="Write your abstract"
              // maxLength={conference?.submission_abstract_length ?? 0}
              value={abstract}
              onChange={handleAbstractChange}
              disabled={disableInput || inputDisabled}
            />
            <p className={`text-sm float-right italic ${wordCount === conference?.submission_abstract_length ? 'text-primary-400' : ''}`}>
              Words: {wordCount} / {conference?.submission_abstract_length}
            </p>
          </div>
        </div>

        <hr className="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700" />

        <DynamicAuthors
          useFieldArrayObject={{ fields, append, remove }}
          useFormObject={{ setValue, register }}
          styles={styles}
          values={{ country, submission: defaultFormValues }}
          disableInput={disableInput}
          conference={conference}
        />
        {!disableInput && (
          <Button type="submit" className="mt-6">
            Save
          </Button>
        )}
      </form>
    </>
  )
}

export default React.memo(SubmissionDetailPage)
