import React, { PropsWithChildren, memo } from 'react'

type Status = 'success' | 'error' | 'neutral'
type Colors = {
  [key in Status]: { bg: string; text: string }
}
type NotificationItem = {
  message: string[]
  status?: Status | string
  origin: string
}
type NotificationProps = PropsWithChildren<{
  notification: NotificationItem
  includeOrigin: string
}>

const NotificationBar = ({ notification: { message, status = 'neutral', origin }, includeOrigin }: NotificationProps) => {
  const statusColor: Colors = {
    success: { bg: 'bg-green-500', text: 'text-white' },
    error: { bg: 'bg-red-700', text: 'text-white' },
    neutral: { bg: 'bg-transparent', text: 'text-primary-600' },
  }
  const hasOrigin = includeOrigin
    .split(',')
    .map((item) => item.trim())
    .find((item) => item === origin)

  return (
    <>
      {!hasOrigin ? (
        <div></div>
      ) : (
        <div className={`py-2 px-4 my-2 rounded-md ${(statusColor as any)[status]?.bg}`}>
          {Array.isArray(message) &&
            message.map((item, index) => {
              return (
                <p className={`text-base ${(statusColor as any)[status]?.text}`} key={index}>
                  {item}
                </p>
              )
            })}
        </div>
      )}
    </>
  )
}

export default memo(NotificationBar)
