import { HttpResponseSchema } from 'src/shared/http/data'
import { z } from 'zod'

export const STATE = {
  RESET_PASSWORD: 'resetPassword',
}

export const ENDPOINT = {
  RESET_PASSWORD: 'users/forget-password/update',
}

export const ResetPasswordPayload = z
  .object({
    password: z
      .string()
      .min(8, 'Password must be at least 8 characters long')
      .regex(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/,
        'Password must contain at least one lowercase letter, one uppercase letter, one number, and be at least 8 characters long'
      )
      .default(''),
    passwordConfirm: z
      .string()
      .min(8, 'Password must be at least 8 characters long')
      .regex(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/,
        'Password must contain at least one lowercase letter, one uppercase letter, one number, and be at least 8 characters long'
      )
      .default(''),
    // token: z.string().default(''),
  })
  .superRefine(({ passwordConfirm, password }, ctx) => {
    if (passwordConfirm !== password) {
      ctx.addIssue({
        code: 'custom',
        message: 'The passwords did not match',
        path: ['passwordConfirm'],
      })
    }
  })
export const ResetPasswordSchema = z.object({ token: z.string().default(''), http: HttpResponseSchema.default({}) })
export type TResetPasswordSchema = z.infer<typeof ResetPasswordSchema>
export type TResetPasswordPayload = z.infer<typeof ResetPasswordPayload>
export const ResetPasswordState: TResetPasswordSchema = ResetPasswordSchema.parse({})
// zod schema for reset password where min is 8 characters and at least 1 number, 1 uppercase, 1 lowercase
