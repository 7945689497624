import { API_URL } from 'src/shared/data'
import { HttpService } from 'src/shared/http/service'
import { ENDPOINT } from '../data'

const http = new HttpService()
// client-users?order=DESC&page=1&take=10'
const getReviewerList = async () => {
  return await http.get(`${API_URL}/${ENDPOINT.REVIEWER_LIST}`)
}

const getReviewerDetail = async (id: number) => {
  return await http.get(`${API_URL}/${ENDPOINT.REVIEWER_DETAIL}/${id}`)
}

const getReviewerActivityList = async () => {
  return await http.get(`${API_URL}/${ENDPOINT.REVIEWER_ACTIVITY_LIST}`)
}

const ReviewerService = {
  getReviewerList,
  getReviewerDetail,
  getReviewerActivityList,
}
export default ReviewerService
