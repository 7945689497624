import { z } from 'zod'

const now = new Date()
const minAge = new Date(now.getFullYear() - 99, 0, 1)
const maxAge = new Date(now.getFullYear() - 18, 11, 31)
const dobValidator = (value: any) => {
  if (!value) {
    return true // Allow null and undefined values
  }

  const startDate = new Date(value.startDate)
  if (startDate >= minAge && startDate <= maxAge) {
    return true
  }

  return 'Invalid Date of Birth'
}

const RoleManagementSchema = z.object({
  // conference: z.string().nullable().default(null),
  // role: z.string().nullable().default(null),
  // userAccountStatus: z.string().nullable().default(null),
  conference: z.any().nullable().default(null),
  role: z.any().nullable().default(null),
  userAccountStatus: z.any().nullable().default(null),
})
const UserInfoSchema = z.object({
  firstName: z.string().default(''),
  lastName: z.string().default(''),
  gender: z.string().optional().nullable().default(null),
  email: z.string().email().default(''),
  phone: z.string().nullable().default(''),
  // dob: z.custom(dobValidator).optional().nullable().default(null),
  affiliation: z.string().default(''),
})

const UserAddressSchema = z.object({
  addressLine1: z.string().optional().default(''),
  addressLine2: z.string().optional().default(''),
  city: z.string().default(''),
  postcode: z.string().default(''),
  country: z.string().default(''),
})

export const ReviewerAccountInfoSchema = z.object({
  ...UserInfoSchema.shape,
  ...UserAddressSchema.shape,
  roleManagement: z.array(RoleManagementSchema).default([]),
})
// export const AccountInfoSchema = z.object({
//   user: UserInfoSchema.default({}),
//   address: UserAddressSchema.default({}),
//   roleManagement: z.array(RoleManagementSchema).default([]),
// })
// TODO: find a way to have AccountInfoSchema as {user: user, address: address, roleManagement: roleManagement}
// the zod validation should be on user.name, user.id etc.. if using the above AccountInfoSchema
// then Form should be able to validate nested object. might need to flatten the object first to eg. user.id, because Form can't validate nested object
export type TUserInfo = z.infer<typeof UserInfoSchema>
export type TUserAddress = z.infer<typeof UserAddressSchema>
export type TRoleManagement = z.infer<typeof RoleManagementSchema>
export type TReviewerAccountInfo = z.infer<typeof ReviewerAccountInfoSchema>
// export const AccountInfoState: TAccountInfo = AccountInfoSchema.parse({})
export const ACCOUNT_INFO_FORM = [
  {
    heading: 'Account Info',
    columns: [
      {
        name: 'firstName',
        type: 'text',
        label: 'First Name',
        required: true,
      },
      {
        name: 'lastName',
        type: 'text',
        label: 'Last Name',
        required: true,
      },
      // {
      //   name: 'gender',
      //   type: 'radio',
      //   label: 'Gender',
      //   required: false,
      //   options: [
      //     {
      //       label: 'Male',
      //       value: '1',
      //     },
      //     {
      //       label: 'Female',
      //       value: '2',
      //     },
      //   ],
      // },
      {
        name: 'email',
        type: 'email',
        label: 'Email',
        required: true,
      },
      {
        name: 'phone',
        type: 'tel',
        label: 'Contact Number',
        required: true,
      },
      // {
      //   name: 'dob',
      //   type: 'date',
      //   label: 'Date of Birth',
      //   required: false,
      //   minDate: minAge,
      //   maxDate: maxAge,
      // },
      {
        name: 'affiliation',
        type: 'text',
        label: 'Affiliation',
        required: true,
      },
    ],
  },
  {
    heading: 'Address',
    columns: [
      {
        name: 'addressLine1',
        type: 'text',
        label: 'Address line 1',
        required: false,
      },
      {
        name: 'addressLine2',
        type: 'text',
        label: 'Address Line 2',
        required: false,
      },
      {
        name: 'city',
        type: 'text',
        label: 'City',
        required: true,
      },
      {
        name: 'postcode',
        type: 'text',
        label: 'Postcode',
        required: true,
      },
      {
        name: 'country',
        type: 'text',
        label: 'Country',
        required: true,
      },
    ],
  },
  {
    heading: 'Roles Management',
    columns: [
      {
        name: 'roleManagement',
        type: 'dynamic',
        label: '',
        required: true,
        options: [{ conference: '', role: '', userAccountStatus: '' }],
        // options: {},
      },
    ],
  },
]

export const defaultValues = {
  firstName: '',
  lastName: '',
  gender: null,
  email: '',
  phone: '',
  affiliation: '',
  addressLine1: '',
  addressLine2: '',
  city: '',
  postcode: '',
  country: '',
  roleManagement: [{ conference: '', role: '', userAccountStatus: '' }],
}
//must have default value for dynamic form input
