import { combineReducers, configureStore } from '@reduxjs/toolkit'
import messageReducer from '@hubz/product-notification/state'
import loaderReducer from '@hubz/product-full-page-loader/state'
import sessionStorage from 'redux-persist/lib/storage/session'
import { persistReducer } from 'redux-persist'
import userReducer from '../pages/user/state'
import authReducer from '../shared/auth/state'
import reviewerReducer from '../pages/user/views/reviewer/state'
import conferenceReducer from '../pages/conference/state'
import submissionReducer from '../pages/submission/state'
import sharedReducer from '../shared/state'
import reportReducer from '../pages/report/state'
import forgotPasswordReducer from '../pages/forgot-password/state'
import resetPasswordReducer from '../pages/reset-password/state'
import alertDialogReducer from '@hubz/product-alert-dialog/state'
import alertReducer from '@hubz/product-alert/state'
import toastReducer from '@hubz/product-toast/state'
import emailTemplateReducer from '../pages/user/views/email-template/state'

const persistConfig = {
  key: 'root',
  storage: sessionStorage,
}

const reducer = combineReducers({
  auth: authReducer,
  message: messageReducer,
  user: userReducer,
  reviewer: reviewerReducer,
  conference: conferenceReducer,
  submission: submissionReducer,
  shared: sharedReducer,
  report: reportReducer,
  forgotPassword: forgotPasswordReducer,
  resetPassword: resetPasswordReducer,
  loader: loaderReducer,
  alertDialog: alertDialogReducer,
  alert: alertReducer,
  toast: toastReducer,
  emailTemplate: emailTemplateReducer,
})

const rootReducer = (state: any, action: any) => {
  if (action.payload === 'RESET_ALL_STATE') {
    sessionStorage.removeItem('persist:root')
    return reducer(undefined, action)
  }

  return reducer(state, action)
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.REACT_APP_DEV_TOOLS === 'true',
})
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export default store
