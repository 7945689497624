import { useState, useCallback, useEffect } from 'react'
import { SubmissionItemState, TReviewerAssignmentCreate, TSubmissionItem } from '../data'
import { useAppDispatch } from 'src/store/hooks'
import { setMessage } from 'src/packages/product/notification/state'
import { getReviewerAssignmentList, setReviewerAssignment, submissionSlice } from '../state'
import { useForm } from 'react-hook-form'
import { setToast } from '@/packages/product/toast/state'

const useAssignmentHook = () => {
  const dispatch = useAppDispatch()
  const [isAssigneeOpen, setIsAssigneeOpen] = useState(false)
  const [assignee, setAssignee] = useState<any[]>([])
  const [selectedPaper, setSelectedPaper] = useState<TSubmissionItem>(SubmissionItemState)
  const useFormObject = useForm()

  const getReviewersAsync = async (id: number) => {
    const reviewers = await dispatch(getReviewerAssignmentList({ paperId: id, type: 'all' }))
    if (!reviewers.payload) dispatch(setMessage('No records found'))
    // setData(reviewers.payload)
    return reviewers.payload
  }

  const handleAssigneeModalToggle = useCallback((item?: TSubmissionItem) => {
    setIsAssigneeOpen((prevIsAssigneeOpen) => !prevIsAssigneeOpen)
    if (!item?.id) return
    setSelectedPaper(item)
  }, [])

  useEffect(() => {
    if (isAssigneeOpen && selectedPaper) {
      getReviewersAsync(selectedPaper.id!)
    }
    if (!isAssigneeOpen) {
      const { reset } = useFormObject
      reset()
      dispatch(submissionSlice.actions.clearReviewerAssignmentList())
    }
  }, [isAssigneeOpen, selectedPaper])

  const handleAssigneeSubmit = useCallback(async (paperId: number, item: any) => {
    console.log('assignee button click', paperId, item)
    const payload: TReviewerAssignmentCreate = {
      paperId,
      assignments: item,
    }
    const res = await dispatch(setReviewerAssignment(payload))
    console.log('res ', res)
    if (res.meta.requestStatus === 'fulfilled') {
      handleAssigneeModalToggle()
      dispatch(setToast({ message: 'Reviewer assigned successfully', variant: 'success' }))
      window.location.reload()
    } else {
      dispatch(setToast({ message: res.payload as string, variant: 'error' }))
    }
    const { reset } = useFormObject
    reset()
    // setIsAssigneeOpen(true)
    // setAssignee((prevItems) => [...prevItems, item])
  }, [])

  return {
    handleAssigneeModalToggle,
    handleAssigneeSubmit,
    isAssigneeOpen,
    assignee,
    selectedPaper,
    useFormObject,
  }
}

export const ReviewerAssignmentHook = {
  useAssignmentHook,
}
