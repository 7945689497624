import { HttpResponseSchema } from 'src/shared/http/data'
import { z } from 'zod'

const AuthPathSchema = z.object({
  path: z.string().default(''),
  status: z.number().transform(Number).default(200),
})
export const AuthStateSchema = z
  .object({
    isAuth: z.boolean().default(false),
    id: z.number().nullable().default(null),
    getRefreshToken: z.number().default(0),
    iat: z.number().nullable().default(null),
    route: AuthPathSchema.default({}),
  })
  .merge(HttpResponseSchema)

export type TAuthState = z.infer<typeof AuthStateSchema>

export const AuthState: TAuthState = AuthStateSchema.parse({
  isAuth: false,
  id: null,
  getRefreshToken: 0,
  iat: null,
  route: {
    path: '',
    status: 200,
  },
})

export const TokenSchema = z.object({
  email: z.string(),
  sub: z.number(),
  type: z.string(),
  role: z.number().optional(),
})

const thunkAPIApiErrorSchema = z.object({
  message: z.string(),
})
export const thunkAPISchema = z.object({
  error: thunkAPIApiErrorSchema,
  meta: z.object({}),
  payload: z.string(), //string object is currently used only for createUserAccount payload. can change if needed
  type: z.string(),
})

export type TThunkAPI = z.infer<typeof thunkAPISchema>
export type TToken = z.infer<typeof TokenSchema> | null

export const AUTH_STATE = {
  AUTH_SET_ACTIVE_ROLE: 'auth/set-active-role',
  AUTH_SET_REFRESH_TOKEN: 'auth/set-refresh-token',
}

export const ENDPOINT = {
  LOCAL_SELECT_ROLE: `users/local/select-role`,
  REFRESH_TOKEN: `users/refresh-token`,
}
