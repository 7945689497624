import React from 'react'

import { useState } from 'react'
import { MagnifyingGlassIcon, PlusIcon } from '@heroicons/react/24/outline'
import Button from 'src/packages/core/button'
import Icon from 'src/packages/core/icon'
import { useNavigate } from 'react-router-dom'
import { ROUTE_PATH } from 'src/shared/data'

type SearchFormProps = {
  // data: T[]
  onSearch: (value: string) => void
}
const ConferenceTopBar = ({ /*data,*/ onSearch }: SearchFormProps) => {
  const [searchValue, setSearchValue] = useState('')
  const navigate = useNavigate()

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setSearchValue(value)
    onSearch(value)
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    onSearch(searchValue)
  }

  return (
    <div className="flex">
      <form onSubmit={handleSubmit} className="w-1/3">
        <div className="relative rounded-md">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </div>
          <input
            type="text"
            name="search"
            id="search"
            className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
            placeholder="Search"
            value={searchValue}
            onChange={handleSearchChange}
          />
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
            <button type="submit" className="hidden" />
            {/* <button
            type="submit"
            className="inline-flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Search
          </button> */}
          </div>
        </div>
      </form>
      <Button type="button" className="ml-6" onClick={() => navigate(ROUTE_PATH.CONFERENCE_ADD)}>
        <Icon icon={<PlusIcon className="w-5 mt-0.5" />} css={'w-7'} /> New Conference
      </Button>
    </div>
  )
}

export default ConferenceTopBar
