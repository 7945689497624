type TValidateFileUpload = {
  status: 'error' | 'success'
  message: string | null
}

const validateFileUpload = async (file: File): Promise<TValidateFileUpload> => {
  if (!file) {
    return {
      status: 'error',
      message: 'File is required',
    }
  }

  // validate file name
  const invalidChars = /[^\w\s\-.]/
  if (invalidChars.test(file.name)) {
    const invalidCharList = file.name.match(invalidChars)?.join(', ')
    return {
      status: 'error',
      message: `File name contains invalid characters: ${invalidCharList}`,
    }
  }

  // validate file type
  const fileType = file.type
  if (!['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'].includes(fileType)) {
    return {
      status: 'error',
      message: 'File type must be docx, doc',
    }
  }

  // validate file content
  const fileContent = await new Promise<string>((resolve) => {
    const reader = new FileReader()
    reader.onload = () => {
      resolve(reader.result as string)
    }
    reader.readAsText(file)
  })

  if (fileContent.includes('<script>')) {
    return {
      status: 'error',
      message: 'File contains malicious code',
    }
  }

  // validate file path
  if (/[^\w\s\-.]/.test(file.name)) {
    return {
      status: 'error',
      message: 'File path contains invalid characters',
    }
  }

  // validate file size
  if (file.size > Number(process.env.REACT_APP_FP_FILE_SIZE)!) {
    return {
      status: 'error',
      message: 'File size must be less than 3MB',
    }
  }

  return {
    status: 'success',
    message: null,
  }
}

export default validateFileUpload
