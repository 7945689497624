import React from 'react'
import { ENDPOINT } from '../data'
import { API_URL } from 'src/shared/data'
import { HttpService } from 'src/shared/http/service'

const http = new HttpService()
const getSubThemeList = async () => {
  return await http.get(`${API_URL}/${ENDPOINT.SUB_THEME}`)
}

const SubThemeService = {
  getSubThemeList,
}
export default SubThemeService
