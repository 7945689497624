import { createAsyncThunk } from '@reduxjs/toolkit'
import { setMessage } from 'src/packages/product/notification/state'
import { STATE, TReviewerAccountInfo, TRoleManagement, TUserAddress, TUserInfo } from '../data'
import UserService from '../service'
import { HTTP_STATUS, HTTP_STATUS_CODE, THttpRequestData } from 'src/shared/http/data'

export const createUserProfile = createAsyncThunk(STATE.USER_DETAILS, async (data: TUserInfo, thunkAPI) => {
  try {
    const res = await UserService.createUserProfile(data)
    console.log('res', res)
    return res
    // if (res.statusCode === HTTP_STATUS_CODE.FORBIDDEN) {
    //   thunkAPI.dispatch(setMessage({ message: res.message, origin: 'user', status: 'error' }))
    //   thunkAPI.dispatch(setMessage({ message: 'User profile created successfully', origin: 'user', status: 'success' }))
    // }
  } catch (error: any) {
    console.log('error', error)
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    thunkAPI.dispatch(setMessage({ message: message, origin: 'user', status: 'error' }))
    return thunkAPI.rejectWithValue(message)
    // throw new Error(message)
  }
})

export const createUserAddressBook = createAsyncThunk(STATE.USER_ADDRESS, async (data: TUserAddress, thunkAPI) => {
  try {
    return await UserService.createUserAddressBook(data)
  } catch (error: any) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    thunkAPI.dispatch(setMessage({ message: message, origin: 'user-address', status: 'error' }))
    return thunkAPI.rejectWithValue(message)
  }
})

export const createUserRole = createAsyncThunk(STATE.USER_ROLES, async (data: TRoleManagement[], thunkAPI) => {
  try {
    return await UserService.createUserRole(data)
  } catch (error: any) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    thunkAPI.dispatch(setMessage({ message: message, origin: 'user-role', status: 'error' }))
    return thunkAPI.rejectWithValue(message)
  }
})

export const createUserAccount = createAsyncThunk(STATE.USER_ACCOUNT, async (data: any, thunkAPI) => {
  try {
    const res: any = (await UserService.createUserProfile(data)).data
    // thunkAPI.dispatch(setMessage({ message: 'User account created successfully', origin: 'user-account', status: 'success' }))
    return { http: { status: HTTP_STATUS.SUCCESS }, ...res }
  } catch (error: any) {
    console.log('error all 3', error)
    const message: string = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    // thunkAPI.dispatch(setMessage({ message: message, origin: 'user-account', status: 'error' }))
    // return thunkAPI.rejectWithValue(message)
    return thunkAPI.rejectWithValue({ http: { status: HTTP_STATUS.FAIL, message: message } })
  }
})

export const updateUserAccount = createAsyncThunk(STATE.USER_ACCOUNT_UPDATE, async (data: any, thunkAPI) => {
  try {
    const res: any = (await UserService.updateUserProfile(data)).data
    return { http: { status: HTTP_STATUS.SUCCESS, message: 'User account updated successfully' }, ...res }
  } catch (error: any) {
    const message: string = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue({ http: { status: HTTP_STATUS.FAIL, message: message } })
  }
})

export const updateAccountPassword = createAsyncThunk(STATE.USER_PASSWORD, async (data: any, thunkAPI) => {
  try {
    const res: any = (await UserService.updateAccountPassword(data)).data
    // thunkAPI.dispatch(setMessage({ message: 'Password Updated', origin: 'user-password', status: 'success' }))
    return { http: { status: HTTP_STATUS.SUCCESS, message: 'User account password updated successfully' }, ...res }
  } catch (error: any) {
    const message: string = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue({ http: { status: HTTP_STATUS.FAIL, message: message } })
  }
})

export const deleteUserAccount = createAsyncThunk(STATE.USER_ACCOUNT_DELETE, async (data: number | null, thunkAPI) => {
  try {
    if (data === null) return thunkAPI.rejectWithValue('No user id provided')
    const res = (await UserService.deleteUserAccount(data)).data
    thunkAPI.dispatch(setMessage({ message: 'User account deleted successfully', origin: 'user-account', status: 'success' }))
    return res
  } catch (error: any) {
    const message: string = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    thunkAPI.dispatch(setMessage({ message: message, origin: 'user-account', status: 'error' }))
    return thunkAPI.rejectWithValue(message)
  }
})

export const createUserProfileReducer = () => {
  return {
    [`${createUserAccount.fulfilled}`]: (state: TUserInfo, action: any) => {
      // state.http.status = HTTP_STATUS.SUCCESS
      // state.user = action.payload
      console.log('action.payload', action.payload, state)
    },
    [`${createUserAccount.rejected}`]: (state: any, action: any) => {
      state.http.status = HTTP_STATUS.FAIL
      console.log('error msg', action.error)
      console.log('action.payload rejected', action.payload, state)
      // state.error = action.error.message;
    },
    [`${updateUserAccount.fulfilled}`]: (state: any, action: any) => {
      console.log('updateUserAccount.fulfilled', action.payload)
      state.http.status = HTTP_STATUS.SUCCESS
      // state.error = action.error.message;
    },
    [`${updateUserAccount.rejected}`]: (state: any, action: any) => {
      console.log('updateUserAccount.rejected', state, action)
      state.http.status = HTTP_STATUS.FAIL
      state.http.message = action
      // state.error = action.error.message;
    },
  }
}
