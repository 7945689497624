import { useAppDispatch, useAppSelector } from 'src/store/hooks'
import { getSubmissionStatusList } from '../state'
import { setMessage } from 'src/packages/product/notification/state'
import { useEffect, useState } from 'react'

const useSubmissionStatusHook = () => {
  const [searchTerm, setSearchTerm] = useState('')
  const dispatch = useAppDispatch()
  const { submissionStatusList } = useAppSelector((state) => state.submission)

  const useFetchData = () => {
    useEffect(() => {
      const getSubmissionStatusListAsync = async () => {
        const response = await dispatch(getSubmissionStatusList())
        console.log('response submission status list', response)
        if (!response.payload) dispatch(setMessage({ message: 'No record found', type: 'error' }))
        return response
      }
      getSubmissionStatusListAsync()
    }, [dispatch])
  }

  return {
    useFetchData,
    submissionStatusList,
    searchTerm,
    setSearchTerm,
  }
}

const SubmissionStatusHook = {
  useSubmissionStatusHook,
}

export default SubmissionStatusHook
