import { useAppDispatch, useAppSelector } from 'src/store/hooks'
import { useEffect } from 'react'
import { downloadReportState, getConferenceSubmissionSummary } from '../state'
import { TDownloadReportLink } from '../data'
import { setToast } from '@/packages/product/toast/state'

const useReportHook = () => {
  const dispatch = useAppDispatch()
  const { conferenceSubmissionSummary } = useAppSelector((state) => state.report)

  const useFetchData = () => {
    useEffect(() => {
      let conferenceSubmissionSummary: any
      const getConferenceSubmissionSummaryAsync = async () => {
        conferenceSubmissionSummary = await dispatch(getConferenceSubmissionSummary())
      }

      getConferenceSubmissionSummaryAsync()

      return () => {
        conferenceSubmissionSummary
      }
    }, [dispatch])
  }

  const downloadReport = async (): Promise<TDownloadReportLink | undefined> => {
    try {
      const downloadReport = await dispatch(downloadReportState())
      if (downloadReport.meta.requestStatus === 'rejected') {
        dispatch(setToast({ message: `Download failed- ${downloadReport.payload}`, variant: 'error' }))
      } else {
        return downloadReport?.payload as TDownloadReportLink
      }
    } catch (error: any) {
      dispatch(setToast({ message: `Download failed- ${error}`, variant: 'error' }))
      return error
    }
    return undefined
  }

  return {
    useFetchData,
    conferenceSubmissionSummary,
    downloadReport,
  }
}

export const ReportHook = {
  useReportHook,
}
