import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { setMessage } from 'src/packages/product/notification/state'
import { HTTP_STATUS } from 'src/shared/http/data'
import {
  STATE,
  SubmissionState,
  TEvaluationItem,
  TFullPaperFileUpload,
  TReviewerAssignmentCreate,
  TSubmission,
  TSubmissionItemCreate,
  TSubmissionItemUpdate,
} from '../data'
import { ReviewerAssignmentService, SubmissionService } from '../service'
import { TReviewerAssignmentReqData } from 'src/pages/user/views/reviewer/data'

export const getSubmissionList = createAsyncThunk(STATE.SUBMISSION, async (_, thunkAPI) => {
  try {
    return (await SubmissionService.getSubmissionList()).data
  } catch (error: any) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    thunkAPI.dispatch(setMessage({ message: message, origin: 'submission', status: 'error' }))
    return thunkAPI.rejectWithValue(message)
  }
})

export const getSubmissionDetail = createAsyncThunk(STATE.GET_SUBMISSION_DETAIL, async (data: number, thunkAPI) => {
  try {
    const details = (await SubmissionService.getSubmissionDetail(data)).data
    return details
  } catch (error: any) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    thunkAPI.dispatch(setMessage({ message: message, origin: 'submission', status: 'error' }))
    return thunkAPI.rejectWithValue(message)
  }
})

export const createSubmission = createAsyncThunk(STATE.SUBMISSION_FORM, async (data: TSubmissionItemCreate, thunkAPI) => {
  try {
    const details: any = (await SubmissionService.createSubmission(data)).data
    // thunkAPI.dispatch(setMessage({ message: 'Paper submitted successfully', origin: 'submission', status: 'success' }))
    return { http: { status: HTTP_STATUS.SUCCESS }, ...details }
  } catch (error: any) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    // thunkAPI.dispatch(setMessage({ message: message, origin: 'submission', status: 'error' }))
    return thunkAPI.rejectWithValue({ message, http: { status: HTTP_STATUS.FAIL } })
  }
})

export const updateSubmission = createAsyncThunk(STATE.SUBMISSION_DETAIL, async (data: TSubmissionItemUpdate, thunkAPI) => {
  try {
    const details: any = (await SubmissionService.updateSubmission(data)).data
    // thunkAPI.dispatch(setMessage({ message: 'Paper updated successfully', origin: 'submission', status: 'success' }))
    return { http: { status: HTTP_STATUS.SUCCESS }, ...details }
  } catch (error: any) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    console.log('update error', message)
    // thunkAPI.dispatch(setMessage({ message: message, origin: 'submission', status: 'error' }))
    return thunkAPI.rejectWithValue({ message, http: { status: HTTP_STATUS.FAIL } })
  }
})

export const getReviewerAssignmentList = createAsyncThunk(
  STATE.REVIEWER_ASSIGNMENT_LIST,
  async (data: TReviewerAssignmentReqData, thunkAPI) => {
    try {
      return (await ReviewerAssignmentService.getReviewerAssignmentList(data)).data
    } catch (error: any) {
      const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
      thunkAPI.dispatch(setMessage({ message: message, origin: 'submission', status: 'error' }))
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const setReviewerAssignment = createAsyncThunk(STATE.REVIEWER_ASSIGNMENT_FORM, async (data: TReviewerAssignmentCreate, thunkAPI) => {
  try {
    const details = (await ReviewerAssignmentService.setReviewerAssignment(data)).data
    return details
  } catch (error: any) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

export const updateSubmissionScore = createAsyncThunk(STATE.REVIEWING_ABSTRACT_SUBMISSION, async (data: TEvaluationItem, thunkAPI) => {
  try {
    const details = (await SubmissionService.updateSubmissionScore(data)).data
    return details
  } catch (error: any) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

export const updateFullPaperSubmissionScore = createAsyncThunk(
  STATE.REVIEWING_FULL_PAPER_SUBMISSION,
  async (data: TEvaluationItem, thunkAPI) => {
    try {
      const details = (await SubmissionService.updateFullPaperSubmissionScore(data)).data
      // thunkAPI.dispatch(setMessage({ message: 'Review score updated successfully', origin: 'submission', status: 'success' }))
      return details
    } catch (error: any) {
      const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
      // thunkAPI.dispatch(setMessage({ message: message, origin: 'submission-score', status: 'error' }))
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const getAbstractSubmissionScore = createAsyncThunk(STATE.SUBMISSION_ABSTRACT_SCORE, async (data: number, thunkAPI) => {
  try {
    const details = (await SubmissionService.getAbstractSubmissionScore(data)).data
    return details
  } catch (error: any) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    thunkAPI.dispatch(setMessage({ message: message, origin: 'submission', status: 'error' }))
    return thunkAPI.rejectWithValue(message)
  }
})

export const getFullPaperSubmissionScore = createAsyncThunk(STATE.SUBMISSION_FULL_PAPER_SCORE, async (data: number, thunkAPI) => {
  try {
    const details = (await SubmissionService.getFullPaperSubmissionScore(data)).data
    return details
  } catch (error: any) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    thunkAPI.dispatch(setMessage({ message: message, origin: 'submission', status: 'error' }))
    return thunkAPI.rejectWithValue(message)
  }
})

export const setFullPaperFile = createAsyncThunk(STATE.SUBMISSION_FULL_PAPER_FILE, async (data: TFullPaperFileUpload, thunkAPI) => {
  try {
    const details = (await SubmissionService.setFullPaperFile(data)).data
    // thunkAPI.dispatch(setMessage({ message: 'Full paper file uploaded successfully', origin: 'submission-upload', status: 'success' }))
    return details
  } catch (error: any) {
    console.log(error)
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    // thunkAPI.dispatch(setMessage({ message: message, origin: 'submission-upload', status: 'error' }))
    return thunkAPI.rejectWithValue(message)
  }
})

export const getFullPaperFiles = createAsyncThunk(STATE.SUBMISSION_FULL_PAPER_FILE_LIST, async (data: number, thunkAPI) => {
  try {
    const details = (await SubmissionService.getFullPaperFiles(data)).data
    return details
  } catch (error: any) {
    console.log(error)
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    // thunkAPI.dispatch(setMessage({ message: message, origin: 'submission-upload', status: 'error' }))
    return thunkAPI.rejectWithValue(message)
  }
})

export const getRevisedFullPaperFiles = createAsyncThunk(STATE.SUBMISSION_REVISED_FULL_PAPER_FILE_LIST, async (data: number, thunkAPI) => {
  try {
    const details = (await SubmissionService.getRevisedFullPaperFiles(data)).data
    return details
  } catch (error: any) {
    console.log(error)
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    // thunkAPI.dispatch(setMessage({ message: message, origin: 'submission-upload', status: 'error' }))
    return thunkAPI.rejectWithValue(message)
  }
})

export const setRevisedFullPaperFile = createAsyncThunk(
  STATE.SUBMISSION_REVISED_FULL_PAPER_FILE_LIST,
  async (data: TFullPaperFileUpload, thunkAPI) => {
    try {
      const details = (await SubmissionService.setRevisedFullPaperFile(data)).data
      // thunkAPI.dispatch(setMessage({ message: 'Full paper file uploaded successfully', origin: 'submission-upload', status: 'success' }))
      return details
    } catch (error: any) {
      console.log(error)
      const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
      // thunkAPI.dispatch(setMessage({ message: message, origin: 'submission-upload', status: 'error' }))
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const getSubmissionStatusList = createAsyncThunk(STATE.SUBMISSION_STATUS_LIST, async (_, thunkAPI) => {
  try {
    return (await SubmissionService.getSubmissionStatusList()).data
  } catch (error: any) {
    console.log(error)
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    thunkAPI.dispatch(setMessage({ message: message, origin: 'submission-status', status: 'error' }))
    return thunkAPI.rejectWithValue(message)
  }
})

export const submissionSlice = createSlice({
  name: 'submission',
  initialState: SubmissionState,
  reducers: {
    clearForm: (state) => {
      state.form = null
    },
    clearReviewerAssignmentList: (state) => {
      state.reviewerAssignmentList = []
    },
    setReviewingSubmission: (state, action: PayloadAction<any>) => {
      console.log('set reviewing submission', action.payload)
      state.reviewingSubmission = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSubmissionList.fulfilled, (state: TSubmission, action: any) => {
        console.log('submission list', action)
        state.http.status = HTTP_STATUS.SUCCESS
        state.data = action.payload.data
        // state.data = action.payload.data.map((item: TSubmissionItem) => {
        //   item.submitted_date = moment(item.submitted_date).format('MMM DD, YYYY hh:mm A')
        // })
        state.meta = action.payload.meta
        // state = action.payload
      })
      .addCase(getSubmissionList.rejected, (state: TSubmission) => {
        state.http.status = HTTP_STATUS.FAIL
      })
      .addCase(createSubmission.pending, (state: any, action: PayloadAction<any, any, any>) => {
        console.log('create submission pending', action, state)
        const payload = action.meta.arg
        state.form = payload
      })
      .addCase(createSubmission.fulfilled, (state: any) => {
        state.form = {}
        state.http.status = HTTP_STATUS.SUCCESS
      })
      .addCase(createSubmission.rejected, (state: any) => {
        state.http.status = HTTP_STATUS.FAIL
      })
      .addCase(updateSubmission.fulfilled, (state: any) => {
        state.http.status = HTTP_STATUS.SUCCESS
      })
      .addCase(updateSubmission.rejected, (state, payload: any) => {
        state.http.status = HTTP_STATUS.FAIL
        state.http.message = `${payload?.error?.message}-${payload?.payload?.message}`
      })
      .addCase(getReviewerAssignmentList.fulfilled, (state: TSubmission, action: PayloadAction<any, any>) => {
        state.http.status = HTTP_STATUS.SUCCESS
        console.log('reviewer assignment list', action.payload)
        state.reviewerAssignmentList = action.payload
        // state = action.payload
      })
      .addCase(setReviewerAssignment.fulfilled, (state: TSubmission) => {
        console.log('success set assignment')
        state.http.status = HTTP_STATUS.SUCCESS
      })
      .addCase(setReviewerAssignment.rejected, (state: TSubmission) => {
        state.http.status = HTTP_STATUS.FAIL
      })
      .addCase(getAbstractSubmissionScore.fulfilled, (state: TSubmission, action: PayloadAction<any, any>) => {
        state.http.status = HTTP_STATUS.SUCCESS
        state.abstractScore = action.payload
      })
      .addCase(getAbstractSubmissionScore.rejected, (state: TSubmission) => {
        state.http.status = HTTP_STATUS.FAIL
      })
      .addCase(getFullPaperSubmissionScore.fulfilled, (state: TSubmission, action: PayloadAction<any, any>) => {
        state.http.status = HTTP_STATUS.SUCCESS
        state.fullPaperScore = action.payload
      })
      .addCase(getFullPaperSubmissionScore.rejected, (state: TSubmission) => {
        state.http.status = HTTP_STATUS.FAIL
      })
      .addCase(updateSubmissionScore.fulfilled, (state: TSubmission) => {
        state.http.status = HTTP_STATUS.SUCCESS
      })
      .addCase(updateSubmissionScore.rejected, (state: TSubmission) => {
        state.http.status = HTTP_STATUS.FAIL
      })
      .addCase(updateFullPaperSubmissionScore.fulfilled, (state: TSubmission) => {
        state.http.status = HTTP_STATUS.SUCCESS
      })
      .addCase(updateFullPaperSubmissionScore.rejected, (state: TSubmission) => {
        state.http.status = HTTP_STATUS.FAIL
      })
      .addCase(getSubmissionDetail.fulfilled, (state: TSubmission, action: PayloadAction<any, any>) => {
        state.http.status = HTTP_STATUS.SUCCESS
        state.form = action.payload
      })
      .addCase(getSubmissionDetail.rejected, (state: TSubmission) => {
        state.http.status = HTTP_STATUS.FAIL
      })
      .addCase(setFullPaperFile.fulfilled, (state: TSubmission) => {
        state.http.status = HTTP_STATUS.SUCCESS
      })
      .addCase(setFullPaperFile.rejected, (state: TSubmission) => {
        state.http.status = HTTP_STATUS.FAIL
      })
      .addCase(getFullPaperFiles.fulfilled, (state: TSubmission, action: PayloadAction<any, any>) => {
        state.http.status = HTTP_STATUS.SUCCESS
        state.fullPaperFiles = action.payload.data
      })
      .addCase(getFullPaperFiles.rejected, (state: TSubmission) => {
        state.http.status = HTTP_STATUS.FAIL
        state.fullPaperFiles = []
      })
      .addCase(getSubmissionStatusList.fulfilled, (state: TSubmission, action: PayloadAction<any, any>) => {
        state.http.status = HTTP_STATUS.SUCCESS
        state.submissionStatusList = action.payload.data
      })
      .addCase(getSubmissionStatusList.rejected, (state: TSubmission) => {
        state.http.status = HTTP_STATUS.FAIL
        state.submissionStatusList = []
      })
      .addCase(getRevisedFullPaperFiles.fulfilled, (state: TSubmission, action: PayloadAction<any, any>) => {
        state.http.status = HTTP_STATUS.SUCCESS
        state.revisedFullPaperFiles = action.payload.data
      })
      .addCase(getRevisedFullPaperFiles.rejected, (state: TSubmission) => {
        state.http.status = HTTP_STATUS.FAIL
        state.revisedFullPaperFiles = []
      })
  },
})

export const {
  reducer,
  actions: { setReviewingSubmission },
} = submissionSlice
export default reducer
