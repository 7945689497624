import { createSlice } from '@reduxjs/toolkit'

type TMessageState = {
  message: string[]
  origin: string
  status: string
}
const initialState: TMessageState = { message: [], origin: '', status: '' }

const messageSlice = createSlice({
  name: 'message',
  initialState,
  reducers: {
    setMessage: (state, action) => {
      console.log('set message state', state, action)
      // return state.message = action.payload;
      const message = typeof action.payload.message === 'string' ? [action.payload.message] : action.payload.message
      return { message: message, origin: action.payload.origin, status: action.payload.status }
    },
    clearMessage: () => {
      console.log('clear message')
      return initialState
    },
  },
})

const { reducer, actions } = messageSlice

export const { setMessage, clearMessage } = actions
export default reducer
