import React, { useState } from 'react'
import { FieldError, useFieldArray, useForm } from 'react-hook-form'
import { FormProps } from './data'
import { renderColumn, renderCreatableSelect, renderDatePicker, renderDynamicSelectFields } from './render-columns'

const Form = ({
  onSubmit,
  defaultValues = {},
  sections,
  button,
  // schema,
  children,
  cols = 'grid-cols-1 md:grid-cols-2 gap-y-4 gap-x-10',
}: // onChange,
FormProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    watch,
    reset,
  } = useForm({
    defaultValues,
    // resolver: zodResolver(schema),
    // below is used to debug data values for the resolve if no action is happening
    resolver: (data) => {
      // console.log('data resolver', data, schema)
      return { values: data, errors: {} }
    },
  })

  let dynamicFieldsName = ''
  for (const section of sections) {
    for (const field of section.columns) {
      if (field.type === 'dynamic') {
        dynamicFieldsName = field.name
      }
    }
  }

  const useFieldArrayObject = useFieldArray({ control, name: dynamicFieldsName })

  const [dateValues, setDateValues] = useState<Record<string, any>>(() => {
    const initialValues: Record<string, any> = defaultValues ? defaultValues : {}
    sections.forEach((section) => {
      section.columns.forEach((field) => {
        if (field.type === 'date') {
          // console.log('set initial date field', field.name, initialValues[field.name])
          // initialValues[field.name] = null
        }
      })
    })
    return initialValues
  })

  const handleDateChange = (name: string, value: any) => {
    setDateValues((prevState) => ({
      ...prevState,
      [name]: value,
    }))
    setValue(name, value?.startDate)
  }

  const handleCreatableSelectChange = (name: string, selectedOptions: any) => {
    console.log('selected options', selectedOptions)
    const values = selectedOptions
      ? selectedOptions.map((option: { label: string; value: string; __isNew__?: boolean }) => {
          // if (option.__isNew__) return { name: option.label }
          return { label: option.label, value: parseInt(option.value) }
        })
      : []
    console.log('set values creatable', name, values)
    setValue(name, values)
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)} className="mt-4">
      {sections.map(({ heading, columns }, index) => (
        <div key={index} className="rounded-md">
          {heading && <h2 className="font-bold text-2xl pt-8">{heading}</h2>}
          <div className={`grid ${heading !== 'Roles Management' ? cols : 'md:grid-cols-1 gap-y-4 gap-x-10'}`}>
            {columns.map((col) => (
              <div key={col.name} className="rounded-md pt-2">
                {renderColumn(col, register, control)}
                {col.type === 'date' && renderDatePicker(col, dateValues, handleDateChange)}
                {col.type === 'dynamic' && renderDynamicSelectFields(col, useFieldArrayObject, control, watch)}
                {col.type === 'creatable-select' && renderCreatableSelect(col, handleCreatableSelectChange, defaultValues)}
                {errors[col.name] && <span className="text-red-600 text-sm border-0">{(errors[col.name] as FieldError).message}</span>}
              </div>
            ))}
          </div>
        </div>
      ))}

      <div className="flex">
        {children}
        <span className="mt-4">{button}</span>
      </div>
    </form>
  )
}

export default Form
