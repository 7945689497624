import React from 'react'
import { useState } from 'react'
import Button from '@/packages/core/button'

type Tab = {
  name: string
  content: React.ReactNode
}

type TabsProps = {
  tabs: Tab[]
  initialTab?: number
}
const Tabs = ({ tabs, initialTab = 0 }: TabsProps) => {
  const [activeTab, setActiveTab] = useState(initialTab)

  return (
    <>
      <div className="flex">
        {tabs.map((tab, index) => (
          <Button
            key={tab.name}
            className={`${index === activeTab ? '' : ' text-gray-800 border-2'} py-2 px-6 rounded-md focus:outline-none cursor-pointer`}
            onClick={() => setActiveTab(index)}
            fill={index === activeTab ? 'default' : 'outline'}
          >
            {tab.name}
          </Button>
        ))}
      </div>
      <div>{tabs[activeTab].content}</div>
    </>
  )
}

export default Tabs
