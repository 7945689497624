import React from 'react'

const AbstractScoreGuidelineSection = () => {
  const styles = {
    tableRow: 'border border-gray-100',
    tableData: 'p-4 border-b border-gray-100 text-black',
  }
  return (
    <table className="w-full mt-6 border border-gray-200 rounded-lg border-separate">
      <tbody>
        <tr className={styles.tableRow}>
          <td className={`w-1/8 font-bold ${styles.tableData}`}>Level</td>
          <td className={`w-1/8 font-bold ${styles.tableData}`}>Percentage</td>
          <td className={`w-6/8 font-bold ${styles.tableData}`}>Guidelines for evaluation of Quality of Abstract</td>
        </tr>
        <tr>
          <td className={`w-1/8 font-bold ${styles.tableData}`}>Reject</td>
          <td className={`w-1/8 ${styles.tableData}`}>Below 50%</td>
          <td className={`w-6/8 ${styles.tableData}`}>
            <ul>
              <li>Not within Education Agenda</li>
              <li>Completely outside scope of Conference Theme</li>
            </ul>
          </td>
        </tr>
        <tr>
          <td className={`w-1/8 font-bold ${styles.tableData}`}>1</td>
          <td className={`w-1/8 ${styles.tableData}`}>50-59%</td>
          <td className={`w-6/8 ${styles.tableData}`}>
            <b>Below Average</b> Proposed Academic & Research Content of <b>Low Interest</b> to other participants
          </td>
        </tr>
        <tr>
          <td className={`w-1/8 font-bold ${styles.tableData}`}>2</td>
          <td className={`w-1/8 ${styles.tableData}`}>60-69%</td>
          <td className={`w-6/8 ${styles.tableData}`}>
            <b>Average</b> Proposed Academic & Research Content of <b>General Interest</b> to other participants
          </td>
        </tr>
        <tr>
          <td className={`w-1/8 font-bold ${styles.tableData}`}>3</td>
          <td className={`w-1/8 ${styles.tableData}`}>70-79%</td>
          <td className={`w-6/8 ${styles.tableData}`}>
            <b>Acceptable</b> Academic & Research Content of <b>Good Interest</b> to other participants
          </td>
        </tr>
        <tr>
          <td className={`w-1/8 font-bold ${styles.tableData}`}>4</td>
          <td className={`w-1/8 ${styles.tableData}`}>80-89%</td>
          <td className={`w-6/8 ${styles.tableData}`}>
            <b>Good Proposed</b> Academic & Research Content of <b>Good Interest</b> to other participants
          </td>
        </tr>
        <tr>
          <td className={`w-1/8 font-bold ${styles.tableData}`}>5</td>
          <td className={`w-1/8 ${styles.tableData}`}>90-100%</td>
          <td className={`w-6/8 ${styles.tableData}`}>
            <b>Excellent Proposed</b> Academic & Research Content of <b>High Interest</b> to other participants
          </td>
        </tr>
      </tbody>
    </table>
  )
}
export default AbstractScoreGuidelineSection
