import { API_URL } from 'src/shared/data'
import { HttpService } from 'src/shared/http/service'
import { ENDPOINT, TResetPasswordPayload } from '../data'

const http = new HttpService()

const resetPassword = async (data: TResetPasswordPayload) => {
  return await http.post(`${API_URL}/${ENDPOINT.RESET_PASSWORD}`, data)
}

export const ResetPasswordService = {
  resetPassword,
}

export default ResetPasswordService
