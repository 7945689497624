import { Table } from '@hubz/core-table'
import MyRolesHook from './service/my-roles.hook'
import React from 'react'

const MyRolesPage = () => {
  const { useHook, table } = MyRolesHook
  const { user, useSubmit, useFetchData } = useHook()
  useFetchData()
  const { columns, records } = table({ submit: useSubmit, data: user })
  const rowIdKey = (item: any) => item.role.id

  return (
    <>
      <div className="flex text-black text-4xl font-semibold">Welcome back{`, ${user.user?.firstName} ${user.user?.lastName}`}</div>
      <p className="text-black mt-3">Your recent roles are shown in the table below. Click on a role to access</p>
      <Table data={records} columns={columns} selectable={false} rowIdKey={rowIdKey} tableClass="my-4 p-4" thClass="text-left" />
    </>
  )
}

export default MyRolesPage
