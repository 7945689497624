import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { ForgotPasswordState, STATE, TForgotPassword } from '../data'
import { ForgotPasswordService } from '../service/forgot-password.service'
import { setMessage } from 'src/packages/product/notification/state'
import { HTTP_STATUS } from 'src/shared/http/data'

export const forgotPassword = createAsyncThunk(STATE.FORGOT_PASSWORD, async (data: string, thunkAPI) => {
  try {
    return (await ForgotPasswordService.forgotPassword(data)).data
  } catch (error: any) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    const data = (error.response && error.response.data && error.response.data.data) || error.message || error.toString()
    thunkAPI.dispatch(setMessage({ message: message, origin: 'forgot-password', status: 'error' }))
    return thunkAPI.rejectWithValue({message: message, data: data})
  }
})

export const forgotPasswordSlice = createSlice({
  name: 'forgotPassword',
  initialState: ForgotPasswordState,
  reducers: {
    resetForgotPassword: (state) => {
      state.http.status = HTTP_STATUS.IDLE
      return state
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(forgotPassword.fulfilled, (state: TForgotPassword) => {
        state.http.status = HTTP_STATUS.SUCCESS
        state.successTime = new Date().toISOString()
      })
      .addCase(forgotPassword.rejected, (state: TForgotPassword, action: any) => {
        console.log('actions password', action)
        state.http.status = HTTP_STATUS.FAIL
        state.http.message = action.payload.message
        state.http.data = action.payload.data
      })
      .addCase(forgotPassword.pending, (state: TForgotPassword) => {
        state.http.status = HTTP_STATUS.LOADING
      })
  },
})

const { reducer } = forgotPasswordSlice
export default reducer
