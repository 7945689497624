import React from 'react'
const ReviewStatusLegend = () => {
  return (
    <section className="mt-8">
      {/* // legends for status */}
      <div className="flex flex-row justify-end">
        <div className="flex flex-row">
          <div className="flex flex-row items-center ml-4">
            <div className="w-4 h-4 bg-zinc-500 rounded-full mr-2"></div>
            <p className="text-sm">Not Assigned</p>
          </div>
          <div className="flex flex-row items-center ml-4">
            <div className="w-4 h-4 bg-yellow-500 rounded-full mr-2"></div>
            <p className="text-sm">Assigned</p>
          </div>
          <div className="flex flex-row items-center ml-4">
            <div className="w-4 h-4 bg-cyan-500 rounded-full mr-2"></div>
            <p className="text-sm">Reviewed</p>
          </div>
          <div className="flex flex-row items-center ml-4">
            <div className="w-4 h-4 bg-green-500 rounded-full mr-2"></div>
            <p className="text-sm">Accepted</p>
          </div>
          <div className="flex flex-row items-center ml-4">
            <div className="w-4 h-4 bg-destructive rounded-full mr-2"></div>
            <p className="text-sm">Rejected</p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ReviewStatusLegend
