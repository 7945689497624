import React from 'react'
export const levelColor = (level: number) => {
  switch (level) {
    case 0:
      return <span className={`px-2 py-1 rounded-md w-3 h-3 m-auto bold bg-gray-300`}>Rejected</span>
    case 1:
      return <span className={`px-2 py-1 rounded-md w-3 h-3 m-auto bg-destructive`}>{level}</span>
    case 2:
      return <span className={`px-2 py-1 rounded-md w-3 h-3 m-auto bg-orange-400`}>{level}</span>
    case 3:
      return <span className={`px-2 py-1 rounded-md w-3 h-3 m-auto bg-amber-300`}>{level}</span>
    case 4:
      return <span className={`px-2 py-1 rounded-md w-3 h-3 m-auto bg-lime-500`}>{level}</span>
    case 5:
      return <span className={`px-2 py-1 rounded-md w-3 h-3 m-auto bg-green-400`}>{level}</span>
    default:
      return <span className={`px-2 py-1 rounded-md w-3 h-3 m-auto`}>N/A</span>
  }
}
