import React, { useEffect, useRef } from 'react'
import Form from '@hubz/core-form'
import Button from '@hubz/core-button'
import { defaultValues } from '../../data/manage-reviewer-account.data'
import NotificationHook from 'src/shared/service/notification.hook'
import { PROFILE_ACCOUNT_INFO_FORM, ProfileAccountInfoSchema } from '../../data/profile-account-info.data'
import AccountManagementHook from '../../service/account-management.hook'

export const ProfileAccountInfoFormSection = ({ data }: any) => {
  const { useHook } = AccountManagementHook
  const { useFetchData, useUpdate, useBack } = useHook()
  const { useNotification } = NotificationHook
  const { notification } = useNotification()

  useFetchData()

  const myRef = useRef(null)
  const hasAddress = () => {
    return data?.addressBook && data?.addressBook.length > 0
  }
  if (typeof data == 'object') {
    defaultValues.roleManagement = [{ conference: '', role: '', userAccountStatus: '' }]
    defaultValues.firstName = data?.firstName
    defaultValues.lastName = data?.lastName
    defaultValues.email = data?.email
    defaultValues.phone = data?.phone
    defaultValues.gender = data?.gender
    defaultValues.addressLine1 = hasAddress() ? data?.addressBook[0]?.addressLine1 : ''
    defaultValues.addressLine2 = hasAddress() ? data?.addressBook[0]?.addressLine2 : ''
    defaultValues.city = hasAddress() ? data?.addressBook[0]?.city : ''
    defaultValues.postcode = hasAddress() ? data?.addressBook[0]?.postcode : ''
    defaultValues.country = hasAddress() ? data?.addressBook[0]?.country : ''
    defaultValues.affiliation = data?.affiliation
  }

  useEffect(() => {
    if (myRef.current && notification) (myRef.current as HTMLElement)?.scrollIntoView({ behavior: 'smooth' })
  }, [notification])

  return (
    <>
      <div ref={myRef} className="scroll-mt-2 mt-2"></div>
      <Form
        onSubmit={useUpdate}
        sections={PROFILE_ACCOUNT_INFO_FORM}
        button={<Button type="submit">Save</Button>}
        schema={ProfileAccountInfoSchema}
        defaultValues={defaultValues}
      >
        <Button type="button" fill="outline" className="mr-6 mt-4" onClick={useBack}>
          Back
        </Button>
      </Form>
    </>
  )
}
