import React from 'react'
import Tabs from '@hubz/product-tabs'
import AccountPasswordSection from 'src/pages/user/views/profile/account-password'
import { useAppSelector } from 'src/store/hooks'
import { ProfileAccountInfoFormSection } from './profile-account-info'
import AccountManagementHook from '../../service/account-management.hook'

const AccountManagementPage = () => {
  const { useHook, useAccountPassword } = AccountManagementHook
  const { useSubmit } = useAccountPassword()
  const { useFetchData } = useHook()
  const { user } = useAppSelector((state) => state.user)

  //   const displayTab = () => {
  //     if (reviewer && !matchPath) return true
  //     if (!reviewer && matchPath) return true
  //     return false
  //   }
  useFetchData()
  const tabs = [
    { name: 'Account Info', content: <ProfileAccountInfoFormSection data={user} /> },
    { name: 'Password Info', content: <AccountPasswordSection handlePasswordUpdate={useSubmit} /> },
  ]

  return <>{<Tabs tabs={tabs} />}</>
}

export default AccountManagementPage
