import { API_URL } from 'src/shared/data'
import { ENDPOINT } from '../data'
import { HttpService } from 'src/shared/http/service'

const http = new HttpService()

const getConferenceSubmissionSummary = async () => {
  return await http.get(`${API_URL}/${ENDPOINT.CONFERENCE_SUBMISSION_SUMMARY}`)
}

const downloadReport = async () => {
  return await http.get(`${API_URL}/${ENDPOINT.DOWNLOAD_REPORT}`)
}

export const ReportService = {
  getConferenceSubmissionSummary,
  downloadReport,
}

export default ReportService
