import React from 'react'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useAppDispatch, useAppSelector } from 'src/store/hooks'
import { ResetPasswordPayload } from './data'
import { HTTP_STATUS } from 'src/shared/http/data'
import Button from 'src/packages/core/button'
import { resetPassword } from './state'
import { NavLink } from 'react-router-dom'
import NotificationBar from 'src/packages/product/notification'
import { ROUTE_PATH } from '@/shared/data'
import { finishLoading, startLoading } from '@/packages/product/full-page-loader/state'

const ResetPasswordPage = () => {
  const { http } = useAppSelector((state) => state.resetPassword)
  const dispatch = useAppDispatch()
  const searchParams = new URLSearchParams(location.search)
  const token = searchParams.get('token')
  const useNotification = useAppSelector((state) => state.message)
  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm({
    resolver: zodResolver(ResetPasswordPayload),
  })

  const onSavePassword = async (data: any) => {
    console.log('password reset fn', data, token)
    dispatch(startLoading())
    await dispatch(resetPassword({ ...data, token }))
    dispatch(finishLoading())
  }

  const styles = {
    title: 'font-bold text-2xl pt-8',
    inputSection: ' gap-y-4 gap-x-10 pt-2',
    label: 'block text-md font-semibold leading-6 text-gray-500',
    input:
      'appearance-none text-slate-900 rounded-md block w-full px-3 h-12 sm:text-sm focus:outline-none placeholder:text-slate-400 focus:ring-2 focus:ring-sky-500 ring-1 ring-slate-200 border-neutral',
    radioLabel: 'block text-sm font-semibold leading-6 text-gray-500',
    radioInput: 'form-radio h-4 w-4 text-primary-600 transition duration-150 ease-in-out',
    radioInputSpan: 'ml-2 text-gray-700 mr-4',
    errorMessage: 'text-red-600 text-sm border-0',
  }
  return (
    <>
      <div className={`grid grid-cols-2 min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8 h-screen bg-blob`}>
        <div className="mx-auto">
          <img src={require('src/shared/assets/seaair_logo.png')} className="logo w-2/3" />
          <br />
          <h1 className="text-white font-bold">
            SEAAIR <br /> Conference Management System
          </h1>
        </div>

        <div className="w-full max-w-md space-y-8">
          <div>
            {(!http || http.status === HTTP_STATUS.IDLE) && (
              <>
                <h2 className="mt-6 text-left text-3xl font-bold tracking-tight text-black">
                  Enter your <br />
                  New Password
                </h2>
              </>
            )}
            {http?.status === HTTP_STATUS.SUCCESS && (
              <>
                <h2 className="mt-6 text-left text-3xl font-bold tracking-tight text-black">
                  Password <br />
                  Updated
                </h2>
                <span>
                  Your password has been successfully updated. You can now log in using your new password&nbsp;
                  <NavLink to={ROUTE_PATH.LOGIN} replace className="underline underline-offset-2">
                    here
                  </NavLink>
                  .
                </span>
              </>
            )}
            {http?.status === HTTP_STATUS.FAIL && (
              <>
                <h2 className="mt-6 text-left text-3xl font-bold tracking-tight text-black">
                  Request <br />
                  Unsuccessful
                </h2>
                <span className="text-primary-500">{http.message}</span>
              </>
            )}
          </div>
          <NotificationBar notification={useNotification} includeOrigin="resetPassword" />
          <form onSubmit={handleSubmit(onSavePassword)}>
            <div className={`${styles.inputSection}`}>
              <label htmlFor="code" className={`${styles.label}`}>
                New Password
              </label>

              <input {...register('password')} id="password" type="password" className={`${styles.input}`} />
              {errors.password && <span className={`${styles.errorMessage}`}>{errors.password.message?.toString()}</span>}
            </div>
            <div className={`${styles.inputSection}`}>
              <label htmlFor="code" className={`${styles.label}`}>
                Confirm Password
              </label>
              <input {...register('passwordConfirm')} id="passwordConfirm" type="password" className={`${styles.input}`} />
              {errors.passwordConfirm && <span className={`${styles.errorMessage}`}>{errors.passwordConfirm.message?.toString()}</span>}
            </div>

            <div className="flex justify-between items-center mt-4">
              <Button type="submit">Save</Button>
              <div className="text-right">
                <NavLink to={ROUTE_PATH.LOGIN} replace className="underline underline-offset-2">
                  {' '}
                  Sign In
                </NavLink>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}
export default ResetPasswordPage
