import React from 'react'
import { DocumentArrowDownIcon, UserPlusIcon } from '@heroicons/react/24/outline'
import Icon from 'src/packages/core/icon'
import { TSubmission, TSubmissionItem } from '../data'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'src/store/hooks'
import { clearMessage, setMessage } from 'src/packages/product/notification/state'
import { getSubmissionList } from '../state'
import { API_URL, ROUTE_PATH, SHARED } from 'src/shared/data'
import { setToast } from '@/packages/product/toast/state'

const useFetchSubmissionList = (dispatch: any, setData: any, refreshData: boolean) => {
  useEffect(() => {
    const getSubmissionListAsync = async () => {
      const submission = await dispatch(getSubmissionList())
      if (submission?.payload?.data?.length === 0) {
        dispatch(setMessage('No records found'))
      }

      setData(submission?.payload)
    }

    getSubmissionListAsync()
  }, [dispatch, setData, refreshData])
}

const useHook = () => {
  const [searchTerm, setSearchTerm] = useState('')
  const dispatch = useAppDispatch()

  const navigate = useNavigate()
  const [data, setData] = useState<TSubmission>()
  const [refreshData, setRefreshData] = useState(false)
  const { activeRole } = useAppSelector((state) => state.user)

  const useNotification = useAppSelector((state) => state.message)

  const useClearMessageOnUnmount = () => {
    useEffect(() => {
      return () => {
        dispatch(clearMessage())
      }
    }, [dispatch])
  }

  const useFetchData = () => {
    useFetchSubmissionList(dispatch, setData, refreshData)
  }

  const replaceIdInRoute = (route: string, id: number) => {
    return route.replace(':id', id.toString())
  }

  const handleRowClick = (item: any) => {
    const { USER, REVIEWER, ADMIN } = SHARED.ROLES
    const routes = {
      [USER]: ROUTE_PATH.SUBMISSION_ABSTRACT_REVIEWED,
      [REVIEWER]: ROUTE_PATH.SUBMISSION_REVIEWER_DETAIL,
      [ADMIN]: ROUTE_PATH.SUBMISSION_SUMMARY,
    }

    const route = routes[activeRole.code]
    if (route) {
      navigate(replaceIdInRoute(route, item.id))
    }
  }

  const useModalHook = () => {
    const [isOpen, setIsOpen] = useState(false)
    const [items, setItems] = useState<TSubmissionItem[]>([])

    const handleButtonClick = useCallback((item: TSubmissionItem) => {
      setIsOpen(true)
      setItems((prevItems) => [...prevItems, item])
    }, [])

    const handleModalClose = useCallback(() => {
      setIsOpen(false)
      setItems([])
    }, [])

    const handleConfirm = useCallback(async () => {
      console.log('items 2', items)
      setIsOpen(false)
      setItems([])
      setRefreshData((prev) => !prev)
    }, [items])

    return { isOpen, handleButtonClick, handleModalClose, handleConfirm, items }
  }

  return {
    searchTerm,
    useFetchData,
    data,
    useNotification,
    handleRowClick,
    useClearMessageOnUnmount,
    useModalHook,
    setSearchTerm,
  }
}

const useTable = ({
  data,
  handleAssigneeModalToggle,
}: {
  data: TSubmission | undefined
  handleAssigneeModalToggle: (data: TSubmissionItem) => void
}) => {
  const { activeRole } = useAppSelector((state) => state.user)
  const dispatch = useAppDispatch()
  const handleDownloadPaper = async (item: any) => {
    if (!item.file || item.file.length === 0) return
    const filePath = item.file[item.file.length - 1].filePath
    // if (!filePath) return
    console.log('download paper', filePath)
    const downloadUrl = `${API_URL}/${filePath}`
    const response = await fetch(downloadUrl)
    const blob = await response.blob()
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    link.download = filePath.split('/').pop() || '' // Set the filename to the last segment of the URL
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const noPaper = () => {
    dispatch(setToast({ message: 'No file found', variant: 'error' }))
  }

  const records = data
  interface IStyles {
    [key: string]: { [key: string]: string }
  }
  const styles: IStyles = {
    paperType: {
      ABS: 'bg-sky-400 text-white text-md  mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-blue-400 border border-blue-400',
      FP: 'bg-teal-300 text-black text-md  mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-blue-400 border border-teal-400',
    },
    paperStatus: {
      NEW: 'bg-zinc-400 dark:bg-gray-700 ',
      IN_PROGRESS: 'bg-yellow-400 dark:bg-gray-700 ',
      COMPLETED: 'bg-cyan-400 dark:bg-gray-700 ',
      REVIEWED: 'bg-cyan-400 dark:bg-gray-700 ',
      ACCEPTED: 'bg-green-500 dark:bg-gray-700 ',
      REJECTED: 'bg-destructive dark:bg-gray-700 ',
    },
    reviewStatus: {
      NOT_ASSIGNED: 'bg-zinc-500 dark:bg-gray-700 ',
      ASSIGNED: 'bg-yellow-500 dark:bg-gray-700 ',
      REVIEWED: 'bg-cyan-500 dark:bg-gray-700 ',
      ACCEPTED: 'bg-green-500 dark:bg-gray-700 ',
      REJECTED: 'bg-destructive dark:bg-gray-700 ',
    },
  }

  const { USER, REVIEWER, ADMIN } = SHARED.ROLES
  const actionItems = {
    [USER]: () => {
      return
    },
    [REVIEWER]: (item: any) => {
      const canDownload = item.paperType.code === SHARED.PAPER_TYPE.FP && item.file.length > 0
      return canDownload ? (
        <Icon
          icon={
            <DocumentArrowDownIcon
              className="w-5 mr-2 text-right"
              onClick={() => {
                console.log('yes', canDownload, item)
                handleDownloadPaper(item)
              }}
            />
          }
        />
      ) : null
    },
    [ADMIN]: (item: any) => {
      const canDownload = item.paperType.code === SHARED.PAPER_TYPE.FP && item.file.length > 0
      return (
        <div className="flex">
          <Icon icon={<UserPlusIcon className="w-5 mr-2" onClick={() => handleAssigneeModalToggle(item)} />} />
          {canDownload ? (
            <Icon
              icon={
                <DocumentArrowDownIcon
                  className="w-5 mr-2"
                  onClick={() => {
                    console.log('yes', canDownload, item)
                    handleDownloadPaper(item)
                  }}
                />
              }
            />
          ) : null}
        </div>
      )
    },
  }

  const findReviewByPaperType = (item: any) => item.review.find((review: any) => review.paperType?.code === item.paperType?.code)

  const reviewStatusName = (item: any, returnField: string) => {
    const review = findReviewByPaperType(item)

    if (item.review.length === 0) return 'NOT_ASSIGNED'
    let hasReviewed = false
    let hasAssigned = false
    for (const reviewItem of item.review) {
      if (reviewItem.paperType.code === item.paperType.code) {
        if (reviewItem.reviewStatus.code === 'REVIEWED') hasReviewed = true
        if (reviewItem.reviewStatus.code === 'ASSIGNED') hasAssigned = true
      }
    }
    if (hasReviewed && hasAssigned) return 'ASSIGNED'
    return review?.reviewStatus[returnField]
  }

  const paperTypeHasReview = (item: any) => Boolean(findReviewByPaperType(item))

  const reviewerColumns = [
    {
      key: 'code',
      label: 'ID',
      render: (item: any) => item.code,
    },
    {
      key: 'title',
      label: 'Titles',
      render: (item: any) => (
        <>
          <span className={styles.paperType[item.paperType.code]}>{item.paperType.code}</span>
          {item.title}
        </>
      ),
    },
    {
      key: 'submitted_date',
      label: 'Submitted Date',
      render: (item: any) => item.submitted_date,
      // admin view only
    },
    {
      key: 'reviewStatus',
      label: 'Status',
      render: (item: any) => (
        <>
          <span
            data-tooltip-target={`reviewStatus-tooltip-${item.id}`}
            className={`${styles.reviewStatus[reviewStatusName(item, 'code')]} block rounded-full w-3 h-3 m-auto`}
            title={reviewStatusName(item, 'name')}
          ></span>
        </>
      ),
    },
    {
      key: 'action',
      label: '',
      render: (item: any) => {
        return item.paperType.code === SHARED.PAPER_TYPE.FP ? actionItems[activeRole.code](item) : null
      },
    },
  ]

  const adminColumns = [
    {
      key: 'code',
      label: 'ID',
      render: (item: any) => item.code,
    },
    {
      key: 'name',
      label: 'Author',
      render: (item: any) => item.user?.firstName + ' ' + item.user?.lastName,
    },
    {
      key: 'title',
      label: 'Titles',
      render: (item: any) => (
        <>
          <span className={styles.paperType[item.paperType.code]}>{item.paperType.code}</span>
          {item.title}
        </>
      ),
    },
    {
      key: 'submitted_date',
      label: 'Submitted Date',
      render: (item: any) => item.submitted_date,
    },
    {
      key: 'review',
      label: 'Assignees',
      render: (item: any) => {
        const reviewers: any = []
        const { review } = item
        if (review.length === 0) return ''
        for (const reviewItem of review) {
          if (reviewItem.paperType.code === item.paperType.code)
            reviewers.push(`${reviewItem.user?.firstName} ${reviewItem.user?.lastName}`)
        }
        return reviewers.join(', ')
      },
    },
    {
      key: 'reviewStatus',
      label: 'Review Status',
      render: (item: any) => (
        <>
          <span
            className={`${
              !paperTypeHasReview(item) ? styles.reviewStatus['NOT_ASSIGNED'] : styles.reviewStatus[reviewStatusName(item, 'code')]
            } block rounded-full w-3 h-3 m-auto`}
            title={reviewStatusName(item, 'name')}
          ></span>
        </>
      ),
    },
    {
      key: 'action',
      label: '',
      render: (item: any) => {
        return actionItems[activeRole.code](item)
      },
    },
  ]

  const userColumns = [
    {
      key: 'code',
      label: 'ID',
      render: (item: any) => item.code,
    },
    {
      key: 'title',
      label: 'Titles',
      render: (item: any) => (
        <>
          <span className={styles.paperType[item.paperType.code]}>{item.paperType.code}</span>
          {item.title}
        </>
      ),
    },
    {
      key: 'submitted_date',
      label: 'Submitted Date',
      render: (item: any) => item.submitted_date,
      // admin view only
    },
    {
      key: 'paperStatus',
      label: 'Status',
      render: (item: any) => (
        <>
          <span
            data-tooltip-target={`paperStatus-tooltip-${item.id}`}
            className={`${styles.paperStatus[item.paperStatus.code]} block rounded-full w-3 h-3 m-auto`}
            title={item.paperStatus.name}
          ></span>
          <div
            id={`paperStatus-tooltip-${item.id}`}
            role="tooltip"
            className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-500 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700"
          >
            {item.paperStatus.name}
            <div className="tooltip-arrow" data-popper-arrow></div>
          </div>
        </>
      ),
    },
    {
      key: 'action',
      label: '',
      render: (item: any) => {
        return actionItems[activeRole.code](item)
      },
    },
  ]
  const displayColumns = {
    [ADMIN]: adminColumns,
    [REVIEWER]: reviewerColumns,
    [USER]: userColumns,
  }
  const columns = displayColumns[activeRole.code]

  return { records, columns }
}

export const SubmissionListHook = {
  useHook,
  useTable,
}
