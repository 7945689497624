import { API_URL, FILTER_ORDER } from 'src/shared/data'
import { HttpService } from 'src/shared/http/service'
import { ENDPOINT, TReviewerAssignmentCreate } from '../data'
import { TReviewerAssignmentReqData } from 'src/pages/user/views/reviewer/data'

const http = new HttpService()

const getReviewerAssignmentList = async (query: TReviewerAssignmentReqData) => {
  return await http.get(`${API_URL}/${ENDPOINT.REVIEWER_ASSIGNMENT_LIST}/${query.paperId}?type=${query.type}`)
}

const setReviewerAssignment = async (data: TReviewerAssignmentCreate) => {
  const { paperId, assignments } = data
  return await http.post(`${API_URL}/${ENDPOINT.REVIEWER_ASSIGNMENT_LIST}/${paperId}`, assignments)
}
export const ReviewerAssignmentService = { getReviewerAssignmentList, setReviewerAssignment }
