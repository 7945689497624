import React, { useState } from 'react'
import Select from 'react-select'
import { PlusIcon } from '@heroicons/react/24/outline'
import Button from 'src/packages/core/button'
import Icon from 'src/packages/core/icon'
import { useNavigate } from 'react-router-dom'
import { ROUTE_PATH, SHARED } from 'src/shared/data'
import { TRole } from '../user/data'
import { setToast } from '@/packages/product/toast/state'
import { useAppSelector, useAppDispatch } from '@/store/hooks'

type TSearchFormProps = {
  onSearch: (column: string, value: string) => void
  activeRole: TRole
}

const SubmissionTopBar = ({ onSearch, activeRole }: TSearchFormProps) => {
  const [searchValue, setSearchValue] = useState('')
  const [searchColumn, setSearchColumn] = useState('all')
  const navigate = useNavigate()
  const conference = useAppSelector((state) => state.conference.data)
  const dispatch = useAppDispatch()

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    onSearch('handle submit all', searchValue)
  }

  const columnOptions = [
    { value: 'all', label: 'All' },
    { value: 'reviewStatus', label: 'Review Status' },
    { value: 'type', label: 'Submission Type' },
  ]

  const valueOptions: any = {
    all: [],
    reviewStatus: [
      { value: SHARED.REVIEW_STATUS.NOT_ASSIGNED, label: 'Not Assigned' },
      { value: SHARED.REVIEW_STATUS.ASSIGNED, label: 'Assigned' },
      { value: SHARED.REVIEW_STATUS.REVIEWED, label: 'Reviewed' },
      { value: SHARED.REVIEW_STATUS.REJECTED, label: 'Rejected' },
      { value: SHARED.REVIEW_STATUS.ACCEPTED, label: 'Accepted' },
    ],
    type: [
      { value: SHARED.PAPER_TYPE.ABS, label: SHARED.PAPER_TYPE.ABS },
      { value: SHARED.PAPER_TYPE.FP, label: SHARED.PAPER_TYPE.FP },
    ],
  }

  const handleColumnChange = (selectedOption: any) => {
    setSearchColumn(selectedOption.value)
    setSearchValue('')
    if (selectedOption.value === 'all') {
      onSearch('all', '')
    }
  }
  const handleValueChange = (selectedOption: any) => {
    setSearchValue(selectedOption.value)
    onSearch(searchColumn, selectedOption.value)
  }

  const handleNewSubmission = () => {
    const conf = conference[0]
    if (conf?.abstract_open_date && conf?.abstract_close_date) {
      const today = new Date()
      const abstractOpenDate = new Date(conf.abstract_open_date)
      const abstractCloseDate = new Date(conf.abstract_close_date)
      if (today >= abstractOpenDate && today <= abstractCloseDate) {
        navigate(ROUTE_PATH.SUBMISSION_ADD)
      } else {
        dispatch(setToast({ message: 'No conference submission date are open', variant: 'error' }))
      }
    }
  }

  return (
    <div className="flex">
      <form onSubmit={handleSubmit} className="w-1/2 flex">
        <div className="relative rounded-md flex w-full">
          <Select
            className="w-1/2 mr-4"
            options={columnOptions}
            placeholder="Search columns"
            isSearchable={false}
            onChange={handleColumnChange}
          />
          <Select
            className="w-1/2 mr-4"
            options={valueOptions[searchColumn]}
            placeholder="Search value"
            isSearchable={false}
            value={valueOptions[searchColumn]?.find((option: any) => option.value === searchValue) ?? null}
            onChange={handleValueChange}
            isDisabled={searchColumn === 'all'}
          />
        </div>
      </form>
      {activeRole?.code === SHARED.ROLES.USER && (
        <Button type="button" className="ml-6" onClick={() => handleNewSubmission()}>
          <Icon icon={<PlusIcon className="w-5 mt-0.5" />} css={'w-7'} /> New Submission
        </Button>
      )}
    </div>
  )
}

export default SubmissionTopBar
