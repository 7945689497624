import { HttpResponseSchema } from 'src/shared/http/data'
import { z } from 'zod'

const module = 'users'
export const RegisterSchema = z
  .object({
    firstName: z.string().default(''),
    lastName: z.string().default(''),
    email: z.string().email().default(''),
    password: z
      .string()
      .refine((value) => /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/.test(value), {
        message: 'Password must have at least 8 characters, 1 number, 1 uppercase, and 1 lowercase',
      })
      .default(''),
    passwordConfirm: z
      .string()
      .refine((value) => /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/.test(value), {
        message: 'Password must have at least 8 characters, 1 number, 1 uppercase, and 1 lowercase',
      })
      .default(''),
  })
  .merge(HttpResponseSchema)
  .superRefine(({ passwordConfirm, password }, ctx) => {
    if (passwordConfirm !== password) {
      ctx.addIssue({
        code: 'custom',
        message: 'The passwords did not match',
      })
    }
  })

export type TRegister = z.infer<typeof RegisterSchema>
// export const RegisterState: TRegister = RegisterSchema.parse({
//   name: '',
//   email: '',
//   password: '',
//   passwordConfirm: '',
// })
export const RegisterState = { firstName: '', lastName: '', email: '', password: '', passwordConfirm: '' }
export const STATE = {
  LOCAL_REGISTER: 'local/register',
}

export const ENDPOINT = {
  LOCAL_REGISTER: `${module}/register`,
}

export const REGISTER_FORM = [
  {
    columns: [
      {
        name: 'firstName',
        type: 'text',
        label: 'First Name',
      },
      {
        name: 'lastName',
        type: 'text',
        label: 'Last Name',
      },
      {
        name: 'email',
        type: 'email',
        label: 'Email',
      },
      {
        name: 'password',
        type: 'password',
        label: 'Password',
      },
      {
        name: 'passwordConfirm',
        type: 'password',
        label: 'Confirm Password',
      },
    ],
  },
]
