import React from 'react'
import { API_URL } from 'src/shared/data'
import { HttpService } from 'src/shared/http/service'
import { ENDPOINT } from '../data/user.data'
import store from 'src/store/store'
import { TRoleManagement, TUserAddress, TUserInfo } from '../data'
import qs from 'query-string'
const http = new HttpService()

const getUserDetails = async () => {
  const { id } = store.getState().auth
  return await http.get(`${API_URL}/${ENDPOINT.USER_DETAILS}/${id}`)
}

const getUserRoles = async () => {
  return await http.get(`${API_URL}/${ENDPOINT.USER_ROLES}`)
}

const getUserAccountStatusList = async () => {
  return await http.get(`${API_URL}/${ENDPOINT.USER_ACCOUNT_STATUS_LIST}`)
}

const createUserProfile = async (data: any) => {
  return await http.post(`${API_URL}/${ENDPOINT.USER_DETAILS}`, data)
}

const updateUserProfile = async (data: any) => {
  const { userId, ...rest } = data
  console.log('the rest', rest)
  return await http.patch(`${API_URL}/${ENDPOINT.USER_DETAILS}/${userId}`, rest)
}

const createUserAddressBook = async (data: TUserAddress, query?: any) => {
  const queryString = query ? `?${qs.stringify(query)}` : ''
  return await http.post(`${API_URL}/${ENDPOINT.USER_ADDRESS}${queryString}`, data)
}

const createUserRole = async (data: TRoleManagement[]) => {
  return await http.post(`${API_URL}/${ENDPOINT.USER_ROLES}`, data)
}

const updateAccountPassword = async (data: any) => {
  const { userId, ...rest } = data
  const endpoint = ENDPOINT.USER_PASSWORD.replace(':id', userId)
  return await http.patch(`${API_URL}/${endpoint}`, rest)
}

const deleteUserAccount = async (data: number) => {
  return await http.delete(`${API_URL}/${ENDPOINT.USER_DETAILS}/${data}`)
}

const UserService = {
  getUserDetails,
  getUserRoles,
  getUserAccountStatusList,
  createUserProfile,
  createUserAddressBook,
  createUserRole,
  updateAccountPassword,
  updateUserProfile,
  deleteUserAccount,
}

export default UserService
