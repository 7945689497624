import React, { PropsWithChildren } from 'react'
import { Button as ButtonShadcn } from 'src/components/shadcn/ui/button'

type Expand = 'full' | 'clear' | undefined
type Fill = 'solid' | 'outline' | 'clear' | undefined
type Type = 'button' | 'submit' | 'reset'
type ButtonProps = PropsWithChildren<{
  onClick?: () => void | Promise<void>
  expand?: Expand
  fill?: Fill
  type?: Type
  css?: string
}>

type SizeShadcn = 'default' | 'sm' | 'lg' | 'icon'
type FillShadcn = 'default' | 'destructive' | 'outline' | 'secondary' | 'ghost' | 'link'
type ButtonPropsShadcn = PropsWithChildren<{
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void | Promise<void>
  expand?: SizeShadcn
  fill?: FillShadcn
  type?: Type
  className?: string
}>

// const Button = ({ children, onClick, expand, fill, type = 'button', css = 'mt-10' }: ButtonProps) => {
//   const expandClass = (expand: Expand) => {
//     switch (expand) {
//       case 'full':
//         return 'w-full'
//       case 'clear':
//         return 'px-0'
//       default:
//         return 'px-12'
//     }
//   }

//   const fillClass = (fill: Fill) => {
//     switch (fill) {
//       case 'solid':
//       default:
//         return {
//           button: 'bg-primary-600 hover:bg-primary-700 border border-transparent transition duration-300 hover:ease-in-out',
//           text: 'text-white',
//         }
//       case 'outline':
//         return { button: 'bg-transparent hover:bg-neutral-100 border-2 border-primary-600 transition duration-300 hover:ease-in-out' }
//       case 'clear':
//         return { button: 'bg-transparent hover:bg-transparent border border-transparent' }
//     }
//   }

//   return (
//     <button
//       type={type}
//       onClick={onClick}
//       className={`group inline-flex items-center rounded-md py-2 font-medium ${expandClass(expand)} ${fillClass(fill).button} ${css}`}
//     >
//       <span className={`w-full text-slate-800 flex ${fillClass(fill).text}`}>{children}</span>
//     </button>
//   )
// }
// export default Button
const Button = ({ onClick, children, fill = 'default', className = '' }: ButtonPropsShadcn) => (
  <ButtonShadcn onClick={onClick} className={`${className}`} variant={fill}>
    {children}
  </ButtonShadcn>
)

export default Button
