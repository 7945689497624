import { HttpResponseSchema } from 'src/shared/http/data'
import { MetaSchema } from 'src/shared/http/data/meta-data'
import { z } from 'zod'

export const KeywordItemSchema = z.object({
  value: z.number().nullable().default(null),
  label: z.string().default(''),
})

const KeywordSchema = z.object({
  data: z.array(KeywordItemSchema).default([]),
  meta: MetaSchema.default({}),
  http: HttpResponseSchema.default({}),
})

export type TKeyword = z.infer<typeof KeywordSchema>
export type TKeywordItem = z.infer<typeof KeywordItemSchema>

export const KeywordState: TKeyword = KeywordSchema.parse({})
