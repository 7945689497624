import React, { useCallback } from 'react'
import { TrashIcon } from '@heroicons/react/24/outline'
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Icon from 'src/packages/core/icon'
import { clearMessage, setMessage } from 'src/packages/product/notification/state'
import { TConferenceItemUpdate } from 'src/pages/conference/data'
import { deleteConference, getConferences } from 'src/pages/conference/state'
import { useAppDispatch, useAppSelector } from 'src/store/hooks'
import { ROUTE_PATH } from 'src/shared/data'
import moment from 'moment'

const useHook = () => {
  const [searchTerm, setSearchTerm] = useState('')
  const dispatch = useAppDispatch()
  const user = useAppSelector((state) => state.user)
  const navigate = useNavigate()
  const [data, setData] = useState<TConferenceItemUpdate[]>([])
  const [refreshData, setRefreshData] = useState(false)

  const useNotification = useAppSelector((state) => state.message)

  const useClearMessageOnUnmount = () => {
    useEffect(() => {
      return () => {
        dispatch(clearMessage())
      }
    }, [])
  }

  const useFetchData = () => {
    useEffect(() => {
      let conference: any
      const getConferenceListAsync = async () => {
        conference = await dispatch(getConferences({}))
        if (!conference.payload) dispatch(setMessage('No records found'))
        console.log('conference.payload: ', conference.payload.data)
        setData(conference.payload.data)
        return conference.payload
      }
      getConferenceListAsync()
      return () => {
        conference
      }
    }, [])
  }

  const handleRowClick = (item: any) => {
    const path = ROUTE_PATH.CONFERENCE_DETAIL.replace(':id', `${item.id}`)
    navigate(`${path}`)
  }
  const useModalHook = () => {
    // const dispatch = useAppDispatch()
    const [isOpen, setIsOpen] = useState(false)
    const [items, setItems] = useState<TConferenceItemUpdate[]>([])

    const handleButtonClick = useCallback((item: TConferenceItemUpdate) => {
      setIsOpen(true)
      setItems((prevItems) => [...prevItems, item])
    }, [])

    const handleModalClose = useCallback(() => {
      setIsOpen(false)
      setItems(() => [])
    }, [])

    const handleConfirm = async () => {
      setIsOpen(false)
      try {
        await dispatch(deleteConference(items[0].id))
        setItems(() => [])
        setRefreshData((prev) => !prev)
      } catch (error) {
        console.log(error)
      }
    }

    return { isOpen, handleButtonClick, handleModalClose, handleConfirm, items }
  }

  return {
    useClearMessageOnUnmount,
    useFetchData,
    data,
    useNotification,
    handleRowClick,
    user,
    useModalHook,
    searchTerm,
    setSearchTerm,
    setRefreshData,
  }
}

const table = ({
  data,
  removeRecord,
}: {
  data: TConferenceItemUpdate[] | undefined
  removeRecord: (item: TConferenceItemUpdate) => void
}) => {
  const records = data

  const columns = [
    {
      key: 'name',
      label: 'Conference',
      render: (item: any) => item.name,
    },
    {
      key: 'country',
      label: 'Country',
      render: (item: any) => item.country,
    },
    {
      key: 'conference_date',
      label: 'Conference Date',
      render: (item: any) => moment(item.conference_date).format('MMM DD, YYYY'),
    },
    {
      key: 'action',
      label: '',
      render: (item: any) => {
        return <Icon icon={<TrashIcon className="w-5 mr-2 text-right" onClick={() => removeRecord(item)} />} />
      },
    },
  ]

  return { records, columns }
}
export const ConferenceListHook = {
  useHook,
  table,
}
