import React, { useEffect, useRef, useState } from 'react'
import { ConferenceDetailHook } from './service/conference-detail.hook'
import NotificationBar from 'src/packages/product/notification'
import NotificationHook from 'src/shared/service/notification.hook'
import Button from 'src/packages/core/button'
import { ConferenceItemSchema, defaultValues } from './data'
import { Controller, useForm } from 'react-hook-form'
import CreatableSelect from 'react-select/creatable'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { zodResolver } from '@hookform/resolvers/zod'

const ConferenceDetailPage = () => {
  const { useHook } = ConferenceDetailHook
  const { useFetchData, conference, matchPath, useUpdate, useCreate, useBack, subTheme } = useHook()
  const { useNotification } = NotificationHook
  const { notification } = useNotification()
  const defaultFormValues = conference ? conference : defaultValues
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    register,
    watch,
    reset,
  } = useForm({
    defaultValues: defaultFormValues,
    resolver: zodResolver(ConferenceItemSchema),
  })
  const displayTab = () => {
    console.log('display tab', conference, matchPath, defaultFormValues)
    if (conference && !matchPath) return true
    if (!conference && matchPath) return true
    return false
  }

  const [dateValues, setDateValues] = useState({})
  useFetchData()

  const myRef = useRef(null)

  const handleDateChange = (name: string, value: Date | null) => {
    if (!value) return
    console.log('handleDateChange', name, value.toISOString())
    setDateValues((prevState) => ({
      ...prevState,
      [name as keyof typeof prevState]: value,
    }))
    setValue(name as keyof typeof defaultValues, value.toISOString())
  }
  const handleCreatableSelectChange = (name: keyof typeof defaultValues, selectedOptions: any) => {
    const values = selectedOptions
      ? selectedOptions.map((option: { label: string; value: string; __isNew__?: boolean; name?: string; code?: string; id?: number }) => {
          return { label: option.label ?? option.name, value: option.value ?? option.id }
        })
      : []
    setValue(name, values)
  }

  useEffect(() => {
    if (myRef.current && notification) (myRef.current as HTMLElement)?.scrollIntoView({ behavior: 'smooth' })
    if (conference) {
      console.log('conference reset form', conference)
      reset(conference) // reset the form with the new default values
    }
  }, [notification, conference])

  const styles = {
    title: 'font-bold text-2xl pt-8',
    inputSection: 'rounded-md pt-2',
    label: 'block text-md font-semibold leading-6 text-gray-500',
    input:
      'appearance-none text-slate-900 rounded-md block w-full px-3 h-12 sm:text-sm focus:outline-none placeholder:text-slate-400 focus:ring-2 focus:ring-sky-500 ring-1 ring-slate-200 border-neutral',
    radioLabel: 'block text-sm font-semibold leading-6 text-gray-500',
    radioInput: 'form-radio h-4 w-4 text-primary-600 transition duration-150 ease-in-out',
    radioInputSpan: 'ml-2 text-gray-700 mr-4',
    errorMessage: 'text-red-600 text-sm border-0',
  }
  const format = {
    date: 'yyyy / MM / dd',
  }
  const today = new Date()
  const yesterday = new Date(today)
  yesterday.setDate(today.getDate() - 1)
  const maxDate = new Date(today)
  maxDate.setFullYear(today.getFullYear() + 5)
  console.log('yesterday', conference)
  return (
    <>
      {displayTab() && (
        <>
          <div ref={myRef} className="scroll-mt-2 mt-2"></div>
          <NotificationBar notification={notification} includeOrigin="conference" />

          <form onSubmit={conference ? handleSubmit(useUpdate) : handleSubmit(useCreate)}>
            <h2 className={`${styles.title}`}>Conference Info</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-y-4 gap-x-10">
              <div className={`${styles.inputSection}`}>
                <label htmlFor="code" className={`${styles.label}`}>
                  Conference Acronym
                </label>
                <Controller
                  name="code"
                  control={control}
                  defaultValue={defaultFormValues.code}
                  render={({ field }) => <input {...field} id="code" type="text" className={`${styles.input}`} />}
                />
                {errors.code && <span className={`${styles.errorMessage}`}>{errors.code.message}</span>}
              </div>
              <div className={`${styles.inputSection}`}>
                <label htmlFor="name" className={`${styles.label}`}>
                  Name
                </label>
                <Controller
                  name="name"
                  control={control}
                  defaultValue={defaultFormValues.name}
                  render={({ field }) => <input {...field} id="name" type="text" className={`${styles.input}`} />}
                />
                {errors.name && <span className={`${styles.errorMessage}`}>{errors.name.message}</span>}
              </div>
              <div className={`${styles.inputSection}`}>
                <label htmlFor="description" className={`${styles.label}`}>
                  Description
                </label>
                <Controller
                  name="description"
                  control={control}
                  defaultValue={defaultFormValues.description}
                  render={({ field }) => <input {...field} id="description" type="text" className={`${styles.input}`} />}
                />
                {errors.description && <span className={`${styles.errorMessage}`}>{errors.description.message}</span>}
              </div>
              <div className={`${styles.inputSection}`}>
                <label htmlFor="conference_date" className={`${styles.label}`}>
                  Conference Date
                </label>
                <DatePicker
                  selected={watch('conference_date') ? new Date(watch('conference_date') as string) : null}
                  {...register('conference_date')}
                  onChange={(date) => handleDateChange('conference_date', date)}
                  // onChange={(date) => console.log('data', date)}
                  minDate={yesterday}
                  maxDate={maxDate}
                  showMonthYearDropdown
                  className={`${styles.input}`}
                  dateFormat={`${format.date}`}
                />
                {errors.conference_date && <span className={`${styles.errorMessage}`}>{errors.conference_date.message}</span>}
              </div>

              <div className={`${styles.inputSection}`}>
                <label htmlFor="webpage" className={`${styles.label}`}>
                  Webpage
                </label>
                <Controller
                  name="webpage"
                  control={control}
                  defaultValue={defaultFormValues.webpage}
                  render={({ field }) => <input {...field} id="webpage" type="text" className={`${styles.input}`} />}
                />
                {errors.webpage && <span className={`${styles.errorMessage}`}>{errors.webpage.message}</span>}
              </div>
              <div className={`${styles.inputSection}`}>
                <label htmlFor="address" className={`${styles.label}`}>
                  Address
                </label>
                <Controller
                  name="address"
                  control={control}
                  defaultValue={defaultFormValues.address}
                  render={({ field }) => <input {...field} id="address" type="text" className={`${styles.input}`} />}
                />
                {errors.address && <span className={`${styles.errorMessage}`}>{errors.address.message}</span>}
              </div>
              <div className={`${styles.inputSection}`}>
                <label htmlFor="city" className={`${styles.label}`}>
                  City
                </label>
                <Controller
                  name="city"
                  control={control}
                  defaultValue={defaultFormValues.city}
                  render={({ field }) => <input {...field} id="city" type="text" className={`${styles.input}`} />}
                />
                {errors.city && <span className={`${styles.errorMessage}`}>{errors.city.message}</span>}
              </div>
              <div className={`${styles.inputSection}`}>
                <label htmlFor="country" className={`${styles.label}`}>
                  Country
                </label>
                <Controller
                  name="country"
                  control={control}
                  defaultValue={defaultFormValues.country}
                  render={({ field }) => <input {...field} id="country" type="text" className={`${styles.input}`} />}
                />
                {errors.country && <span className={`${styles.errorMessage}`}>{errors.country.message}</span>}
              </div>
              <div className={`${styles.inputSection}`}>
                <label htmlFor="email" className={`${styles.label}`}>
                  Email
                </label>
                <Controller
                  name="email"
                  control={control}
                  defaultValue={defaultFormValues.email}
                  render={({ field }) => <input {...field} id="email" type="text" className={`${styles.input}`} />}
                />
                {errors.email && <span className={`${styles.errorMessage}`}>{errors.email.message}</span>}
              </div>
              <div className={`${styles.inputSection}`}>
                <label htmlFor="phone" className={`${styles.label}`}>
                  Phone
                </label>
                <Controller
                  name="phone"
                  control={control}
                  defaultValue={defaultFormValues.phone}
                  render={({ field }) => <input {...field} id="phone" type="text" className={`${styles.input}`} />}
                />
                {errors.phone && <span className={`${styles.errorMessage}`}>{errors.phone.message}</span>}
              </div>
            </div>
            <h2 className={`${styles.title}`}>Submission Form</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-y-4 gap-x-10">
              <div className={`${styles.inputSection}`}>
                <label htmlFor="submission_instruction" className={`${styles.radioLabel}`}>
                  Submission Instruction for Authors
                </label>
                <Controller
                  name="submission_instruction"
                  control={control}
                  defaultValue={defaultFormValues.submission_instruction}
                  render={({ field: { onChange, value } }) => (
                    <div>
                      <label htmlFor="submission_instruction_yes" className={`inline-flex items-center cursor-pointer`}>
                        <input
                          type="radio"
                          id="submission_instruction_yes"
                          value="true"
                          onChange={() => onChange(!value)}
                          checked={value == true}
                          className={`${styles.radioInput}`}
                        />
                        <span className={`${styles.radioInputSpan}`}>Yes</span>
                      </label>
                      <label htmlFor="submission_instruction_no" className={`inline-flex items-center cursor-pointer`}>
                        <input
                          type="radio"
                          id="submission_instruction_no"
                          value="false"
                          onChange={() => onChange(!value)}
                          checked={value == false}
                          className={`${styles.radioInput}`}
                        />
                        <span className={`${styles.radioInputSpan}`}>No</span>
                      </label>
                    </div>
                  )}
                />
                {errors.submission_instruction && <span className={`${styles.errorMessage}`}>{errors.submission_instruction.message}</span>}
              </div>
              {/* <div className={`${styles.inputSection}`}>
                <label htmlFor="submission_additional_field" className={`${styles.radioLabel}`}>
                  Additional Fields in the submission form
                </label>
                <Controller
                  name="submission_additional_field"
                  control={control}
                  defaultValue={defaultFormValues.submission_additional_field}
                  render={({ field: { onChange, value } }) => (
                    <div>
                      <label htmlFor="submission_additional_field_yes" className={`inline-flex items-center cursor-pointer`}>
                        <input
                          type="radio"
                          id="submission_additional_field_yes"
                          value="true"
                          onChange={() => onChange(!value)}
                          checked={value == true}
                          className={`${styles.radioInput}`}
                        />
                        <span className={`${styles.radioInputSpan}`}>Yes</span>
                      </label>
                      <label htmlFor="submission_additional_field_no" className={`inline-flex items-center cursor-pointer`}>
                        <input
                          type="radio"
                          id="submission_additional_field_no"
                          value="false"
                          onChange={() => onChange(!value)}
                          checked={value == false}
                          className={`${styles.radioInput}`}
                        />
                        <span className={`${styles.radioInputSpan}`}>No</span>
                      </label>
                    </div>
                  )}
                />
              </div> */}
              <div className={`${styles.inputSection}`}>
                <label htmlFor="submission_allow_updates" className={`${styles.radioLabel}`}>
                  Allow updates after submission is created
                </label>
                <Controller
                  name="submission_allow_updates"
                  control={control}
                  defaultValue={defaultFormValues.submission_allow_updates}
                  render={({ field: { onChange, value } }) => (
                    <div>
                      <label htmlFor="submission_allow_updates_yes" className={`inline-flex items-center cursor-pointer`}>
                        <input
                          type="radio"
                          id="submission_allow_updates_yes"
                          value="true"
                          onChange={() => onChange(!value)}
                          checked={value == true}
                          className={`${styles.radioInput}`}
                        />
                        <span className={`${styles.radioInputSpan}`}>Yes</span>
                      </label>
                      <label htmlFor="submission_allow_updates_no" className={`inline-flex items-center cursor-pointer`}>
                        <input
                          type="radio"
                          id="submission_allow_updates_no"
                          value="false"
                          onChange={() => onChange(!value)}
                          checked={value == false}
                          className={`${styles.radioInput}`}
                        />
                        <span className={`${styles.radioInputSpan}`}>No</span>
                      </label>
                    </div>
                  )}
                />
              </div>
              <div className={`${styles.inputSection}`}>
                <label htmlFor="submission_template" className={`${styles.radioLabel}`}>
                  Submission Template for Authors
                </label>
                <Controller
                  name="submission_template"
                  control={control}
                  defaultValue={defaultFormValues.submission_template}
                  render={({ field: { onChange, value } }) => (
                    <div>
                      <label htmlFor="submission_template_yes" className={`inline-flex items-center cursor-pointer`}>
                        <input
                          type="radio"
                          id="submission_template_yes"
                          value="true"
                          onChange={() => onChange(!value)}
                          checked={value == true}
                          className={`${styles.radioInput}`}
                        />
                        <span className={`${styles.radioInputSpan}`}>Yes</span>
                      </label>
                      <label htmlFor="submission_template_no" className={`inline-flex items-center cursor-pointer`}>
                        <input
                          type="radio"
                          id="submission_template_no"
                          value="false"
                          onChange={() => onChange(!value)}
                          checked={value == false}
                          className={`${styles.radioInput}`}
                        />
                        <span className={`${styles.radioInputSpan}`}>No</span>
                      </label>
                    </div>
                  )}
                />
              </div>
              <div className={`${styles.inputSection}`}>
                <label htmlFor="submission_presenter" className={`${styles.radioLabel}`}>
                  Authors should select the presenter
                </label>
                <Controller
                  name="submission_presenter"
                  control={control}
                  defaultValue={defaultFormValues.submission_presenter}
                  render={({ field: { onChange, value } }) => (
                    <div>
                      <label htmlFor="submission_presenter_yes" className={`inline-flex items-center cursor-pointer`}>
                        <input
                          type="radio"
                          id="submission_presenter_yes"
                          value="true"
                          onChange={() => onChange(!value)}
                          checked={value == true}
                          className={`${styles.radioInput}`}
                        />
                        <span className={`${styles.radioInputSpan}`}>Yes</span>
                      </label>
                      <label htmlFor="submission_presenter_no" className={`inline-flex items-center cursor-pointer`}>
                        <input
                          type="radio"
                          id="submission_presenter_no"
                          value="false"
                          onChange={() => onChange(!value)}
                          checked={value == false}
                          className={`${styles.radioInput}`}
                        />
                        <span className={`${styles.radioInputSpan}`}>No</span>
                      </label>
                    </div>
                  )}
                />
              </div>
              <div className={`${styles.inputSection}`}>
                <label htmlFor="submission_multiple_author" className={`${styles.radioLabel}`}>
                  Allow multiple authors
                </label>
                <Controller
                  name="submission_multiple_author"
                  control={control}
                  defaultValue={defaultFormValues.submission_multiple_author}
                  render={({ field: { onChange, value } }) => (
                    <div>
                      <label htmlFor="submission_multiple_author_yes" className={`inline-flex items-center cursor-pointer`}>
                        <input
                          type="radio"
                          id="submission_multiple_author_yes"
                          value="true"
                          onChange={() => onChange(!value)}
                          checked={value == true}
                          className={`${styles.radioInput}`}
                        />
                        <span className={`${styles.radioInputSpan}`}>Yes</span>
                      </label>
                      <label htmlFor="submission_multiple_author_no" className={`inline-flex items-center cursor-pointer`}>
                        <input
                          type="radio"
                          id="submission_multiple_author_no"
                          value="false"
                          onChange={() => onChange(!value)}
                          checked={value == false}
                          className={`${styles.radioInput}`}
                        />
                        <span className={`${styles.radioInputSpan}`}>No</span>
                      </label>
                    </div>
                  )}
                />
              </div>
              <div className={`${styles.inputSection}`}>
                <label htmlFor="submission_abstract_length" className={`${styles.radioLabel}`}>
                  Submission Abstract Length
                </label>
                <Controller
                  name="submission_abstract_length"
                  control={control}
                  defaultValue={defaultFormValues.submission_abstract_length}
                  render={({ field }) => (
                    <input
                      {...field}
                      id="submission_abstract_length"
                      type="number"
                      value={typeof field.value === 'string' ? undefined : field.value}
                      {...register('submission_abstract_length', { valueAsNumber: true })}
                      className={`${styles.input}`}
                    />
                  )}
                />
                {errors.submission_abstract_length && (
                  <span className={`${styles.errorMessage}`}>{errors.submission_abstract_length.message}</span>
                )}
              </div>
              <div className={`${styles.inputSection}`}>
                <label htmlFor="submission_postal_address" className={`${styles.radioLabel}`}>
                  Do you require postal address for correspondence on submission forms?
                </label>
                <Controller
                  name="submission_postal_address"
                  control={control}
                  defaultValue={defaultFormValues.submission_postal_address}
                  render={({ field: { onChange, value } }) => (
                    <div>
                      <label htmlFor="submission_postal_address_yes" className={`inline-flex items-center cursor-pointer`}>
                        <input
                          type="radio"
                          id="submission_postal_address_yes"
                          value="true"
                          onChange={() => onChange(!value)}
                          checked={value == true}
                          className={`${styles.radioInput}`}
                        />
                        <span className={`${styles.radioInputSpan}`}>Yes</span>
                      </label>
                      <label htmlFor="submission_postal_address_no" className={`inline-flex items-center cursor-pointer`}>
                        <input
                          type="radio"
                          id="submission_postal_address_no"
                          value="false"
                          onChange={() => onChange(!value)}
                          checked={value == false}
                          className={`${styles.radioInput}`}
                        />
                        <span className={`${styles.radioInputSpan}`}>No</span>
                      </label>
                    </div>
                  )}
                />
                {errors.submission_postal_address && (
                  <span className={`${styles.errorMessage}`}>{errors.submission_postal_address.message}</span>
                )}
              </div>
              <div className={`${styles.inputSection}`}>
                <label htmlFor="submission_file_upload_field" className={`${styles.radioLabel}`}>
                  File Upload
                </label>
                <Controller
                  name="submission_file_upload_field"
                  control={control}
                  defaultValue={defaultFormValues.submission_file_upload_field}
                  render={({ field: { onChange, value } }) => (
                    <div>
                      <label htmlFor="submission_file_upload_field_yes" className={`inline-flex items-center cursor-pointer`}>
                        <input
                          type="radio"
                          id="submission_file_upload_field_yes"
                          value="true"
                          onChange={() => onChange(!value)}
                          checked={value == true}
                          className={`${styles.radioInput}`}
                        />
                        <span className={`${styles.radioInputSpan}`}>Yes</span>
                      </label>
                      <label htmlFor="submission_file_upload_field_no" className={`inline-flex items-center cursor-pointer`}>
                        <input
                          type="radio"
                          id="submission_file_upload_field_no"
                          value="false"
                          onChange={() => onChange(!value)}
                          checked={value == false}
                          className={`${styles.radioInput}`}
                        />
                        <span className={`${styles.radioInputSpan}`}>No</span>
                      </label>
                    </div>
                  )}
                />
              </div>
            </div>
            <h2 className={`${styles.title}`}>Sub-theme</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-y-4 gap-x-10">
              <div className={`${styles.inputSection}`}>
                <label htmlFor="sub_theme" className={`${styles.radioLabel}`}>
                  Submission Topics
                </label>
                <Controller
                  name="sub_theme"
                  control={control}
                  defaultValue={defaultFormValues.sub_theme}
                  render={({ field }) => (
                    <CreatableSelect
                      isClearable={true}
                      isMulti
                      getOptionLabel={(option: any) => option.name || option.label}
                      getOptionValue={(option: any) => option.id || option.value}
                      placeholder="Type something and press enter..."
                      {...field}
                      options={subTheme.map((theme) => ({ id: theme.value, name: theme.label }))}
                      onChange={(selectedOptions) => handleCreatableSelectChange('sub_theme', selectedOptions)}
                    />
                  )}
                />
              </div>
              {/* NOTE: topic min and max is hidden because it has changed to be drop-down with 1 selection only */}
              {/* <div className={`${styles.inputSection}`}>
                <label htmlFor="sub_theme_min_topic" className={`${styles.radioLabel}`}>
                  Submission Topic Min
                </label>
                <Controller
                  name="sub_theme_min_topic"
                  control={control}
                  defaultValue={defaultFormValues.sub_theme_min_topic}
                  render={({ field }) => (
                    <input
                      {...field}
                      id="sub_theme_min_topic"
                      type="number"
                      {...register('sub_theme_min_topic', { valueAsNumber: true })}
                      className={`${styles.input}`}
                    />
                  )}
                />
              </div>
              <div className={`${styles.inputSection}`}>
                <label htmlFor="sub_theme_max_topic" className={`${styles.radioLabel}`}>
                  Submission Topic Max
                </label>
                <Controller
                  name="sub_theme_max_topic"
                  control={control}
                  defaultValue={defaultFormValues.sub_theme_max_topic}
                  render={({ field }) => (
                    <input
                      {...field}
                      id="sub_theme_max_topic"
                      type="number"
                      {...register('sub_theme_max_topic', { valueAsNumber: true })}
                      className={`${styles.input}`}
                    />
                  )}
                />
              </div> */}
            </div>

            <h2 className={`${styles.title}`}>Paper Submission</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-y-4 gap-x-10">
              <div className={`${styles.inputSection}`}>
                <label htmlFor="abstract_open_date" className={`${styles.radioLabel}`}>
                  Abstract Open Date
                </label>
                <DatePicker
                  selected={watch('abstract_open_date') ? new Date(watch('abstract_open_date') as string) : null}
                  {...register('abstract_open_date')}
                  onChange={(date) => handleDateChange('abstract_open_date', date)}
                  minDate={yesterday}
                  maxDate={maxDate}
                  showMonthYearDropdown
                  className={`${styles.input}`}
                  dateFormat={`${format.date}`}
                  autoComplete="off"
                />
              </div>
              <div className={`${styles.inputSection}`}>
                <label htmlFor="abstract_close_date" className={`${styles.radioLabel}`}>
                  Abstract Close Date
                </label>
                <DatePicker
                  selected={watch('abstract_close_date') ? new Date(watch('abstract_close_date') as string) : null}
                  {...register('abstract_close_date')}
                  onChange={(date) => handleDateChange('abstract_close_date', date)}
                  minDate={yesterday}
                  maxDate={maxDate}
                  showMonthYearDropdown
                  className={`${styles.input}`}
                  dateFormat={`${format.date}`}
                  autoComplete="off"
                />
              </div>
              <div className={`${styles.inputSection}`}>
                <label htmlFor="full_paper_open_date" className={`${styles.radioLabel}`}>
                  Full Paper Open Date
                </label>
                <DatePicker
                  selected={watch('full_paper_open_date') ? new Date(watch('full_paper_open_date') as string) : null}
                  {...register('full_paper_open_date')}
                  onChange={(date) => handleDateChange('full_paper_open_date', date)}
                  minDate={yesterday}
                  maxDate={maxDate}
                  showMonthYearDropdown
                  className={`${styles.input}`}
                  dateFormat={`${format.date}`}
                  autoComplete="off"
                />
              </div>
              <div className={`${styles.inputSection}`}>
                <label htmlFor="full_paper_close_date" className={`${styles.radioLabel}`}>
                  Full Paper Close Date
                </label>
                <DatePicker
                  selected={watch('full_paper_close_date') ? new Date(watch('full_paper_close_date') as string) : null}
                  {...register('full_paper_close_date')}
                  onChange={(date) => handleDateChange('full_paper_close_date', date)}
                  minDate={yesterday}
                  maxDate={maxDate}
                  showMonthYearDropdown
                  className={`${styles.input}`}
                  dateFormat={`${format.date}`}
                  autoComplete="off"
                />
              </div>
            </div>
            <div className="mb-12 mt-10">
              <Button type="button" fill="outline" className="mr-6" onClick={useBack}>
                Back
              </Button>
              <Button type="submit" fill="default" className="mr-6">
                Save
              </Button>
            </div>
          </form>
        </>
      )}
    </>
  )
}

export default ConferenceDetailPage
