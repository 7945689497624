import { HttpResponseSchema } from 'src/shared/http/data'
import { z } from 'zod'

export const ENDPOINT = {
  CONFERENCE_SUBMISSION_SUMMARY: 'report/conference-submissions-summary',
  DOWNLOAD_REPORT: 'report/conference-submissions-summary/download',
}

export const STATE = {
  CONFERENCE_SUBMISSION_SUMMARY: 'conferenceSubmissionSummary/list',
  DOWNLOAD_REPORT: 'downloadReport',
}
const ConferenceSubmissionSummaryItemSchema = z.object({
  conference: z.object({
    title: z.string(),
    code: z.string(),
    country: z.string(),
    conference_date: z.string(),
  }),
  authors: z.number().nullable().default(null),
  totalPaperSubmitted: z.number().nullable().default(null),
  totalPaperRejected: z.number().nullable().default(null),
  totalAbstractAccepted: z.number().nullable().default(null),
  totalFullPaperAccepted: z.number().nullable().default(null),
})
const DownloadReportLinkSchema = z.object({
  filePath: z.string().nullable().default(''),
  fileName: z.string().nullable().default(''),
})
const ReportSchema = z.object({
  conferenceSubmissionSummary: z.array(ConferenceSubmissionSummaryItemSchema).default([]),
  downloadReportLink: DownloadReportLinkSchema.optional(),
  http: HttpResponseSchema.default({}),
})

export type TConferenceSubmissionSummaryItem = z.infer<typeof ConferenceSubmissionSummaryItemSchema>
export type TReport = z.infer<typeof ReportSchema>
export type TDownloadReportLink = z.infer<typeof DownloadReportLinkSchema>
export const ReportState: TReport = ReportSchema.parse({})
