/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useCallback } from 'react'
import { AlertDialog, AlertDialogContent, AlertDialogTitle, AlertDialogDescription, AlertDialogAction } from '@radix-ui/react-alert-dialog'
import { AlertDialogHeader, AlertDialogFooter } from 'src/components/shadcn/ui/alert-dialog'

type AlertModalProps = {
  open: boolean
  onOpenChange?: () => void
  data?: {
    message: string
    title: string
    actionButton: {
      text: string
      onClick: () => void
    }
  }
}

const AlertModal = ({
  open,
  onOpenChange,
  data = { message: '', title: '', actionButton: { text: 'OK', onClick: () => {} } },
}: AlertModalProps) => {
  const handleOpenChange = useCallback(() => {
    if (onOpenChange) {
      onOpenChange()
    }
  }, [onOpenChange])

  console.log('open in alert modal', open)

  return (
    <AlertDialog open={open} onOpenChange={handleOpenChange}>
      <AlertDialogContent className="flex absolute h-screen w-screen justify-center items-center bg-black/70 z-50">
        <div className="m-auto w-sc w-1/3 text-white bg-primary rounded-md p-5">
          <AlertDialogHeader>
            <AlertDialogTitle className="font-bold">{data.title}</AlertDialogTitle>
            <AlertDialogDescription>{data.message}</AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogAction onClick={data.actionButton.onClick}>{data.actionButton.text}</AlertDialogAction>
          </AlertDialogFooter>
        </div>
      </AlertDialogContent>
    </AlertDialog>
  )
}

export default React.memo(AlertModal)
