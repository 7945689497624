import { AxiosPromise } from 'axios'
import { z } from 'zod'

export enum HTTP_STATUS {
  IDLE = 'idle',
  SUCCESS = 'success',
  LOADING = 'loading',
  FAIL = 'fail',
}

export enum HTTP_STATUS_CODE {
  SUCCESS = 200,
  CREATED = 201,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  INTERNAL_SERVER_ERROR = 500,
  BAD_GATEWAY = 502,
  SERVICE_UNAVAILABLE = 503,
  GATEWAY_TIMEOUT = 504,
}

export const ERROR_CODE = {
  TOKEN_INVALID: 'A-I-1000', // Both Access and Refresh Token Invalid
  TOKEN_EXPIRED: 'A-E-1000', // Both Access and Refresh Token Expired
  MISSING_AUTH_TOKEN: 'A-M-1001', // Auth Token Missing
  MISSING_REFRESH_TOKEN: 'A-M-1002', // Refresh Token Missing
  INVALID_REFRESH_TOKEN: 'A-M-1003', // Refresh Token Invalid
  MISSING_PERMISSION: 'PERM-M-1001', // Missing permission
}

export type THttpService<T> = {
  get(url: string, config?: THttpConfig): AxiosPromise<T>
  post(url: string, data?: T, config?: THttpConfig): AxiosPromise<T>
  patch(url: string, data?: T, config?: THttpConfig): AxiosPromise<T>
  delete(url: string, config?: THttpConfig): AxiosPromise<T>
}

export const HttpResponseSchema = z.object({
  status: z.nativeEnum(HTTP_STATUS).default(HTTP_STATUS.IDLE),
  statusCode: z.number().default(0),
  message: z.string().default(''),
  data: z.any().optional().default(null),
})

const HttpConfigSchema = z.object({
  headers: z.any(),
  signal: z.any(),
})

const HttpRequestDataSchema = z.object({
  data: z.unknown(),
  query: z.any(),
})

export type THttpRequestData<T> = z.infer<typeof HttpRequestDataSchema> & { data: T }
export type THttpConfig = z.infer<typeof HttpConfigSchema>
