import React, { PropsWithChildren, MouseEvent } from 'react'

type ModalProps = PropsWithChildren<{
  isOpen: boolean
  onClose: () => void
  hideOnClickOutside?: boolean
  closeButtonLabel?: string | React.ReactNode
  css?: string
}>

const Modal = ({ children, isOpen, onClose, hideOnClickOutside = true, closeButtonLabel = 'x', css }: ModalProps) => {
  const handleClick = (e: MouseEvent) => {
    if (e.target instanceof HTMLElement && e.target.id === 'modal-background' && hideOnClickOutside) {
      onClose()
    }
  }

  return (
    <div
      id="modal-background"
      className={`fixed z-50 top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center transition-opacity ${
        isOpen ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'
      }`}
      onClick={handleClick}
    >
      <div className={`bg-white rounded-lg shadow-lg pt-2 p-5 text-black relative grid ${css} `}>
        <span className="static cursor-pointer self-end ml-auto" onClick={onClose}>
          {closeButtonLabel}
        </span>
        {children}
      </div>
    </div>
  )
}

export { Modal }
