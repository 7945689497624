import { useCallback, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'src/store/hooks'

import { updateAccountPassword, updateUserAccount } from '../state/profile'
import { useNavigate, useParams } from 'react-router-dom'
import { TAccountPassword } from 'src/pages/user/data/account-password.data'
import { ROUTE_PATH, SHARED } from 'src/shared/data'
import { HTTP_STATUS } from 'src/shared/http/data'
import { getUserDetails } from '../state'

import { finishLoading, startLoading } from '@/packages/product/full-page-loader/state'
import { setToast } from '@/packages/product/toast/state'

const buildDataBody = (data: any) => {
  console.log('build data body', data)
  const body = {
    firstName: data.firstName,
    lastName: data.lastName,
    email: data.email,
    gender: Number(data.gender),
    phone: data.phone,
    affiliation: data.affiliation,
    addressBook: [
      {
        label: 'default',
        addressLine1: data.addressLine1,
        addressLine2: data.addressLine2,
        city: data.city,
        postcode: data.postcode,
        country: data.country,
        id: data.addressId,
      },
    ],
  }
  return body
}
export const useHook = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { user, http, activeRole } = useAppSelector((state) => state.user)
  const {
    form: { addressBook },
    http: reviewerHttp,
  } = useAppSelector((state) => state.reviewer)

  const useFetchData = async () => {
    useEffect(() => {
      dispatch(getUserDetails())
    }, [dispatch])
  }

  const useBack = () => {
    navigate(-1)
  }

  const useUpdate = useCallback(async (data: any) => {
    console.log('get user id', user.id)
    try {
      dispatch(startLoading())
      const body = buildDataBody({ ...data, addressId: addressBook[0]?.id })

      const response = await dispatch(updateUserAccount({ ...body, userId: user.id }))
      console.log('update user account response', response)
      if (response.payload.http.status === HTTP_STATUS.SUCCESS) {
        // if ((http.status === HTTP_STATUS.SUCCESS || reviewerHttp.status === HTTP_STATUS.SUCCESS) && activeRole.code != SHARED.ROLES.USER) {
        // Fetch the updated user data
        const updatedUser = await dispatch(getUserDetails())
        dispatch(setToast({ message: 'User account updated successfully', variant: 'success' }))
        // Navigate to the ACCOUNT_MANAGEMENT route with the updated data
        navigate(ROUTE_PATH.ACCOUNT_MANAGEMENT, { state: { user: updatedUser } })
      } else {
        dispatch(setToast({ message: response.payload.http.message, variant: 'error' }))
      }
      dispatch(finishLoading())
    } catch (error) {
      console.log('error callback', error)
    }
  }, [])

  return {
    useUpdate,
    useBack,
    useFetchData,
  }
}

export const useAccountPassword = () => {
  const dispatch = useAppDispatch()
  const { user } = useAppSelector((state) => state.user)
  const { id } = useParams()
  const useSubmit = useCallback(
    async (data: TAccountPassword) => {
      try {
        const body = { password: data.password, passwordConfirm: data.passwordConfirm }
        await dispatch(updateAccountPassword({ ...body, userId: user.id }))
        dispatch(setToast({ message: 'Password Updated', variant: 'success' }))
      } catch (error) {
        console.log('account password error', error)
      }
    },
    [dispatch]
  )

  return { useSubmit }
}

const AccountManagementHook = {
  useHook,
  useAccountPassword,
}
export default AccountManagementHook
