import React, { useEffect, useState } from 'react'
import { Controller, UseFieldArrayProps, UseFieldArrayReturn, UseFormRegister, useFieldArray } from 'react-hook-form'
import {
  ColumnProps,
  DateFieldProps,
  DynamicFieldProps,
  DynamicSelectProps,
  FieldProps,
  RadioInputProps,
  SelectInputProps,
  TextInputProps,
} from './data'
import Icon from '@hubz/core-icon'
import { PlusIcon, TrashIcon } from '@heroicons/react/24/outline'
import Select from 'react-select'
import MyCreatableSelect from './data/creatable-select'

const TextInput = ({ data, register }: TextInputProps) => {
  const { name, type, label, required } = data as FieldProps
  const value = type === 'number' || type === 'boolean' ? { valueAsNumber: true } : {}
  return (
    <>
      <label htmlFor={name} className="block text-md font-semibold leading-6 text-gray-500">
        {label}
        {required === false && <i>(optional)</i>}
      </label>
      <input
        type={type}
        {...register(name, { required, ...value })}
        className="appearance-none text-slate-900 rounded-md block w-full px-3 h-12 sm:text-sm focus:outline-none placeholder:text-slate-400 focus:ring-2 focus:ring-sky-500 ring-1 ring-slate-200 border-neutral"
      />
    </>
  )
}
const RadioInput = ({ data, options, control }: RadioInputProps) => {
  const { name, label, required } = data as FieldProps
  return (
    // TODO: continue to fix radio button not getting switched
    <Controller
      name={name}
      control={control}
      // defaultValue={false}
      render={({ field: { onChange, onBlur, value } }) => (
        <div>
          <label htmlFor={name} className="block text-sm font-semibold leading-6 text-gray-500">
            {label}
            {required === false && <i>(optional)</i>}
          </label>
          {options &&
            options.map((option) => (
              <label key={option.value} className="inline-flex items-center cursor-pointer">
                <input
                  type="radio"
                  onBlur={onBlur}
                  onChange={() => onChange(option.value)}
                  checked={value === option.value}
                  name={name}
                  className="form-radio h-4 w-4 text-primary-600 transition duration-150 ease-in-out"
                />
                <span className="ml-2 text-gray-700 mr-4">{option.label}</span>
              </label>
            ))}
        </div>
      )}
    />
  )
}

const SelectInput = ({ data, options, register }: SelectInputProps) => {
  const { name } = data as FieldProps
  return (
    <select {...register(name)}>
      {options.map(({ label, value }) => (
        <option key={value} value={value}>
          {label}
        </option>
      ))}
    </select>
  )
}

const DynamicSelectInput = ({ data, useFieldArrayObject, control, watch }: DynamicSelectProps) => {
  const { name, options } = data as DynamicFieldProps
  const { fields, prepend, remove } = useFieldArrayObject

  interface SelectedValues {
    [key: string]: any
  }
  const [selectedValues, setSelectedValues] = useState<SelectedValues>({})
  console.log('options', options)
  return (
    <>
      {fields.map((field: any, index: number) => {
        return (
          <div key={field.id} className={`grid grid-cols-4`}>
            {Object.keys(options).map((key) => {
              const array = options[key]
              return (
                <Controller
                  key={`${field.id}-${key}`}
                  name={`${name}[${index}].${key}`}
                  control={control}
                  render={({ field }) => (
                    <Select
                      // key={index}
                      options={array}
                      className={`${index == 1 ? 'mt-8' : ''}`}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      isSearchable={false}
                      // onChange={handleChange}
                      {...field}
                    />
                  )}
                />
              )
            })}

            {index === 0 ? (
              <div
                className={`rounded-md w-fit ml-6 flex border border-primary p-2`}
                onClick={() => {
                  console.log('watch data', watch())
                  const selectedValues = watch()
                  if (
                    !selectedValues.roleManagement[0]?.conference ||
                    !selectedValues.roleManagement[0]?.role ||
                    !selectedValues.roleManagement[0]?.userAccountStatus
                  )
                    return
                  prepend(selectedValues)
                }}
              >
                Add
                <Icon icon={<PlusIcon className={`w-5 h-full ml-2`} />} />
              </div>
            ) : (
              <button className={`rounded-md pt-2 w-fit ml-6 `} onClick={() => remove(index)}>
                <Icon icon={<TrashIcon className={`w-5 h-full `} />} />
              </button>
            )}
          </div>
        )
      })}
    </>
  )
}

export const renderColumn = (column: ColumnProps, register: UseFormRegister<Record<string, any>>, control: any) => {
  switch (column.type) {
    case 'number':
    case 'email':
    case 'password':
    case 'tel':
    case 'text':
      return <TextInput data={column} register={register} />
    case 'select':
      return <SelectInput data={column} options={column.options || []} register={register} />
    case 'radio':
      return <RadioInput data={column} options={column.options || []} control={control} />
    default:
      return null
  }
}

export const renderDatePicker = (
  column: ColumnProps,
  _dateValues: Record<string, any>,
  _handleDateChange: (name: string, value: any) => void
) => {
  if (column.type === 'date') {
    const { minDate, maxDate, name } = column as DateFieldProps
    return (
      <div>
        <label htmlFor={column.name} className="block text-sm font-semibold leading-6 text-gray-500">
          {column.label}
          {column.required === false && <i>(optional)</i>}
        </label>
        {/* <Datepicker
          primaryColor={'rose'}
          useRange={false}
          asSingle={true}
          minDate={minDate}
          maxDate={maxDate}
          value={dateValues[name]}
          startFrom={minDate && maxDate ? maxDate : undefined}
          onChange={(date) => handleDateChange(column.name, date)}
        /> */}
      </div>
    )
  }
}

export const renderDynamicSelectFields = (
  column: ColumnProps,
  useFieldArrayObject: UseFieldArrayReturn<Record<string, any>, string, 'id'>,
  control: any,
  watch: any
) => {
  return <DynamicSelectInput data={column as DynamicFieldProps} useFieldArrayObject={useFieldArrayObject} control={control} watch={watch} />
}

export const renderCreatableSelect = (column: ColumnProps, handleChange: (name: string, value: object[]) => void, values: any) => {
  return <MyCreatableSelect data={column} options={column.options || []} handleChange={handleChange} values={values} />
}
