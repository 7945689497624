import React, { PropsWithChildren } from 'react'
// https://unpkg.com/browse/@heroicons/react@2.0.17/24/outline/
type IconProps = PropsWithChildren<{
  icon: React.ReactNode
  size?: number
  css?: string
}>

const Icon = ({ icon, css = '' }: IconProps) => {
  return <span className={`${css}`}>{icon}</span>
}

export default Icon
