import React, { useEffect, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from 'src/store/hooks'
import { getReviewerAssignmentList } from '../../state'

const AssignmentSection = () => {
  const { reviewingSubmission, reviewerAssignmentList } = useAppSelector((state) => state.submission)
  const dispatch = useAppDispatch()
  useEffect(() => {
    if (reviewingSubmission?.id) {
      dispatch(getReviewerAssignmentList({ paperId: reviewingSubmission.id, type: 'matching' }))
    }
  }, [reviewingSubmission])

  const styles = {
    table: 'w-full mt-6 border border-gray-200 rounded-lg border-separate text-black',
    title: 'font-bold text-2xl pt-8 pb-4',
    tableRow: 'border border-gray-100',
    tableData: 'p-4 border-b border-gray-100 align-top text-left',
  }
  // const matchingReviewerList = useMemo(() => {
  //   return reviewerAssignmentList.filter((item) => {
  //     if (item.review.length > 0) {
  //       return item.review.filter((review) => review.paper.id === reviewingSubmission?.id)
  //     }
  //   })
  // }, [reviewerAssignmentList])
  return (
    <>
      <h2 className="text-black text-2xl font-bold pt-10">
        Assignment for <span className="text-primary">{reviewingSubmission.title}</span>
      </h2>
      <p className="text-black">The following table summarizes the assignment for &quot;{reviewingSubmission.title}&quot;</p>
      <table className={`${styles.table}`}>
        <thead>
          <tr className={styles.tableRow}>
            <th className={`${styles.tableData}`}>Reviewer</th>
            <th className={`${styles.tableData}`}>Paper Type</th>
            <th className={`${styles.tableData}`}>Review Status</th>
            <th className={`${styles.tableData}`}>Total Abstract Assigned</th>
            <th className={`${styles.tableData}`}>Total Full Paper Assigned</th>
            <th className={`${styles.tableData}`}>Total Assigned</th>
          </tr>
        </thead>
        <tbody>
          {reviewerAssignmentList?.map((item, index) => (
            <tr key={index}>
              <td className={`${styles.tableData}`}>
                {item.firstName} {item.lastName}
              </td>
              <td className={`${styles.tableData}`}>
                {item.review.map((review, index) => (
                  <div key={index}>
                    {review.paperType.code}
                    <br />
                  </div>
                ))}
              </td>
              <td className={`${styles.tableData}`}>
                {item.review.map((review, index) => (
                  <div key={index}>
                    {review.reviewStatus.name}
                    <br />
                  </div>
                ))}
              </td>

              <td className={`${styles.tableData}`}>{item.totalAbstractAssigned}</td>
              <td className={`${styles.tableData}`}>{item.totalFullPaperAssigned}</td>
              <td className={`${styles.tableData}`}>{item.totalAbstractAssigned + item.totalFullPaperAssigned}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  )
}

export default AssignmentSection
