import React from 'react'
import Tabs from 'src/packages/product/tabs'
import SubmissionDetailPage from '../../detail'
import { SHARED } from 'src/shared/data'
import AuthorReviewSummaryPage from './review-summary'
import { ChevronLeftIcon } from '@heroicons/react/24/outline'
import Button from 'src/packages/core/button'
import Icon from 'src/packages/core/icon'
import { SubmissionDetailHook } from '../../service'
import { useAppSelector } from 'src/store/hooks'
import AuthorRevisedFullPaperPage from './revised-full-paper'

const SubmissionReviewedPage = () => {
  const { useHook } = SubmissionDetailHook
  const { reviewingSubmission } = useAppSelector((state) => state.submission)
  const { useBack } = useHook()
  const tabs = [
    { name: 'Submission', content: <SubmissionDetailPage /> },
    { name: 'Abstract Review', content: <AuthorReviewSummaryPage paperType={SHARED.PAPER_TYPE.ABS} /> },

    ...(reviewingSubmission?.paperType?.code === SHARED.PAPER_TYPE.FP
      ? [{ name: 'Full Paper Upload / Review', content: <AuthorReviewSummaryPage paperType={SHARED.PAPER_TYPE.FP} /> }]
      : []),
    ...(reviewingSubmission?.paperType?.code === SHARED.PAPER_TYPE.FP &&
    !reviewingSubmission.deletedAt &&
    reviewingSubmission.paperStatus.code === SHARED.PAPER_STATUS.ACCEPTED
      ? [{ name: 'Revised Full Paper Upload', content: <AuthorRevisedFullPaperPage /> }]
      : []),
  ]

  return (
    <div>
      <Button type="button" fill="ghost" expand="default" onClick={useBack} className="mt-0 text-neutral">
        <Icon icon={<ChevronLeftIcon className="w-5 mr-2" />} css="text-neutral" /> Back
      </Button>
      <div className="mt-10">
        <Tabs tabs={tabs} />
      </div>
    </div>
  )
}

export default SubmissionReviewedPage
