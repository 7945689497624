import React, { useEffect } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import NotificationBar from '@hubz/product-notification'
import styles from './login.module.css'
import { ROUTE_PATH } from 'src/shared/data'
import LoginHook from './service/login.hook'
import { useAppSelector } from 'src/store/hooks'
import LoginForm from './views/login-form'

const LoginPage = () => {
  const { useHook } = LoginHook
  const { useClearMessageOnUnmount, useSubmit, useNotification } = useHook()
  const navigate = useNavigate()
  const { access_token } = useAppSelector((state: any) => state.auth)

  useEffect(() => {
    if (access_token) {
      navigate(ROUTE_PATH.MY_ROLES, { replace: true })
    } else {
      navigate(ROUTE_PATH.LOGIN, { replace: true })
    }
  }, [])
  useClearMessageOnUnmount()

  return (
    <>
      <div
        className={`flex flex-col md:grid md:grid-cols-2 min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8 h-screen ${styles.container} bg-blob`}
      >
        <div className="mx-auto order-first md:order-none">
          <img src={require('src/shared/assets/seaair_logo.png')} className="logo w-2/3" />
          <br />
          <h1 className={`md:text-white font-bold ${styles.title}`}>
            SEAAIR <br /> Conference Management System
          </h1>
        </div>

        <div className="w-full max-w-md space-y-8">
          <div>
            <h2 className="mt-6 text-left text-3xl font-bold tracking-tight text-black">Sign in</h2>
            <span className="text-black">
              Not a member?{' '}
              <NavLink to={ROUTE_PATH.REGISTER} className="underline underline-offset-2">
                Sign up now
              </NavLink>
            </span>
          </div>
          <NotificationBar notification={useNotification} includeOrigin="login" />
          <LoginForm onSubmit={useSubmit} />

          <span className="absolute bottom-5 text-gray-500 text-xs">
            © {new Date().getFullYear()} SEAAIR Conference Management System. All rights reserved. v{process.env.REACT_APP_VERSION}
          </span>
        </div>
      </div>
    </>
  )
}

export default LoginPage
