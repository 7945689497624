import React from 'react'
import { useAppDispatch, useAppSelector } from 'src/store/hooks'
import EmailTemplateHook from './service/email.hook'
import { EmailItems, TEmailItems } from './data'
import { Form, FormField, FormItem, FormLabel, FormMessage } from '@/components/shadcn/ui/form'
import Button from '@/packages/core/button'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { Textarea } from '@/components/shadcn/ui/textarea'
import { finishLoading, startLoading } from '@/packages/product/full-page-loader/state'
import { patchEmailTemplate } from './state'
import { setToast } from '@/packages/product/toast/state'

const EmailTemplatePage = () => {
  const dispatch = useAppDispatch()
  const { useEmailTemplateHook } = EmailTemplateHook
  const { useFetchData } = useEmailTemplateHook()
  useFetchData()
  const { emails } = useAppSelector((state) => state.emailTemplate)

  const form = useForm<TEmailItems>({
    resolver: zodResolver(EmailItems),
    defaultValues: {
      emails: [{}],
    },
    mode: 'onBlur',
  })

  // Get properties from react hook form
  const { handleSubmit, getValues, register } = form

  const onSubmit = async () => {
    dispatch(startLoading())
    try {
      const response: any = await dispatch(patchEmailTemplate(getValues()))
      if (response.payload?.status === 200) {
        dispatch(setToast({ message: 'Email templates updated successfully', variant: 'success' }))
      } else {
        dispatch(setToast({ message: `Error ${response.payload}`, variant: 'error' }))
      }
    } catch (error: any) {
      const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
      dispatch(setToast({ message: `Error ${message}`, variant: 'error' }))
    } finally {
      dispatch(finishLoading())
    }
  }

  return (
    <>
      <h1 className="text-2xl font-bold">Email Templates</h1>
      <Form {...form}>
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-8">
          {emails &&
            emails.length > 0 &&
            emails.map((email, index) => (
              <div key={email.id}>
                <input type="hidden" {...register(`emails.${index}.id`)} defaultValue={email.id} />
                <input type="hidden" {...register(`emails.${index}.name`)} defaultValue={email.name} />
                <input type="hidden" {...register(`emails.${index}.description`)} defaultValue={email.description} />
                <input type="hidden" {...register(`emails.${index}.code`)} defaultValue={email.code} />
                <FormField
                  name={`emails.${index}.content`}
                  control={form.control}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>
                        {email.name}
                        <br />
                        <span className="font-light text-gray-400">{email.description}</span>
                      </FormLabel>
                      {/* <FormControl> */}
                      <Textarea placeholder="Email content..." {...field} defaultValue={email.content} />
                      {/* </FormControl> */}
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            ))}
          <div className="flex justify-between items-center">
            <Button type="submit">Save</Button>
          </div>
        </form>
      </Form>
    </>
  )
}

export default EmailTemplatePage
