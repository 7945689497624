import React from 'react'
import ReviewerActivityHook from './service/reviewer-activity.hook'
import { TReviewerActivityItem } from './data'
import { useAppSelector } from '@/store/hooks'

const ReviewerActivityPage = () => {
  const { useReviewerActivityHook } = ReviewerActivityHook
  const { useFetchData } = useReviewerActivityHook()
  const { activityList } = useAppSelector((state) => state.reviewer)

  useFetchData()

  const styles = {
    tableRow: 'border border-gray-100',
    tableData: 'p-2 border-b border-gray-100',
  }
  return (
    <table className="w-full mt-6 border border-gray-200 rounded-lg border-separate table-auto text-black">
      <tbody>
        <tr className={styles.tableRow}>
          <td className={`w-1/6 font-bold ${styles.tableData}`}>Reviewer</td>
          <td className={` font-bold ${styles.tableData}`}>ABS Assigned</td>
          <td className={` font-bold ${styles.tableData}`}>ABS Reviewed</td>
          <td className={` font-bold ${styles.tableData}`}>FP Assigned</td>
          <td className={` font-bold ${styles.tableData}`}>FP Reviewed</td>
          <td className={` font-bold ${styles.tableData}`}>Total Assigned</td>
          <td className={` font-bold ${styles.tableData}`}>Total Reviewed</td>
        </tr>
        {activityList &&
          activityList.length > 0 &&
          activityList.map((item: TReviewerActivityItem) => (
            <tr className={styles.tableRow} key={item.user_id}>
              <td className={`w-1/8 ${styles.tableData}`}>{item.user_name}</td>
              <td className={`w-4/8 ${styles.tableData}`}>{item.totalABSAssigned}</td>
              <td className={`w-1/8 ${styles.tableData}`}>{item.totalABSReviewed}</td>
              <td className={`w-1/8 ${styles.tableData}`}>{item.totalFPAssigned}</td>
              <td className={`w-1/8 ${styles.tableData}`}>{item.totalFPReviewed}</td>
              <td className={`w-1/8 ${styles.tableData}`}>{Number(item.totalFPAssigned) + Number(item.totalABSAssigned)}</td>
              <td className={`w-1/8 ${styles.tableData}`}>{Number(item.totalFPReviewed) + Number(item.totalABSReviewed)}</td>
            </tr>
          ))}
      </tbody>
    </table>
  )
}

export default ReviewerActivityPage
