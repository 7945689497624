import { API_URL, SHARED } from '../data'
import { HttpService } from '../http/service'

const http = new HttpService()

const getCountryList = async () => {
  return await http.get(`${API_URL}/${SHARED.ENDPOINT.COUNTRY_LIST}`)
}

const SharedService = {
  getCountryList,
}
export default SharedService
