import { type } from 'os'
import { KeywordItemSchema } from 'src/pages/keyword/data'
import { SubThemeItemSchema } from 'src/pages/sub-theme/data'
import { UserProfileSchema } from 'src/pages/user/data'
import { TDefaultValues } from 'src/shared/data'
import { HttpResponseSchema } from 'src/shared/http/data'
import { MetaSchema } from 'src/shared/http/data/meta-data'
import { z } from 'zod'

const module = 'submissions'
const PaperTypeItem = z.object({
  id: z.number().nullable().default(null),
  createdAt: z.string().default(''),
  updatedAt: z.string().default(''),
  code: z.string().default(''),
  name: z.string().default(''),
  description: z.string().default(''),
})

const SubmissionAuthor = z.object({
  firstName: z.string().default(''),
  lastName: z.string().default(''),
  email: z.string().default(''),
  affiliation: z.string().default(''),
  country: z.string().default(''),
  isCorresponding: z.boolean().default(false),
  isPresenter: z.boolean().default(false),
  isMainAuthor: z.boolean().default(false),
  // isFirstAuthor: z.boolean().default(false),
})

const ReviewStatusSchema = z.object({
  id: z.number().nullable().default(null),
  createdAt: z.string().default(''),
  updatedAt: z.string().default(''),
  code: z.string().default(''),
  name: z.string().default(''),
  description: z.string().default(''),
  order: z.number().default(0),
})

const PaperStatusItem = z.object({
  id: z.number().nullable().default(null),
  createdAt: z.string().default(''),
  updatedAt: z.string().default(''),
  code: z.string().default(''),
  name: z.string().default(''),
  description: z.string().default(''),
  order: z.number().default(0),
})

const SubmissionItemSchema = z.object({
  id: z.number().nullable().default(null),
  deletedAt: z.string().optional().default(''),
  code: z.string().default(''),
  title: z.string().default(''),
  abstract: z.string().default(''),
  submitted_date: z.string().default(''),
  keyword: z.array(KeywordItemSchema).default([]),
  status: z.string().default(''),
  submissionType: z.string().default(''),
  paperAuthor: z.array(SubmissionAuthor).default([]),
  paperSubTheme: z.array(SubThemeItemSchema).default([]),
  updatedAt: z.string().default(''),
  review: z
    .array(
      z.object({
        id: z.number(),
        createdAt: z.string().default(''),
        updatedAt: z.string().default(''),
        deletedAt: z.string().default(''),
        version: z.number().default(0),
        description: z.string().default(''),
        assignment_date: z.string().default(''),
        completed_date: z.string().default(''),
        due_date: z.string().default(''),
        score: z.number().default(0),
        adminOverride: z.string().default(''),
        notified_date: z.string().default(''),
        level: z.number().default(0),
        paperType: z.object({
          id: z.number(),
          createdAt: z.string().default(''),
          updatedAt: z.string().default(''),
          code: z.string().default(''),
          name: z.string().default(''),
          description: z.string().default(''),
        }),
        user: UserProfileSchema.default({}),
        reviewStatus: ReviewStatusSchema.default({}),
      })
    )
    .optional()
    .default([]),
  paperType: PaperTypeItem.default({}),
  paperStatus: PaperStatusItem.default({}),
  absTotalScore: z.number().default(0).optional(),
  absTotalLevel: z.number().default(0).optional(),
  fpTotalScore: z.number().default(0).optional(),
  fpTotalLevel: z.number().default(0).optional(),
  file: z.array(z.string()).default([]).optional(),
})
const PaperItem = z.object({
  id: z.number().nullable().default(null),
  createdAt: z.string().default(''),
  updatedAt: z.string().default(''),
  code: z.string().default(''),
  title: z.string().default(''),
  abstract: z.string().default(''),
  submitted_date: z.string().default(''),
})

const ReviewerAssignmentItem = z.object({
  id: z.number(),
  createdAt: z.string().default(''),
  updatedAt: z.string().default(''),
  deletedAt: z.string().default(''),
  version: z.number().default(0),
  description: z.string().default(''),
  assignment_date: z.string().default(''),
  completed_date: z.string().default(''),
  due_date: z.string().default(''),
  score: z.number().default(0),
  adminOverride: z.string().default(''),
  notified_date: z.string().default(''),
  paperType: PaperTypeItem.default({}),
  reviewStatus: z.object({
    id: z.number(),
    createdAt: z.string().default(''),
    updatedAt: z.string().default(''),
    code: z.string().default(''),
    name: z.string().default(''),
    description: z.string().default(''),
  }),
  paper: PaperItem.default({}),
})

const ReviewerAssignmentList = z.object({
  id: z.number(),
  firstName: z.string().default(''),
  lastName: z.string().default(''),
  review: z.array(ReviewerAssignmentItem).default([]),
  totalAbstractAssigned: z.number().default(0),
  totalFullPaperAssigned: z.number().default(0),
})

const ReviewerAssignmentCreate = z.object({
  paperId: z.number(),
  assignments: z
    .array(
      z.object({
        assignAbstract: z.boolean().default(false),
        assignFullPaper: z.boolean().default(false),
        adminOverride: z.boolean().default(false),
        userId: z.string().default(''),
      })
    )
    .default([]),
})

export const EvaluationItemSchema = z.object({
  paperId: z.number().default(0),
  score: z.number().min(0).max(100).default(0),
  description: z.string().default(''),
})
const FullPaperFileSchema = z.object({
  id: z.number().nullable().default(null),
  createdAt: z.string().default(''),
  updatedAt: z.string().default(''),
  deletedAt: z.string().default(''),
  version: z.number().default(0),
  filePath: z.string().default(''),
  fileType: z.string().default(''),
  fileSize: z.number().default(0),
  fileName: z.string().default(''),
  fileExtension: z.string().default(''),
})

const SubmissionStatusItemSchema = z.object({
  paper: z.object({
    id: z.number(),
    code: z.string(),
    title: z.string(),
  }),
  scores: z.array(z.number()),
  averageScore: z.number(),
  level: z.number(),
})

const SubmissionSchema = z.object({
  data: z.array(SubmissionItemSchema).default([]),
  meta: MetaSchema.default({}),
  http: HttpResponseSchema.default({}),
  form: z.any(),
  reviewerAssignmentList: z.array(ReviewerAssignmentList).default([]),
  reviewingSubmission: SubmissionItemSchema.default({}),
  // abstractScore: EvaluationItemSchema.default({}),
  abstractScore: z.any(),
  fullPaperScore: z.any(),
  fullPaperFiles: z.array(FullPaperFileSchema).default([]),
  revisedFullPaperFiles: z.array(FullPaperFileSchema).default([]),
  submissionStatusList: z.array(SubmissionStatusItemSchema).default([]),
})

export type TSubmission = z.infer<typeof SubmissionSchema>
export type TSubmissionItem = z.infer<typeof SubmissionItemSchema>
const SubmissionItemCreateRecipe = SubmissionItemSchema.omit({
  id: true,
  code: true,
  submitted_date: true,
  status: true,
  submissionType: true,
  updatedAt: true,
  review: true,
  paperType: true,
  paperStatus: true,
  deletedAt: true,
})
const SubmissionItemUpdateRecipe = SubmissionItemSchema.omit({
  code: true,
  submitted_date: true,
  status: true,
  submissionType: true,
  updatedAt: true,
  review: true,
  paperType: true,
  paperStatus: true,
  deletedAt: true,
})
const FullPaperFileUploadSchema = z.object({
  paperId: z.number(),
  file: z.any(),
})

export type TSubmissionItemCreate = z.infer<typeof SubmissionItemCreateRecipe>
export type TSubmissionItemUpdate = z.infer<typeof SubmissionItemUpdateRecipe>
export type TReviewerAssignmentList = z.infer<typeof ReviewerAssignmentList>
export type TReviewerAssignmentItem = z.infer<typeof ReviewerAssignmentItem>
export type TReviewerAssignmentCreate = z.infer<typeof ReviewerAssignmentCreate>
export type TEvaluationItem = z.infer<typeof EvaluationItemSchema>
export type TFullPaperFileUpload = z.infer<typeof FullPaperFileUploadSchema>
export type TSubmissionStatusItem = z.infer<typeof SubmissionStatusItemSchema>
export type TFullPaperFileSchema = z.infer<typeof FullPaperFileSchema>
export const SubmissionState: TSubmission = SubmissionSchema.parse({})
export const SubmissionItemState: TSubmissionItem = SubmissionItemSchema.parse({})
export const EvaluationItemState: TEvaluationItem = EvaluationItemSchema.parse({})

export const ENDPOINT = {
  SUBMISSION_LIST: `${module}`,
  SUBMISSION_DETAIL: `${module}`,
  REVIEWER_ASSIGNMENT_LIST: `review/assignment/reviewers`,
  SUBMISSION_ABSTRACT_REVIEW_DETAIL: `review/:id/abstract`,
  SUBMISSION_FULL_PAPER_REVIEW_DETAIL: `review/:id/full-paper`,
  SUBMISSION_FULL_PAPER_UPLOAD: `file/upload`,
  SUBMISSION_STATUS_LIST: `paper-decision`,
  SUBMISSION_REVISED_FULL_PAPER_FILE_LIST: `file/upload/revised-full-paper`,
  SUBMISSION_REVISED_FULL_PAPER_UPLOAD: `file/upload/revised-full-paper`,
}

export const STATE = {
  SUBMISSION: 'submissions',
  GET_SUBMISSION_DETAIL: 'submissionDetail',
  SUBMISSION_DETAIL: 'submission/detail',
  SUBMISSION_FORM: 'submission/form', // NOTE: use to store form value to retain updated information
  REVIEWER_ASSIGNMENT_LIST: 'reviewer/assignment/list',
  REVIEWER_ASSIGNMENT_FORM: 'reviewer/assignment/form',
  REVIEWING_ABSTRACT_SUBMISSION: 'reviewing/abstract/submission',
  REVIEWING_FULL_PAPER_SUBMISSION: 'reviewing/full-paper/submission',
  SUBMISSION_ABSTRACT_SCORE: 'submission/abstract/score',
  SUBMISSION_FULL_PAPER_SCORE: 'submission/full-paper/score',
  SUBMISSION_FULL_PAPER_FILE: 'submission/full-paper/file',
  SUBMISSION_FULL_PAPER_FILE_LIST: 'submission/full-paper/file/list',
  SUBMISSION_STATUS_LIST: 'submission/status/list',
  SUBMISSION_REVISED_FULL_PAPER_FILE_LIST: 'submission/revised-full-paper/file/list',
}

// default values for creating submission
export const defaultValues: TDefaultValues = {
  title: '',
  paperSubTheme: {},
  keyword: [],
  abstract: '',
  paperAuthor: [
    {
      firstName: '',
      lastName: '',
      email: '',
      affiliation: '',
      country: '',
      isCorresponding: false,
      isPresenter: false,
      isMainAuthor: false,
    },
  ],
}

// export const defaultValues: TDefaultValues = {
//   title: 'Test Paper Title',
//   paperSubTheme: [{ code: '20245', name: '2024-5' }],
//   keyword: [
//     {
//       label: 'key1',
//       value: 'key1',
//     },
//     {
//       label: 'key2',
//       value: 'key2',
//     },
//   ],
//   abstract: 'this is testing for abstract word count and the defination of abstract',
//   paperAuthor: [
//     {
//       firstName: 'John',
//       lastName: 'Doe',
//       email: 'johndoe@mail.com',
//       affiliation: 'uni of johnses',
//       country: 'Malaysia',
//       isCorresponding: false,
//       isPresenter: false,
//       isMainAuthor: true,
//     },
//     {
//       firstName: 'Jane',
//       lastName: 'Walker',
//       email: 'janewalks@mail.com',
//       affiliation: 'uni of janewalks',
//       country: 'Algeria',
//       isCorresponding: true,
//       isPresenter: true,
//       isMainAuthor: false,
//     },
//   ],
// }
