import React, { useMemo, useState } from 'react'
import SubmissionStatusHook from '../../service/submission-status.hook'
import NotificationBar from 'src/packages/product/notification'
import NotificationHook from 'src/shared/service/notification.hook'
import { TSubmissionStatusItem } from '../../data'
import { levelColor } from 'src/shared/helper/level.helper'
import SubmissionStatusTopBar from './submission-status-top-bar'
import { useAppSelector } from 'src/store/hooks'

const SubmissionStatusPage = () => {
  const { useSubmissionStatusHook } = SubmissionStatusHook
  const { useFetchData, submissionStatusList, searchTerm, setSearchTerm } = useSubmissionStatusHook()
  const { useNotification } = NotificationHook
  const { notification } = useNotification()
  const [selectedColumn, setSelectedColumn] = useState('')
  const { activeRole } = useAppSelector((state) => state.user)

  useFetchData()

  const handleSearchSubmit = (column: string, value: string) => {
    setSelectedColumn(column)
    setSearchTerm(value)
    console.log('hand search submit', column, value)
  }

  const filteredRes = useMemo(() => {
    if (submissionStatusList.length > 0) {
      if (selectedColumn === 'all' || !selectedColumn) {
        console.log('data.data', submissionStatusList)
        return submissionStatusList.map((item: TSubmissionStatusItem) => {
          return item
        })
      } else if (selectedColumn === 'averageScore') {
        return submissionStatusList
          .map((item: TSubmissionStatusItem) => {
            return item
          })
          .sort((a: TSubmissionStatusItem, b: TSubmissionStatusItem) => {
            const aScore = a.scores.reduce((acc, score) => acc + score, 0) / a.scores.length
            const bScore = b.scores.reduce((acc, score) => acc + score, 0) / b.scores.length
            return aScore - bScore
          })
      } else if (selectedColumn === 'level') {
        return submissionStatusList
          .map((item: TSubmissionStatusItem) => {
            return item
          })
          .sort((a: TSubmissionStatusItem, b: TSubmissionStatusItem) => {
            if (a.level < b.level) {
              return -1
            }
            if (a.level > b.level) {
              return 1
            }
            return 0
          })
      } else {
        return submissionStatusList
          .map((item: TSubmissionStatusItem) => {
            return item
          })
          .filter((item: TSubmissionStatusItem) => {
            if (selectedColumn === 'paperId') {
              const found = item.paper.code.includes(searchTerm)
              if (!found) return null
              return item
            } else if (selectedColumn === 'title') {
              const found = item.paper.title.includes(searchTerm)
              if (!found) return null
              return item
            }
          })
      }
    }
    return null
  }, [submissionStatusList, searchTerm, selectedColumn])

  const styles = {
    tableRow: 'border border-gray-100',
    tableData: 'p-2 border-b border-gray-100',
  }
  return (
    <>
      <SubmissionStatusTopBar onSearch={handleSearchSubmit} activeRole={activeRole} />
      <NotificationBar notification={notification} includeOrigin="submission-status" />
      <table className="w-full mt-6 border border-gray-200 rounded-lg border-separate table-auto">
        <tbody>
          <tr className={styles.tableRow}>
            <td className={`w-1/6 font-bold ${styles.tableData}`}>ID</td>
            <td className={` font-bold ${styles.tableData}`}>Title</td>
            <td className={` font-bold ${styles.tableData}`}>Score</td>
            <td className={` font-bold ${styles.tableData}`}>Average</td>
            <td className={` font-bold ${styles.tableData}`}>Level</td>
          </tr>
          {filteredRes &&
            filteredRes.length > 0 &&
            filteredRes.map((item: TSubmissionStatusItem) => (
              <tr className={styles.tableRow} key={item.paper.id}>
                <td className={`w-1/8 ${styles.tableData}`}>{item.paper.code}</td>
                <td className={`w-4/8 ${styles.tableData}`}>{item.paper.title}</td>
                <td className={`w-1/8 ${styles.tableData}`}>{item.scores.map((score) => `${score}%`).join(', ')}</td>
                <td className={`w-1/8 ${styles.tableData}`}>{item.averageScore}%</td>
                <td className={`w-1/8 ${styles.tableData}`}>{levelColor(item.level)}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </>
  )
}

export default SubmissionStatusPage
