import React, { useEffect } from 'react'
import Form from '@hubz/core-form'
import Button from '@hubz/core-button'
import { ACCOUNT_PASSWORD_FORM, AccountPasswordSchema } from 'src/pages/user/data/account-password.data'
import NotificationBar from 'src/packages/product/notification'
import NotificationHook from 'src/shared/service/notification.hook'
import { useAppDispatch } from '@/store/hooks'
import { clearMessage } from '@/packages/product/notification/state'

type TAccountPasswordProps = {
  handlePasswordUpdate: (data: any) => void
}

export const AccountPasswordSection = ({ handlePasswordUpdate }: TAccountPasswordProps) => {
  const { useNotification } = NotificationHook
  const { notification } = useNotification()
  const dispatch = useAppDispatch()
  useEffect(() => {
    return () => {
      dispatch(clearMessage())
    }
  }, [])

  return (
    <>
      <NotificationBar notification={notification} includeOrigin="user-password" />
      <Form
        onSubmit={handlePasswordUpdate}
        sections={ACCOUNT_PASSWORD_FORM}
        button={<Button type="submit">Save</Button>}
        schema={AccountPasswordSchema}
      />
    </>
  )
}

export default AccountPasswordSection
