import { HttpService } from '../../http/service'
import { ENDPOINT } from '../data'
import { API_URL } from '../../data'
import { TSelectRole } from '../data/select-role.data'

const http = new HttpService()

const selectRole = async (data: TSelectRole) => {
  return await http.post(`${API_URL}/${ENDPOINT.LOCAL_SELECT_ROLE}`, data)
}

const refreshToken = async () => {
  return await http.get(`${API_URL}/${ENDPOINT.REFRESH_TOKEN}`)
}

export const AuthService = {
  selectRole,
  refreshToken,
}
