import { API_URL } from '@/shared/data'
import { HttpService } from '@/shared/http/service'

const http = new HttpService()

const getEmailTemplate = async () => {
  return await http.get(`${API_URL}/email-template`)
}

const patchEmailTemplate = async (data: any) => {
  return await http.patch(`${API_URL}/email-template`, data.emails)
}

const EmailService = {
  getEmailTemplate,
  patchEmailTemplate
}

export default EmailService
