import { NavLink } from 'react-router-dom'
import NotificationBar from '@hubz/product-notification'
import { RegisterSchema, TRegister } from 'src/pages/register/data'
import React, { memo } from 'react'
import { ROUTE_PATH } from 'src/shared/data'
import Button from '@/packages/core/button'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/shadcn/ui/form'
import RegisterHook from './service/register.hook'
import { useForm } from 'react-hook-form'
import { Input } from '@/components/shadcn/ui/input'
import { zodResolver } from '@hookform/resolvers/zod'

const RegisterPage = () => {
  const { useHook } = RegisterHook
  const { useClearMessageOnUnmount, useSubmit, useNotification } = useHook()
  useClearMessageOnUnmount()

  const form = useForm<TRegister>({
    resolver: zodResolver(RegisterSchema),
    defaultValues: { firstName: '', lastName: '', email: '', password: '', passwordConfirm: '' },
    mode: 'onChange',
  })

  const handleSubmit = async (data: TRegister) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    await useSubmit(data)
    form.reset({ firstName: '', lastName: '', email: '', password: '', passwordConfirm: '' })
  }

  return (
    <>
      <div className={`grid grid-cols-2 min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8 h-screen  bg-blob`}>
        <div className="mx-auto">
          <img src={require('src/shared/assets/seaair_logo.png')} className="logo w-2/3" />
          <br />
          <h1 className="text-white font-bold">
            SEAAIR <br /> Conference Management System
          </h1>
        </div>
        <div className="w-full max-w-md space-y-8">
          <div>
            <h2 className="mt-6 text-left text-3xl font-bold tracking-tight text-black">Sign Up</h2>
            <span>
              Already a member?{' '}
              <NavLink to={ROUTE_PATH.LOGIN} className="underline underline-offset-2">
                Sign In
              </NavLink>
            </span>
          </div>
          <NotificationBar notification={useNotification} includeOrigin="register" />
          <Form {...form}>
            <form onSubmit={form.handleSubmit((data) => handleSubmit(data))} className="space-y-8">
              <FormField
                control={form.control}
                name="firstName"
                render={({ field }) => (
                  <FormItem className="space-y-0">
                    <FormLabel>First Name</FormLabel>
                    <FormControl>
                      <Input placeholder="First Name" type="text" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="lastName"
                render={({ field }) => (
                  <FormItem className="space-y-0">
                    <FormLabel>Last Name</FormLabel>
                    <FormControl>
                      <Input placeholder="Last Name" type="text" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem className="space-y-0">
                    <FormLabel>Email</FormLabel>
                    <FormControl>
                      <Input placeholder="Email" type="email" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="password"
                render={({ field }) => (
                  <FormItem className="space-y-0">
                    <FormLabel>Password</FormLabel>
                    <FormControl>
                      <Input placeholder="Password" type="password" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="passwordConfirm"
                render={({ field }) => (
                  <FormItem className="space-y-0">
                    <FormLabel>Confirm Password</FormLabel>
                    <FormControl>
                      <Input placeholder="Confirm Password" type="password" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <div className="flex justify-between items-center">
                <Button type="submit">Create Account</Button>
              </div>
            </form>
          </Form>
        </div>
      </div>
    </>
  )
}

export default memo(RegisterPage)
