import { createSlice } from '@reduxjs/toolkit'

const loaderSlice = createSlice({
  name: 'loader',
  initialState: 0, // count of active requests
  reducers: {
    startLoading: (state) => {
      return state + 1
    },
    finishLoading: (state) => {
      return Math.max(0, state - 1)
    },
  },
})

export const { startLoading, finishLoading } = loaderSlice.actions

export default loaderSlice.reducer
