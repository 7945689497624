import { createAsyncThunk } from '@reduxjs/toolkit'
import UserService from '../service'
import { setMessage } from '@hubz/product-notification/state'
import { STATE } from '../data'

export const getUserAccountStatusList = createAsyncThunk(STATE.USER_ACCOUNT_STATUS_LIST, async (_, thunkAPI) => {
  try {
    return (await UserService.getUserAccountStatusList()).data
  } catch (error: any) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    thunkAPI.dispatch(setMessage({ message: message, origin: 'user', status: 'error' }))
    return thunkAPI.rejectWithValue(message)
  }
})
