import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/shadcn/ui/form'
import { Input } from '@/components/shadcn/ui/input'
import Button from '@/packages/core/button'
import React from 'react'
import { useForm } from 'react-hook-form'
import { LoginSchema, TLogin } from '../data'
import { zodResolver } from '@hookform/resolvers/zod'
import { NavLink } from 'react-router-dom'
import { ROUTE_PATH } from '@/shared/data'

const LoginForm = ({ onSubmit }: { onSubmit: (data: TLogin) => Promise<void> }) => {
  const form = useForm<TLogin>({
    resolver: zodResolver(LoginSchema),
    defaultValues: { email: '', password: '' },
    mode: 'onBlur',
  })
  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit((data) => onSubmit(data))} className="space-y-8">
        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Email</FormLabel>
              <FormControl>
                <Input placeholder="Email" type="email" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="password"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Password</FormLabel>
              <FormControl>
                <Input placeholder="Password" type="password" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <div className="flex justify-between items-center">
          <Button type="submit">Log In</Button>
          <div className="text-right">
            <NavLink to={ROUTE_PATH.FORGOT_PASSWORD}> Forgot Password</NavLink>
          </div>
        </div>
      </form>
    </Form>
  )
}

export default LoginForm
