import { setToast } from '@/packages/product/toast/state'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import { PlusIcon } from '@heroicons/react/24/outline'
import { debounce } from 'lodash'
import React, { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Select from 'react-select'
import Button from 'src/packages/core/button'
import Icon from 'src/packages/core/icon'
import { TRole } from 'src/pages/user/data'
import { ROUTE_PATH, SHARED } from 'src/shared/data'

type TSearchFormProps = {
  onSearch: (column: string, value: string) => void
  activeRole: TRole
}

const SubmissionStatusTopBar = ({ onSearch, activeRole }: TSearchFormProps) => {
  const [searchValue, setSearchValue] = useState('')
  const [searchColumn, setSearchColumn] = useState('all')
  const navigate = useNavigate()
  const conference = useAppSelector((state) => state.conference.data)
  const dispatch = useAppDispatch()

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    onSearch('handle submit all', searchValue)
  }

  const columnOptions = [
    { value: 'all', label: 'All' },
    { value: 'paperId', label: 'ID' },
    { value: 'title', label: 'Title' },
    { value: 'averageScore', label: 'Average' },
    { value: 'level', label: 'Level' },
  ]

  const valueOptions: any = {
    all: [],
    paperId: '',
    title: '',
    averageScore: [
      {
        value: 'ASCENDING',
        label: 'Ascending',
      },
      {
        value: 'DESCENDING',
        label: 'Descending',
      },
    ],
    level: [
      {
        value: 'ASCENDING',
        label: 'Ascending',
      },
      {
        value: 'DESCENDING',
        label: 'Descending',
      },
    ],
  }

  const handleColumnChange = (selectedOption: any) => {
    setSearchColumn(selectedOption.value)
    setSearchValue('')
    if (selectedOption.value === 'all') {
      onSearch('all', '')
    }
  }
  const handleValueChange = (selectedOption: any) => {
    setSearchValue(selectedOption.value)
    onSearch(searchColumn, selectedOption.value)
  }

  const handleInputChange = useCallback(
    debounce((event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchValue(event.target.value)
      onSearch(searchColumn, event.target.value)
    }, 500),
    [searchColumn, onSearch]
  )

  const handleNewSubmission = () => {
    const conf = conference[0]
    if (conf?.abstract_open_date && conf?.abstract_close_date) {
      const today = new Date()
      const abstractOpenDate = new Date(conf.abstract_open_date)
      const abstractCloseDate = new Date(conf.abstract_close_date)
      if (today >= abstractOpenDate && today <= abstractCloseDate) {
        navigate(ROUTE_PATH.SUBMISSION_DETAIL)
      } else {
        console.log('No conference submission date are open')
        dispatch(setToast({ message: 'No conference submission date are open', variant: 'error' }))
      }
    }
  }

  return (
    <div className="flex">
      <form onSubmit={handleSubmit} className="w-full flex">
        <div className="relative rounded-md flex w-full">
          <Select
            className="w-1/4 mr-4"
            options={columnOptions}
            placeholder="Search columns"
            isSearchable={false}
            onChange={handleColumnChange}
          />
          {searchColumn === 'paperId' || searchColumn === 'title' ? (
            <input
              type="text"
              className="appearance-none text-slate-900 rounded-md w-1/4 block px-3 sm:text-sm focus:outline-none placeholder:text-slate-400 focus:ring-2 focus:ring-sky-500 ring-1 ring-slate-200 border-neutral"
              placeholder="Search value"
              //   value={searchValue}
              onChange={handleInputChange}
              name="searchText"
            />
          ) : (
            <Select
              className="w-1/4 mr-4"
              options={valueOptions[searchColumn]}
              placeholder="Search value"
              isSearchable={false}
              value={valueOptions[searchColumn]?.find((option: any) => option.value === searchValue)}
              onChange={handleValueChange}
              isDisabled={searchColumn === 'all'}
            />
          )}
        </div>
      </form>
      {activeRole?.code === SHARED.ROLES.USER && (
        <Button type="button" className="ml-6" onClick={() => handleNewSubmission()}>
          <Icon icon={<PlusIcon className="w-5 mt-0.5" />} css={'w-7'} /> New Submission
        </Button>
      )}
    </div>
  )
}

export default SubmissionStatusTopBar
