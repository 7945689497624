import { z } from 'zod'

const module = 'users'
export const LoginSchema = z.object({
  email: z.string().email().min(8, { message: 'Must be 8 or more characters long' }).default(''),
  password: z.string().min(8, { message: 'Must be 8 or more characters long' }).default(''),
})

export type TLogin = z.infer<typeof LoginSchema>

export const STATE = {
  LOCAL_LOGIN: 'local/login',
}

export const ENDPOINT = {
  LOCAL_SIGN_IN: `${module}/local/signin`,
}

export const LOGIN_FORM = [
  {
    columns: [
      {
        name: 'email',
        type: 'email',
        label: 'Email',
      },
      {
        name: 'password',
        type: 'password',
        label: 'Password',
      },
    ],
  },
]
