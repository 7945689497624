
import { HttpResponseSchema } from "@/shared/http/data"
import { MetaSchema } from "@/shared/http/data/meta-data"
import { z } from "zod"

const module = 'email-template'
export const ENDPOINT = {
    EMAILS: `${module}`
}

export const STATE = {
    GET_EMAILS: 'emailTemplate/getEmails',
    PATCH_EMAILS: 'emailTemplate/patchEmails',
}

export const EmailItem = z.object({
    id: z.string().optional().default(''),
    code: z.string().optional().default(''),
    name: z.string().default(''),
    description: z.string().default(''),
    content: z.string().default(''),
})

export const EmailItems = z.object({
    emails: z.array(EmailItem).default([]),
})

export const EmailItemSchema = z.object({
    ABS_ACCEPTED: EmailItem.default({}),
    ABS_REJECTED: EmailItem.default({}),
    FP_ACCEPTED: EmailItem.default({}),
    FP_REJECTED: EmailItem.default({}),
})

export const EmailSchema = z.object({
    // data: EmailItemSchema.default({}),
    data: EmailItems,
    meta: MetaSchema.default({}),
    http: HttpResponseSchema.default({}),
    emails: z.array(EmailItem).default([]),
})

export type TEmails = z.infer<typeof EmailSchema>
export type TEmailItem = z.infer<typeof EmailItem>
export type TEmailItems = z.infer<typeof EmailItems>
export type TEmailItemSchema = z.infer<typeof EmailItemSchema>
export const EmailItemStateArr: TEmailItemSchema = EmailItemSchema.parse({})
export const EmailItemState: TEmailItem[] = EmailItems.parse({}).emails
