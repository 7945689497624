import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { clearMessage, setMessage } from 'src/packages/product/notification/state'
import { useAppDispatch, useAppSelector } from 'src/store/hooks'
import { TReviewer, TReviewerProfile } from '../data'
import Icon from 'src/packages/core/icon'
import { TrashIcon } from '@heroicons/react/24/outline'
import { getReviewerList } from '../state'
import { deleteUserAccount } from 'src/pages/user/state/profile'
import { ROUTE_PATH, SHARED } from 'src/shared/data'
import DOMPurify from 'dompurify'

const useFetchReviewerList = (dispatch: any, setData: any, refreshData: boolean) => {
  useEffect(() => {
    let reviewer: any
    const getReviewerListAsync = async () => {
      reviewer = await dispatch(getReviewerList())
      if (!reviewer.payload) dispatch(setMessage('No records found'))
      setData(reviewer.payload)
      return reviewer.payload
    }
    getReviewerListAsync()
    return () => {
      reviewer
    }
  }, [dispatch, setData, refreshData])
}

const useHook = () => {
  const [searchTerm, setSearchTerm] = useState('')
  const dispatch = useAppDispatch()
  const user = useAppSelector((state) => state.user)
  const navigate = useNavigate()
  const [data, setData] = useState<TReviewer>()
  const [refreshData, setRefreshData] = useState(false)

  const useNotification = useAppSelector((state) => state.message)

  const useClearMessageOnUnmount = () => {
    useEffect(() => {
      return () => {
        dispatch(clearMessage())
      }
    }, [])
  }

  const useFetchData = () => {
    useFetchReviewerList(dispatch, setData, refreshData)
  }

  const useDeleteRecord = useCallback(
    async (id: number) => {
      try {
        await dispatch(deleteUserAccount(id))
      } catch (error) {
        console.log(error)
      }
    },
    [dispatch]
  )

  const handleRowClick = (item: any) => {
    navigate(`${ROUTE_PATH.REVIEWER_MANAGEMENT}/${item.id}`) // Replace `id` with the actual property name of the unique identifier of the item
  }

  const useModalHook = () => {
    // const dispatch = useAppDispatch()
    const [isOpen, setIsOpen] = useState(false)
    const [items, setItems] = useState<TReviewerProfile[]>([])

    const handleButtonClick = useCallback((item: TReviewerProfile) => {
      setIsOpen(true)
      setItems((prevItems) => [...prevItems, item])
      // setItems(item)
    }, [])

    const handleModalClose = useCallback(() => {
      setIsOpen(false)
      setItems([])
    }, [])

    const handleConfirm = async () => {
      // Dispatch action to API here
      console.log('items 2', items)
      setIsOpen(false)
      try {
        await dispatch(deleteUserAccount(items[0].id))
        setItems(() => [])
        setRefreshData((prev) => !prev)
      } catch (error) {
        console.log(error)
      }
    }

    return { isOpen, handleButtonClick, handleModalClose, handleConfirm, items }
  }

  return {
    useClearMessageOnUnmount,
    useFetchData,
    searchTerm,
    setSearchTerm,
    setData,
    data,
    useNotification,
    handleRowClick,
    user,
    useDeleteRecord,
    setRefreshData,
    useModalHook,
  }
}

const table = ({ data, removeRecord }: { data: TReviewer | undefined; removeRecord: (data: TReviewerProfile) => void }) => {
  const records = data

  const columns = [
    {
      key: 'firstName',
      label: 'Reviewer',
      render: (item: any) => item.firstName + ' ' + item.lastName,
    },
    {
      key: 'email',
      label: 'Email',
      render: (item: any) => item.email,
    },
    {
      key: 'affiliation',
      label: 'Affiliation',
      render: (item: any) => item.affiliation,
    },
    {
      key: 'role',
      label: 'Role',
      render: (item: any) => {
        const activeRole = item.userRole.filter((userRole: any) => userRole.userAccountStatus.code === SHARED.USER_ACCOUNT_STATUS.ACTIVE)
        console.log('active role', activeRole)
        // Group by year
        const rolesByYear = activeRole.reduce(
          (acc: any[][], role: { conference: { conference_date: string | number | Date } | null; role: { name: any } }) => {
            if (role.conference === null) {
              return acc
            }

            const year = new Date(role.conference.conference_date).getFullYear()
            if (!acc[year]) {
              acc[year] = []
            }
            acc[year].push(role.role.name)
            return acc
          },
          {}
        )

        // Display the roles grouped by year
        let displayRolesByYear = ''
        Object.entries(rolesByYear).forEach(([year, roles], index, array) => {
          const rolesArray = roles as string[] // Explicitly type 'roles' as an array of strings
          displayRolesByYear += `<b>${year}:</b> ${rolesArray.join(', ')}`
          if (index < array.length - 1) {
            displayRolesByYear += '<br/>' // Add a new line after each year except the last one
          }
        })
        const sanitizedHTML = DOMPurify.sanitize(displayRolesByYear)
        return <div dangerouslySetInnerHTML={{ __html: sanitizedHTML }}></div>
      },
    },
    {
      key: 'action',
      label: '',
      render: (item: any) => {
        return <Icon icon={<TrashIcon className="w-5 mr-2 text-right" onClick={() => removeRecord(item)} />} />
      },
    },
  ]

  return { records, columns }
}

export const ReviewerListHook = {
  useHook,
  table,
}
