import { z } from 'zod'
import { HttpResponseSchema } from '../http/data'
import { MetaSchema } from '../http/data/meta-data'

export const SHARED = {
  ENDPOINT: {
    COUNTRY_LIST: 'country',
  },
  STATE: {
    SHARED: 'shared',
  },
  ROLES: {
    ADMIN: 'ADMIN',
    REVIEWER: 'REVIEWER',
    USER: 'USER',
  },
  USER_ACCOUNT_STATUS: {
    ACTIVE: 'ACTIVE',
    INACTIVE: 'INACTIVE',
  },
  PAPER_TYPE: {
    ABS: 'ABS',
    FP: 'FP',
  },
  PAPER_STATUS: {
    NEW: 'NEW',
    IN_PROGRESS: 'IN_PROGRESS',
    REVIEWED: 'REVIEWED',
    ACCEPTED: 'ACCEPTED',
    REJECTED: 'REJECTED',
  },
  REVIEW_STATUS: {
    NOT_ASSIGNED: 'NOT_ASSIGNED',
    ASSIGNED: 'ASSIGNED',
    REVIEWED: 'REVIEWED',
    ACCEPTED: 'ACCEPTED',
    REJECTED: 'REJECTED',
  },
  COOKIE: {
    ACCESS_TOKEN: process.env.REACT_APP_ACCESS_TOKEN,
    REFRESH_TOKEN: process.env.REACT_APP_REFRESH_TOKEN,
  },
}
const CountryItemSchema = z.object({
  shortName: z.string(),
  name: z.string(),
  native: z.string(),
  phone: z.string(),
  continent: z.string(),
  capital: z.string(),
  currency: z.string(),
  languages: z.array(z.string()),
  emoji: z.string(),
  emojiU: z.string(),
})

export const CountrySchema = z.object({
  data: z.array(CountryItemSchema).default([]),
  meta: MetaSchema.default({}),
  http: HttpResponseSchema.default({}),
})
const SubThemeItemSchema = z.object({
  id: z.number(),
  code: z.string(),
  name: z.string(),
  description: z.string(),
})

export const SubThemeSchema = z.object({
  data: z.array(SubThemeItemSchema).default([]),
  meta: MetaSchema.default({}),
  http: HttpResponseSchema.default({}),
})

export const SharedSchema = z.object({
  countryList: CountrySchema.default({}),
  subThemeList: SubThemeSchema.default({}),
})

export enum ROUTE_PATH {
  REGISTER = '/register',
  LOGIN = '/login',
  FORGOT_PASSWORD = '/forgot-password',
  MY_ROLES = '/my-roles',
  LOGOUT = '/logout',
  SUBMISSION_LIST = '/submission',
  SUBMISSION_ADD = '/submission/add',
  SUBMISSION_DETAIL = '/submission/:id',
  REVIEWER_MANAGEMENT = '/reviewer-management',
  REVIEWER_ADD = '/reviewer-management/add',
  REVIEWER_DETAIL = '/reviewer-management/:id',
  REVIEWER_ACTIVITY = '/reviewer-activity',
  CONFERENCE_LIST = '/conference',
  CONFERENCE_ADD = '/conference/add',
  CONFERENCE_DETAIL = '/conference/:id',
  SUBMISSION_REVIEWER_DETAIL = '/submission/:id/review',
  SUBMISSION_ABSTRACT_REVIEWED = '/submission/:id/abstract-reviewed',
  SUBMISSION_SUMMARY = '/submission/:id/summary',
  ACCOUNT_MANAGEMENT = '/account-management',
  SUBMISSION_STATUS = '/submission-status',
  REPORT_LIST = '/report',
  RESET_PASSWORD = '/reset-password',
  EMAIL_TEMPLATE = '/email-template',
}

export type TDataList = {
  payload: {
    data: any[]
  }
}
export enum FILTER_ORDER {
  ASC = 'ASC',
  DESC = 'DESC',
}
export interface TDefaultValues {
  [key: string]: string | boolean | number | null | Array<string | object> | Date | object
}

export type TCountryItem = z.infer<typeof CountryItemSchema>
export type TCountrySchema = z.infer<typeof CountrySchema>
export type TSharedSchema = z.infer<typeof SharedSchema>
export const API_URL = `${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_URL}`
export const SharedState: TSharedSchema = SharedSchema.parse({})
console.log('api_url', API_URL)
console.log('api_url env', process.env.REACT_APP_API_URL)
