import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { toast as sonner } from 'sonner'

type Variants = 'success' | 'error' | 'default'
const toast = createSlice({
  name: 'toast',
  initialState: { message: '', variant: '' }, // count of active requests
  reducers: {
    setToast: (state, action: PayloadAction<{ message?: string; variant?: Variants }>) => {
      console.log('set toast', action, state)
      const { message, variant } = action.payload
      if (variant === 'error') sonner.error(message)
      if (variant === 'success') sonner.success(message)
      if (variant === 'default' || !variant) sonner(message)
      return state
    },
  },
})

export const { setToast } = toast.actions

export default toast.reducer
