import { useAppDispatch, useAppSelector } from 'src/store/hooks'
import { clearMessage } from '@hubz/product-notification/state'
import { useCallback, useEffect, useState } from 'react'
import { TLogin } from 'src/pages/login/data'
import { doLogin } from 'src/shared/auth/state'
import { ROUTE_PATH } from 'src/shared/data'
import { useNavigate } from 'react-router-dom'
import { HTTP_STATUS } from 'src/shared/http/data'
import { finishLoading, startLoading } from 'src/packages/product/full-page-loader/state'

const useHook = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const useNotification = useAppSelector((state) => state.message)

  const useClearMessageOnUnmount = () => {
    useEffect(() => {
      return () => {
        dispatch(clearMessage())
      }
    }, [])
  }

  const isAuth = useAppSelector((state) => state.auth.status)

  const useSubmit = useCallback(
    async (user: TLogin) => {
      console.log('do login', user)
      dispatch(startLoading())
      try {
        await dispatch(doLogin(user)).unwrap()
        if (isAuth) navigate(ROUTE_PATH.MY_ROLES, { replace: true })
      } catch (error) {
        console.log('login error', error)
        // handle error
      } finally {
        dispatch(finishLoading())
      }
    },
    [dispatch, navigate, isAuth]
  )

  return {
    useClearMessageOnUnmount,
    useSubmit,
    useNotification,
  }
}

const saveAssignment = () => {
  console.log('do dmg')
}

const useModal = () => {
  const [isOpen, setIsOpen] = useState(false)

  const handleButtonClick = useCallback(() => {
    setIsOpen(true)
  }, [])

  const handleCloseModal = useCallback(() => {
    setIsOpen(false)
  }, [])

  return {
    isOpen,
    handleButtonClick,
    handleCloseModal,
  }
}

const LoginHook = {
  useHook,
  useModal,
  saveAssignment,
}

export default LoginHook
