import React, { useEffect, useState } from 'react'
import { API_URL, SHARED } from 'src/shared/data'
import { levelColor } from 'src/shared/helper/level.helper'
import { TFullPaperFileSchema, TSubmissionItem } from '../../data'
import moment from 'moment'
import { useAppSelector } from '@/store/hooks'

type TSubmissionSummarySection = {
  children?: React.ReactNode
  submission: TSubmissionItem
  fullPaperList: TFullPaperFileSchema[]
  role?: string
  revisedFullPaperList?: TFullPaperFileSchema[]
}

const SubmissionSummarySection = ({ children, submission, role }: TSubmissionSummarySection) => {
  const [canViewFullPaper, setCanViewFullPaper] = useState(false)
  const abstractLevel = (type: 'score' | 'level') => {
    if (!submission) return null
    const { review, absTotalScore, absTotalLevel } = submission
    if (review?.length === 0) return null
    console.log('get abstract level', submission, absTotalScore)

    return type === 'level' ? levelColor(absTotalLevel!) : `${absTotalScore}%`
  }
  const { fullPaperFiles: fullPaperList, revisedFullPaperFiles } = useAppSelector((state) => state.submission)
  const fullPaperLevel = () => {
    if (!submission) return null
    const { review, fpTotalLevel } = submission
    if (review?.length === 0) return null

    return fpTotalLevel ? levelColor(fpTotalLevel!) : null
  }

  const handleDownloadPaper = async (item: any) => {
    const filePath = item.filePath
    if (!filePath) return
    console.log('download paper', filePath)
    const downloadUrl = `${API_URL}/${filePath}`
    const response = await fetch(downloadUrl)
    const blob = await response.blob()
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    link.download = filePath.split('/').pop() || '' // Set the filename to the last segment of the URL
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  useEffect(() => {
    console.log('fullPaperList', submission)
    const canViewFP = submission.review.find((item) => {
      return item.paperType.code === SHARED.PAPER_TYPE.FP && !item.deletedAt
    })
    console.log('can view fp', canViewFP)
    if (canViewFP) {
      setCanViewFullPaper(true)
    }
  }, [])

  const styles = {
    title: 'font-bold text-2xl pt-8 pb-4',
    tableRow: 'border border-gray-100',
    tableData: 'p-4 border-b border-gray-100 align-top text-black',
  }
  return (
    <div>
      <table className="w-full mt-6 border border-gray-200 rounded-lg border-separate">
        <tbody>
          <tr className={styles.tableRow}>
            <td className={`w-1/6 font-bold ${styles.tableData}`}>Paper ID</td>
            <td className={`w-5/6 ${styles.tableData}`}>{submission?.code}</td>
          </tr>
          <tr className={styles.tableRow}>
            <td className={`w-1/6 font-bold ${styles.tableData}`}>Title</td>
            <td className={`w-5/6 ${styles.tableData}`}>{submission?.title}</td>
          </tr>
          <tr>
            <td className={`w-1/6 font-bold ${styles.tableData}`}>Submitted</td>
            <td className={`w-5/6 ${styles.tableData}`}>{submission?.submitted_date}</td>
          </tr>
          <tr>
            <td className={`w-1/6 font-bold ${styles.tableData}`}>Last updated</td>
            <td className={`w-5/6 ${styles.tableData}`}>{submission?.updatedAt ?? submission?.submitted_date}</td>
          </tr>

          <tr>
            <td className={`w-1/6 font-bold ${styles.tableData}`}>Sub-theme</td>
            <td className={`w-5/6 ${styles.tableData} break-words whitespace-pre-wrap`}>
              {submission?.paperSubTheme && submission?.paperSubTheme?.length > 0
                ? submission?.paperSubTheme
                    .map((item) => {
                      return item?.name
                    })
                    .join('\n')
                : '-'}
            </td>
          </tr>
          <tr>
            <td className={`w-1/6 font-bold ${styles.tableData}`}>Keywords</td>
            <td className={`w-5/6 ${styles.tableData}`}>
              {submission?.keyword && submission?.keyword?.length > 0
                ? submission.keyword
                    .filter((item) => item && 'label' in item)
                    .map((item) => item.label)
                    .join('\n')
                : '-'}
            </td>
          </tr>
          <tr>
            <td className={`w-1/6 font-bold ${styles.tableData}`}>Abstract</td>
            <td className={`w-5/6 ${styles.tableData}`}>{submission?.abstract}</td>
          </tr>

          {abstractLevel && role === SHARED.ROLES.ADMIN && (
            <tr>
              <td className={`w-1/6 font-bold ${styles.tableData}`}>Abstract Level</td>
              <td className={`w-5/6 ${styles.tableData}`}>{abstractLevel('level')}</td>
            </tr>
          )}
          {canViewFullPaper && (
            <tr>
              <td className={`w-1/6 font-bold ${styles.tableData}`}>Full Paper</td>
              {/* <td className={`w-5/6 ${styles.tableData}`}>{submission?.fullPaper}</td> */}
              <td className={`w-5/6 ${styles.tableData}`}>
                {fullPaperList.length > 0 &&
                  fullPaperList.map((file, index) => {
                    return (
                      <div key={index}>
                        <a className="cursor-pointer underline text-blue-500" onClick={() => handleDownloadPaper(file)}>
                          {file.fileName},
                        </a>
                        <span> {moment(file.createdAt).format('DD MMM YYYY, hh:mm A')}</span>
                      </div>
                    )
                  })}
              </td>
            </tr>
          )}
          {fullPaperLevel && role === SHARED.ROLES.ADMIN && (
            <tr>
              <td className={`w-1/6 font-bold ${styles.tableData}`}>Full Paper Level</td>
              <td className={`w-5/6 ${styles.tableData}`}>{fullPaperLevel() ?? 'N/A'}</td>
            </tr>
          )}
          {revisedFullPaperFiles.length > 0 && (
            <tr>
              <td className={`w-1/6 font-bold ${styles.tableData}`}>Revised Full Paper</td>
              <td className={`w-5/6 ${styles.tableData}`}>
                {revisedFullPaperFiles.map((file, index) => {
                  return (
                    <div key={index}>
                      <a className="cursor-pointer underline text-blue-500" onClick={() => handleDownloadPaper(file)}>
                        {file.fileName},
                      </a>
                      <span> {moment(file.createdAt).format('DD MMM YYYY, hh:mm A')}</span>
                    </div>
                  )
                })}
              </td>
            </tr>
          )}
        </tbody>
      </table>

      <div>{children}</div>
    </div>
  )
}
export default SubmissionSummarySection
