import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { ReportState, STATE, TConferenceSubmissionSummaryItem, TReport } from '../data'
import { ReportService } from '../service'
import { setMessage } from 'src/packages/product/notification/state'
import { HTTP_STATUS } from 'src/shared/http/data'
import { API_URL } from '@/shared/data'
import { setToast } from '@/packages/product/toast/state'

export const getConferenceSubmissionSummary = createAsyncThunk(STATE.CONFERENCE_SUBMISSION_SUMMARY, async (_, thunkAPI) => {
  try {
    return (await ReportService.getConferenceSubmissionSummary()).data
  } catch (error: any) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    thunkAPI.dispatch(setMessage({ message: message, origin: 'report', status: 'error' }))
    return thunkAPI.rejectWithValue(message)
  }
})

export const downloadReportState = createAsyncThunk(STATE.DOWNLOAD_REPORT, async (_, thunkAPI) => {
  try {
    const link = (await ReportService.downloadReport()).data
    return link
  } catch (error: any) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    thunkAPI.dispatch(setMessage({ message: message, origin: 'report', status: 'error' }))
    return thunkAPI.rejectWithValue(message)
  }
})

export const reportSlice = createSlice({
  name: 'report',
  initialState: ReportState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getConferenceSubmissionSummary.fulfilled, (state: TReport, action: PayloadAction<any, any>) => {
        state.http.status = HTTP_STATUS.SUCCESS
        state.conferenceSubmissionSummary = action.payload
      })
      .addCase(getConferenceSubmissionSummary.rejected, (state: TReport) => {
        state.http.status = HTTP_STATUS.FAIL
        state.conferenceSubmissionSummary = []
      })
      .addCase(downloadReportState.fulfilled, (state: TReport, action: PayloadAction<any, any>) => {
        state.http.status = HTTP_STATUS.SUCCESS
        state.downloadReportLink = action.payload
      })
      .addCase(downloadReportState.rejected, (state: TReport) => {
        state.http.status = HTTP_STATUS.FAIL
      })
  },
})

const { reducer } = reportSlice
export default reducer
