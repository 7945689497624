import React, { useCallback, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'src/store/hooks'
import { TConferenceItemUpdate } from '../data'
import { useNavigate, useMatch, useParams } from 'react-router-dom'
import { clearMessage, setMessage } from 'src/packages/product/notification/state'
import { ROUTE_PATH, TDataList } from 'src/shared/data'
import { createConference, getConferenceDetail, updateConference } from '../state'
import { getSubThemeList } from 'src/pages/sub-theme/state'
import { TSubThemeItem } from 'src/pages/sub-theme/data'
import { HTTP_STATUS } from 'src/shared/http/data'
import { finishLoading, startLoading } from '@/packages/product/full-page-loader/state'
import { setToast } from '@/packages/product/toast/state'

const buildDataBody = (data: any) => {
  console.log('build data body', data)
  const subTheme = data.sub_theme.map((item: any) => {
    item.name = item.label
    delete item.value
    delete item.label
    return item
  })
  const body = {
    code: data.code,
    name: data.name,
    description: data.description,
    conference_date: typeof data.conference_date === 'object' ? data.conference_date.startDate : data.conference_date,
    webpage: data.webpage,
    address: data.address,
    city: data.city,
    country: data.country,
    email: data.email,
    phone: data.phone,
    submission_instruction: data.submission_instruction,
    submission_additional_field: data.submission_additional_field,
    submission_template: data.submission_template,
    submission_presenter: data.submission_presenter,
    submission_multiple_author: data.submission_multiple_author,
    submission_abstract_length: data.submission_abstract_length,
    submission_postal_address: data.submission_postal_address,
    submission_file_upload_field: data.submission_file_upload_field,
    sub_theme: subTheme,
    sub_theme_min_topic: data.sub_theme_min_topic,
    sub_theme_max_topic: data.sub_theme_max_topic,
    // submission_allow_new: data.submission_allow_new,
    submission_allow_updates: data.submission_allow_updates,
    abstract_open_date: typeof data.abstract_open_date === 'object' ? data.abstract_open_date.startDate : data.abstract_open_date,
    abstract_close_date: typeof data.abstract_close_date === 'object' ? data.abstract_close_date.startDate : data.abstract_close_date,
    full_paper_open_date: typeof data.full_paper_open_date === 'object' ? data.full_paper_open_date.startDate : data.full_paper_open_date,
    full_paper_close_date:
      typeof data.full_paper_close_date === 'object' ? data.full_paper_close_date.startDate : data.full_paper_close_date,
  }
  return body
}

const useHook = () => {
  const { id } = useParams<{ id: string }>()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [conference, setConference] = useState<TConferenceItemUpdate>()
  const [subTheme, setSubTheme] = useState<{ value: any; label: string }[]>([])
  const params = useParams()
  const matchPath = useMatch(ROUTE_PATH.CONFERENCE_ADD)
  const { http } = useAppSelector((state) => state.conference)
  console.log('matchPath', matchPath)

  const useClearMessageOnUnmount = () => {
    useEffect(() => {
      return () => {
        dispatch(clearMessage())
      }
    }, [])
  }
  const useFetchData = () => {
    useEffect(() => {
      let conference: any
      let subThemeList: any
      const getConferenceDetailAsync = async () => {
        if (!id) return dispatch(setMessage('No id found'))
        conference = await dispatch(getConferenceDetail(+id))
        // if (!conference.payload) dispatch(setMessage('No records found'))
        const response = { ...conference.payload }

        if (response?.conferenceSubTheme?.length > 0) {
          response.sub_theme = response?.conferenceSubTheme?.map((item: any) => {
            return {
              value: item.subTheme.id,
              label: item.subTheme.name,
            }
          })
          delete response.conferenceSubTheme
        }
        console.log('conference payload', response)

        setConference(response)
        return conference.payload
      }

      const getSubThemeListAsync = async () => {
        const subThemeList = (await dispatch(getSubThemeList())) as TDataList
        if (!subThemeList.payload) console.log('no subs theme found')
        if (subThemeList?.payload?.data?.length > 0) {
          // const {sub_theme} =
          const list: TSubThemeItem[] = subThemeList.payload.data as TSubThemeItem[]
          const subThemes = list.map((item: any) => {
            return {
              value: item.id,
              label: item.name,
            }
          })
          setSubTheme(subThemes)
        }
      }

      if (!matchPath) getConferenceDetailAsync()
      getSubThemeListAsync()
      return () => {
        conference
        subThemeList
      }
    }, [dispatch])
  }

  const useBack = () => {
    navigate(-1)
  }

  const useUpdate = useCallback(
    async (data: any) => {
      console.log('use udpate', data)
      try {
        dispatch(startLoading())
        const body = buildDataBody(data)
        await dispatch(updateConference({ ...body, id: Number(id) }))
        if (http.status === HTTP_STATUS.SUCCESS) {
          dispatch(setToast({ message: 'Conference updated successfully', variant: 'success' }))
          navigate(ROUTE_PATH.CONFERENCE_LIST)
        } else {
          console.log('error')
        }
        dispatch(finishLoading())
      } catch (error) {
        console.log('error callback', error)
      }
    },
    [http, id]
  )

  const useCreate = useCallback(async (data: any) => {
    console.log('use create', data)
    try {
      dispatch(startLoading())
      const body = buildDataBody(data)
      await dispatch(createConference(body))
      dispatch(setToast({ message: 'Conference created successfully', variant: 'success' }))
      dispatch(finishLoading())
      navigate(ROUTE_PATH.CONFERENCE_LIST)
    } catch (error) {
      console.log('error callback', error)
    }
  }, [])

  return { useFetchData, useBack, conference, params, matchPath, useUpdate, useCreate, useClearMessageOnUnmount, subTheme }
}
export const ConferenceDetailHook = {
  useHook,
}
