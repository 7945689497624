import { API_URL } from 'src/shared/data'
import { HttpService } from 'src/shared/http/service'
import { ENDPOINT } from '../data'

const http = new HttpService()

const getRoleList = async () => {
  return await http.get(`${API_URL}/${ENDPOINT.ROLE_LIST}`)
}

const RoleService = {
  getRoleList,
}
export default RoleService
