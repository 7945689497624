import React, { useState } from 'react'

// if selectable is defined and true then rowIdKey must exist
type TableProps<T> =
  | {
      data: T[]
      columns: {
        key: string
        label: string
        render: (item: T) => React.ReactNode
        isVisible?: boolean
      }[]
      tableClass?: string
      thClass?: string
      tdClass?: string
      selectable?: true
      rowIdKey: string | ((item: T) => number | string)
      onRowClick?: (item: T) => void
    }
  | {
      data: T[]
      columns: {
        key: string
        label: string
        render: (item: T) => React.ReactNode
        isVisible?: boolean
      }[]
      tableClass?: string
      thClass?: string
      tdClass?: string
      selectable?: false
      rowIdKey?: string | ((item: T) => number | string)
      onRowClick?: (item: T) => void
    }

export const Table = <T extends Record<string, any>>({
  data,
  columns,
  tableClass = 'mt-10',
  thClass = 'text-left',
  tdClass = '',
  selectable = false,
  rowIdKey,
  onRowClick,
}: TableProps<T>) => {
  const [selectedRows, setSelectedRows] = useState<string[]>([])

  const handleSelectAll = () => {
    if (selectedRows.length === data.length) {
      setSelectedRows([])
    } else {
      const allRowIds = data.map((item) => getRowId(item))
      setSelectedRows(allRowIds)
    }
  }

  const handleSelectRow = (itemId: string) => {
    if (selectedRows.includes(itemId)) {
      setSelectedRows(selectedRows.filter((id) => id !== itemId))
    } else {
      setSelectedRows([...selectedRows, itemId])
    }
  }

  const handleRowClick = (item: T) => {
    if (onRowClick) {
      onRowClick(item)
    }
  }

  const getRowId = (item: T) => {
    if (typeof rowIdKey === 'function') {
      return rowIdKey(item)
    } else if (typeof rowIdKey === 'string' && item[rowIdKey]) {
      return item[rowIdKey]
    } else {
      throw new Error(`Could not find row ID key '${rowIdKey}' in item: ${JSON.stringify(item)}`)
    }
  }

  const renderCheckbox = (item: T) => {
    return (
      <input
        type="checkbox"
        checked={selectedRows.includes(getRowId(item))}
        onChange={() => handleSelectRow(getRowId(item))}
        className="w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
      />
    )
  }

  const checkboxColumn = selectable
    ? {
        key: 'select-checkbox',
        label: <input type="checkbox" checked={selectedRows.length === data.length} onChange={handleSelectAll} />,
        render: renderCheckbox,
        isVisible: true,
      }
    : undefined

  const tableColumns = checkboxColumn ? [checkboxColumn, ...columns] : columns

  return (
    <table className={`w-full border border-gray-200 rounded-lg border-separate table-auto ${tableClass}`}>
      <thead>
        <tr>
          {tableColumns.map((column) => (
            <th key={column.key} className={`${thClass} text-black p-4 border-b border-gray-100`}>
              {column.label}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => (
          <tr key={index} className={`text-black border-b border-gray-100 hover:bg-gray-100 ${onRowClick ? 'cursor-pointer' : ''}`}>
            {tableColumns.map((column, colIndex) => (
              <td
                key={column.key}
                className={`p-4 ${tdClass} border-b border-gray-100`}
                onClick={() => {
                  if ((selectable && colIndex === 0) || column.key === 'action') return
                  handleRowClick(item)
                }}
              >
                {column.render(item)}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  )
}
