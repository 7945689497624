import React from 'react'
import { useAppSelector } from 'src/store/hooks'

const ReviewAuthorsSection = () => {
  const { reviewingSubmission } = useAppSelector((state) => state.submission)
  console.log('authors', reviewingSubmission)

  const styles = {
    title: 'font-bold text-2xl pt-8 pb-4',
    tableRow: 'border border-gray-100',
    tableData: 'p-4 border-b border-gray-100 align-top text-black',
  }

  return (
    // <div>
    //     {reviewingSubmission?.review.map((item) => {
    <div className="mb-16">
      <h2 className="text-2xl font-bold pt-10 text-black">Authors</h2>
      <table className="w-full mt-6 border border-gray-200 rounded-lg border-separate">
        <thead>
          <tr className={styles.tableRow}>
            <th className={`${styles.tableData}`}>Name</th>
            <th className={`${styles.tableData}`}>Email</th>
            <th className={`${styles.tableData}`}>Country</th>
            <th className={`${styles.tableData}`}>Affiliation</th>
            <th className={`${styles.tableData}`}>Corresponding</th>
            <th className={`${styles.tableData}`}>Presenter</th>
            <th className={`${styles.tableData}`}>Author</th>
          </tr>
        </thead>
        <tbody>
          {reviewingSubmission?.paperAuthor?.map((item, index) => (
            <tr key={index}>
              <td className={`${styles.tableData}`}>
                {item.firstName} {item.lastName}
              </td>
              <td className={`${styles.tableData}`}>{item.email}</td>
              <td className={`${styles.tableData}`}>{item.country}</td>
              <td className={`${styles.tableData}`}>{item.affiliation}</td>
              <td className={`${styles.tableData}`}>{item.isCorresponding ? 'Yes' : 'No'}</td>
              <td className={`${styles.tableData}`}>{item.isPresenter ? 'Yes' : 'No'}</td>
              <td className={`${styles.tableData}`}>{item.isMainAuthor ? 'Yes' : 'No'}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
export default ReviewAuthorsSection
