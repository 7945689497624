import React, { useEffect, useMemo } from 'react'
import Tabs from 'src/packages/product/tabs'
import { SubmissionDetailHook } from '../../service'
import SubmissionSummarySection from './reviewer-submission'
import Icon from 'src/packages/core/icon'
import Button from 'src/packages/core/button'
import { ChevronLeftIcon } from '@heroicons/react/24/outline'
import SubmissionAbstractSection from './abstract-review'
import { useAppDispatch, useAppSelector } from 'src/store/hooks'
import { SHARED } from 'src/shared/data'
import { getFullPaperFiles, getRevisedFullPaperFiles } from '../../state'
import SubmissionFullPaperSection from './full-paper-review'
import NotificationHook from 'src/shared/service/notification.hook'

const SubmissionReviewerDetail = () => {
  const { useHook } = SubmissionDetailHook
  const { useBack, useFetchData } = useHook()
  const dispatch = useAppDispatch()
  const { useNotification } = NotificationHook
  const { notification } = useNotification()
  const { reviewingSubmission, fullPaperFiles, revisedFullPaperFiles } = useAppSelector((state) => state.submission)
  const loader = useAppSelector((state) => state.loader)
  useFetchData()

  useEffect(() => {
    if (reviewingSubmission && reviewingSubmission.id && !loader) {
      dispatch(getFullPaperFiles(reviewingSubmission.id) as any) // add "as any" to cast the action to AnyAction
      dispatch(getRevisedFullPaperFiles(reviewingSubmission.id) as any) // add "as any" to cast the action to AnyAction
    }
  }, [reviewingSubmission?.id, loader])

  const isDoneAbsReview = reviewingSubmission?.review?.some(
    (item) =>
      // item.paperType.code === SHARED.PAPER_TYPE.ABS &&
      item.reviewStatus?.code !== SHARED.REVIEW_STATUS.NOT_ASSIGNED || item.reviewStatus?.code !== SHARED.REVIEW_STATUS.ASSIGNED
  )

  const reviewIsDeletedAndNoScore = reviewingSubmission?.review?.some(
    (item) => item.deletedAt && !item.score && item.paperType.code === SHARED.PAPER_TYPE.ABS
  )
  const canReviewAbstract =
    reviewingSubmission?.review?.some((item) => item.paperType.code === SHARED.PAPER_TYPE.ABS) && !reviewIsDeletedAndNoScore

  const tabs = useMemo(
    () => [
      {
        name: 'Submission',
        content: (
          <SubmissionSummarySection
            submission={reviewingSubmission}
            fullPaperList={fullPaperFiles}
            revisedFullPaperList={revisedFullPaperFiles}
          />
        ),
      },
      ...(canReviewAbstract ? [{ name: 'Abstract Review', content: <SubmissionAbstractSection submission={reviewingSubmission} /> }] : []),
      ...(reviewingSubmission?.review?.some((item) => item.paperType.code === SHARED.PAPER_TYPE.FP && !item.deletedAt) && isDoneAbsReview
        ? [
            {
              name: 'Full Paper Review',
              content: <SubmissionFullPaperSection submission={reviewingSubmission} notification={notification} />,
            },
          ]
        : []),
    ],
    [reviewingSubmission, canReviewAbstract, isDoneAbsReview]
  )

  return (
    <div>
      <Button type="button" fill="ghost" expand="default" onClick={useBack} className="mt-0 text-neutral">
        <Icon icon={<ChevronLeftIcon className="w-5 mr-2" />} css="text-neutral" /> Back
      </Button>
      <div className="mt-10">
        <Tabs tabs={tabs} />
      </div>
    </div>
  )
}

export default SubmissionReviewerDetail
