import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import EmailService from '../service/email.service'
import { EmailItemState, STATE, TEmailItems } from '../data'
import { HTTP_STATUS } from '@/shared/http/data'

export const getEmailTemplate = createAsyncThunk(STATE.GET_EMAILS, async (_, thunkAPI) => {
  try {
    return await EmailService.getEmailTemplate()
  } catch (error: any) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

export const patchEmailTemplate = createAsyncThunk(STATE.PATCH_EMAILS, async (data: TEmailItems, thunkAPI) => {
  try {
    console.log('patch email data', data)
    return await EmailService.patchEmailTemplate(data)
  } catch (error: any) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})
export const emailSlice = createSlice({
  name: 'emailTemplate',
  initialState: {
    emails: EmailItemState,
    http: {
      status: '',
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getEmailTemplate.fulfilled, (state, action: PayloadAction<any, any>) => {
        console.log('email template', action.payload.data)
        state.emails = action.payload.data
        state.http.status = HTTP_STATUS.SUCCESS
      })
      .addCase(getEmailTemplate.rejected, (state) => {
        state.http.status = HTTP_STATUS.FAIL
      })
      .addCase(patchEmailTemplate.fulfilled, (state, action: PayloadAction<any, any>) => {
        state.http.status = HTTP_STATUS.SUCCESS
        state.emails = action.payload.data
      })
      .addCase(patchEmailTemplate.rejected, (state, action: PayloadAction<any, any>) => {
        console.log('set patch fail', state, action)
        state.http.status = HTTP_STATUS.FAIL
      })
  },
})

const { reducer } = emailSlice
export default reducer
