import { z } from 'zod'

export const UserAccountStatusSchema = z.object({
  id: z.number().nullable().default(null),
  createdAt: z.string().default(''),
  updatedAt: z.string().optional().default(''),
  code: z.string().default(''),
  name: z.string().default(''),
  description: z.string().optional().default(''),
  order: z.number().optional().nullable().default(null),
})

export type TUserAccountStatus = z.infer<typeof UserAccountStatusSchema>
