import React from 'react'
import ReviewerTopBar from './top-bar'
import { Table } from '@hubz/core-table'
import { ReviewerListHook } from './service'
import { TReviewerProfile } from './data'
import NotificationBar from 'src/packages/product/notification'

import Button from 'src/packages/core/button'
import { Modal } from '@hubz/product-modal'
const ReviewerPage = () => {
  const { useHook, table } = ReviewerListHook
  const { searchTerm, setSearchTerm, useFetchData, data, useNotification, handleRowClick, useClearMessageOnUnmount, useModalHook } =
    useHook()
  const { isOpen, handleButtonClick, handleModalClose, handleConfirm, items } = useModalHook()
  const { columns } = table({ data: data, removeRecord: handleButtonClick })

  useFetchData()
  useClearMessageOnUnmount()

  const handleSearchSubmit = (searchTerm: string) => {
    setSearchTerm(searchTerm)
  }

  const filteredRes = data?.data.filter((item: TReviewerProfile) => {
    return (
      item.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.lastName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.email.toLowerCase().includes(searchTerm.toLowerCase())
    )
  })

  // TODO: if use is admin or reviewer then show then selectable
  // const activeRole = RoleHelper.useCurrentRole()
  // activeRole.code

  return (
    <div id="reviewer-page">
      <ReviewerTopBar onSearch={handleSearchSubmit} />
      <NotificationBar notification={useNotification} includeOrigin="user-account,user,reviewer" />
      {filteredRes && (
        <Table
          data={filteredRes}
          columns={columns}
          onRowClick={(item: any) => handleRowClick(item)}
          selectable={true}
          rowIdKey={(item: any) => item.id}
        />
      )}

      <Modal isOpen={isOpen} onClose={handleModalClose} hideOnClickOutside={false}>
        <h1 className="font-bold">Confirm Delete?</h1>
        <p>Are you sure you want to delete the following item(s)?</p>
        <ul className="list-disc ml-4">
          {items?.map((item: any) => (
            <li key={item.id}>
              {item.firstName} {item.lastName}
            </li>
          ))}
        </ul>

        <div className="flex">
          <Button onClick={handleModalClose} type="button" fill="outline" className="mt-10 mr-2">
            Cancel
          </Button>
          <Button onClick={handleConfirm} type="button" className="mt-10">
            Submit
          </Button>
        </div>
      </Modal>
    </div>
  )
}

export default ReviewerPage
