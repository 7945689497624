import React, { useEffect, useState } from 'react'
import styles from '../login/login.module.css'
import Form from 'src/packages/core/form'
import { ForgotPasswordHook } from './service/forgot-password.hook'
import { FORGOT_PASSWORD_FORM, ForgotPasswordForm } from './data'
import Button from 'src/packages/core/button'
import { useAppSelector } from 'src/store/hooks'
import { HTTP_STATUS } from 'src/shared/http/data'
import { ROUTE_PATH } from 'src/shared/data'
import { NavLink } from 'react-router-dom'

const ForgotPasswordPage = () => {
  const { useForgotPasswordHook } = ForgotPasswordHook
  const { useSubmit, useUnmount } = useForgotPasswordHook()
  const { http } = useAppSelector((state) => state.forgotPassword)

  useUnmount()
  return (
    <>
      <div
        className={`grid grid-cols-2 min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8 h-screen ${styles.container} bg-blob`}
      >
        <div className="mx-auto">
          <img src={require('src/shared/assets/seaair_logo.png')} className="logo w-2/3" />
          <br />
          <h1 className="text-white font-bold">
            SEAAIR <br /> Conference Management System
          </h1>
        </div>

        <div className="w-full max-w-md space-y-8">
          <div>
            {(!http || http.status === HTTP_STATUS.IDLE) && (
              <>
                <h2 className="mt-6 text-left text-3xl font-bold tracking-tight text-black">
                  Enter your <br />
                  email address
                </h2>
                <span>Enter the email address you used when you joined and we will send you instructions to reset your password.</span>
              </>
            )}
            {http?.status === HTTP_STATUS.SUCCESS && (
              <>
                <h2 className="mt-6 text-left text-3xl font-bold tracking-tight text-black">
                  Request <br />
                  Successful
                </h2>
                <span>We&apos;ve sent the password reset link to your email. Please follow the instructions listed there.</span>
              </>
            )}
            {http?.status === HTTP_STATUS.FAIL && (
              <>
                <h2 className="mt-6 text-left text-3xl font-bold tracking-tight text-black">
                  Request <br />
                  Unsuccessful
                </h2>
                <span className="text-primary-500">{http.message}</span>
              </>
            )}
          </div>
          <Form
            onSubmit={useSubmit}
            sections={FORGOT_PASSWORD_FORM}
            button={<Button type="submit">Request</Button>}
            schema={ForgotPasswordForm}
            cols=""
          >
            <div className="pt-4 text-right pr-4 mt-2">
              <NavLink to={ROUTE_PATH.LOGIN}> Back to Sign In</NavLink>
            </div>
            <br />
          </Form>

          <span className="absolute bottom-5 text-gray-500 text-xs">
            © {new Date().getFullYear()} SEAAIR Conference Management System. All rights reserved. v{process.env.REACT_APP_VERSION}
          </span>
        </div>
      </div>
    </>
  )
}

export default ForgotPasswordPage
