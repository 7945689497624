import { createAsyncThunk } from '@reduxjs/toolkit'
import { setMessage } from 'src/packages/product/notification/state'

import RoleService from '../service'
import { STATE } from '../data'

export const getRoles = createAsyncThunk(STATE.ROLE_LIST, async (_, thunkAPI) => {
  try {
    return (await RoleService.getRoleList()).data
  } catch (error: any) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    thunkAPI.dispatch(setMessage({ message: message, origin: 'role', status: 'error' }))
    return thunkAPI.rejectWithValue(message)
  }
})
