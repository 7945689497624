import React from 'react'
import { Toaster } from '@/components/shadcn/ui/sonner'

type Position = 'top-left' | 'top-center' | 'top-right' | 'bottom-left' | 'bottom-center' | 'bottom-right'

const Toast = ({ position = 'bottom-right', expand = true }: { position?: Position; expand?: boolean }) => {
  return <Toaster position={position} expand={expand} closeButton={true} richColors />
}

export default Toast
