import React from 'react'
import Tabs from '@hubz/product-tabs'
import { AccountInfoFormSection } from '../profile'
import AccountPasswordSection from 'src/pages/user/views/profile/account-password'
import { ReviewerDetailHook } from './service'
import ProfileHook from '../../service/profile.hook'
import MangeReviewerAccount from './view/manage-reviewer-account'

const ReviewerDetailPage = () => {
  const { useHook } = ReviewerDetailHook
  const { useFetchData, reviewer, matchPath } = useHook()
  const { useAccountPassword } = ProfileHook
  const { useSubmit } = useAccountPassword()
  const displayTab = () => {
    if (reviewer && !matchPath) return true
    if (!reviewer && matchPath) return true
    return false
  }
  useFetchData()
  const tabs = [
    { name: 'Account Info', content: <AccountInfoFormSection data={matchPath ? null : reviewer} /> },
    // { name: 'Account Info', content: <MangeReviewerAccount data={reviewer} /> },
    { name: 'Password Info', content: <AccountPasswordSection handlePasswordUpdate={useSubmit} /> },
  ]

  return <>{displayTab() && <Tabs tabs={tabs} />}</>
}

export default React.memo(ReviewerDetailPage)
