import React from 'react'
import { ReportHook } from './service'
import { TConferenceSubmissionSummaryItem } from './data'
import Button from 'src/packages/core/button'
import Icon from 'src/packages/core/icon'
import { DocumentArrowDownIcon } from '@heroicons/react/24/outline'
import { API_URL } from '@/shared/data'
import { setToast } from '@/packages/product/toast/state'
const ReportListPage = () => {
  const { useReportHook } = ReportHook
  const { conferenceSubmissionSummary, useFetchData, downloadReport } = useReportHook()

  useFetchData()

  const doDownload = async () => {
    const downloadUrl = await downloadReport()
    if (!downloadUrl) return
    const response = await fetch(`${API_URL}/${downloadUrl.filePath}`)
    if (!response) setToast({ message: 'Download failed', variant: 'error' })
    const blob = await response.blob()
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    link.download = downloadUrl.filePath?.split('/').pop() || '' // Set the filename to the last segment of the URL
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const styles = {
    tableRow: 'border border-gray-100',
    tableData: 'p-2 border-b border-gray-100 text-black',
  }
  return (
    <>
      <Button onClick={doDownload}>
        <Icon icon={<DocumentArrowDownIcon className={`w-5 h-full mr-4 `} />} />
        Download
      </Button>
      <table className="w-full mt-6 border border-gray-200 rounded-lg border-separate table-auto">
        <tbody>
          <tr className={styles.tableRow}>
            <td className={`w-1/6 font-bold ${styles.tableData}`}>Conference</td>
            <td className={` font-bold ${styles.tableData}`}>Country</td>
            <td className={` font-bold ${styles.tableData}`}>Authors</td>
            <td className={` font-bold ${styles.tableData}`}>Paper Submitted</td>
            <td className={` font-bold ${styles.tableData}`}>Papers Rejected</td>
            <td className={` font-bold ${styles.tableData}`}>ABS Accepted</td>
            <td className={` font-bold ${styles.tableData}`}>FP Accepted</td>
          </tr>
          {conferenceSubmissionSummary &&
            conferenceSubmissionSummary.length > 0 &&
            conferenceSubmissionSummary.map((item: TConferenceSubmissionSummaryItem) => (
              <tr className={styles.tableRow} key={item.conference.code}>
                <td className={`w-2/8${styles.tableData}`}>{item.conference.title}</td>
                <td className={`w-1/8${styles.tableData}`}>{item.conference.country}</td>
                <td className={`w-1/8${styles.tableData}`}>{item.authors}</td>
                <td className={`w-1/8${styles.tableData}`}>{item.totalPaperSubmitted}</td>
                <td className={`w-1/8${styles.tableData}`}>{item.totalPaperRejected}</td>
                <td className={`w-1/8${styles.tableData}`}>{item.totalAbstractAccepted}</td>
                <td className={`w-1/8${styles.tableData}`}>{item.totalFullPaperAccepted}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </>
  )
}

export default ReportListPage
