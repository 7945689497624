import { useAppDispatch } from 'src/store/hooks'
import { forgotPassword, forgotPasswordSlice } from '../state'
import { useEffect, useCallback } from 'react'
import { finishLoading, startLoading } from '@/packages/product/full-page-loader/state'

const useForgotPasswordHook = () => {
  const dispatch = useAppDispatch()

  const useSubmit = useCallback(
    async (data: any) => {
      console.log('use submit', data.email)
      try {
        dispatch(startLoading())
        await dispatch(forgotPassword(data.email))
        dispatch(finishLoading())
      } catch (error) {
        console.log('account password error', error)
      }
    },
    [dispatch]
  )

  const useUnmount = () => {
    useEffect(() => {
      return () => {
        dispatch(forgotPasswordSlice.actions.resetForgotPassword())
      }
    }, [dispatch])
  }

  return {
    useSubmit,
    useUnmount,
  }
}

export const ForgotPasswordHook = {
  useForgotPasswordHook,
}
