import { z } from 'zod'

const now = new Date()

const UserInfoSchema = z.object({
  name: z.string().default(''),
  gender: z.string().optional().nullable().default(null),
  email: z.string().email().default(''),
  phone: z.string().nullable().default(''),
  affiliation: z.string().default(''),
})

const UserAddressSchema = z.object({
  addressLine1: z.string().optional().default(''),
  addressLine2: z.string().optional().default(''),
  city: z.string().default(''),
  postcode: z.string().default(''),
  country: z.string().default(''),
})

export const ProfileAccountInfoSchema = z.object({
  ...UserInfoSchema.shape,
  ...UserAddressSchema.shape,
})

export type TUserInfo = z.infer<typeof UserInfoSchema>
export type TUserAddress = z.infer<typeof UserAddressSchema>
export type TProfileAccountInfo = z.infer<typeof ProfileAccountInfoSchema>

export const PROFILE_ACCOUNT_INFO_FORM = [
  {
    heading: 'Profile Info',
    columns: [
      {
        name: 'firstName',
        type: 'text',
        label: 'First Name',
        required: true,
      },
      {
        name: 'lastName',
        type: 'text',
        label: 'Last Name',
        required: true,
      },
      // {
      //   name: 'gender',
      //   type: 'radio',
      //   label: 'Gender',
      //   required: false,
      //   options: [
      //     {
      //       label: 'Male',
      //       value: '1',
      //     },
      //     {
      //       label: 'Female',
      //       value: '2',
      //     },
      //   ],
      // },
      {
        name: 'email',
        type: 'email',
        label: 'Email',
        required: true,
      },
      {
        name: 'phone',
        type: 'tel',
        label: 'Contact Number',
        required: true,
      },
      {
        name: 'affiliation',
        type: 'text',
        label: 'Affiliation',
        required: true,
      },
    ],
  },
  {
    heading: 'Address',
    columns: [
      {
        name: 'addressLine1',
        type: 'text',
        label: 'Address line 1',
        required: false,
      },
      {
        name: 'addressLine2',
        type: 'text',
        label: 'Address Line 2',
        required: false,
      },
      {
        name: 'city',
        type: 'text',
        label: 'City',
        required: true,
      },
      {
        name: 'postcode',
        type: 'text',
        label: 'Postcode',
        required: true,
      },
      {
        name: 'country',
        type: 'text',
        label: 'Country',
        required: true,
      },
    ],
  },
]

export const defaultValues = {
  name: '',
  gender: null,
  email: '',
  phone: '',
  affiliation: '',
  addressLine1: '',
  addressLine2: '',
  city: '',
  postcode: '',
  country: '',
}
