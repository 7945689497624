import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { setMessage } from '@hubz/product-notification/state'
import RegisterService from '../service/register.service'
import { RegisterState, STATE, TRegister } from '../data'
import { HTTP_STATUS } from 'src/shared/http/data'

export const doRegister = createAsyncThunk(STATE.LOCAL_REGISTER, async (data: any, thunkAPI) => {
  try {
    const res = (await RegisterService.register(data)).data
    console.log('done register', res)
    thunkAPI.dispatch(
      setMessage({ message: 'Account registered successfully, you may proceed to sign in', origin: 'register', status: 'success' })
    )
    return res
  } catch (error: any) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    thunkAPI.dispatch(setMessage({ message: message, origin: 'register', status: 'error' }))
    return thunkAPI.rejectWithValue(message)
  }
})

export const registerSlice = createSlice({
  name: 'register',
  initialState: RegisterState,
  reducers: {},
  extraReducers: () => {
    // builder
    //   .addCase(doRegister.pending, (state: TRegister) => {
    //     state.status = HTTP_STATUS.LOADING
    //   })
    //   .addCase(doRegister.fulfilled, (state: TRegister, action: any) => {
    //     state.status = HTTP_STATUS.SUCCESS
    //     state.message = action.payload.message
    //   })
  },
})

const { reducer } = registerSlice
export default reducer
