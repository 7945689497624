import React from 'react'
import AbstractScoreGuidelineSection from './abstract-score-guideline'
import EvaluationSection from './evaluation'
import { TSubmissionItem } from '../../data'
import { SHARED } from 'src/shared/data'

type TSubmissionAbstractSectionProps = {
  submission: TSubmissionItem
}
const SubmissionAbstractSection = ({ submission }: TSubmissionAbstractSectionProps) => {
  const styles = {
    title: 'font-bold text-2xl pt-8 pb-4',
    tableRow: 'border border-gray-100',
    tableData: 'p-4 border-b border-gray-100',
  }

  return (
    <div>
      <table className="w-full mt-6 border border-gray-200 rounded-lg border-separate">
        <tbody>
          <tr>
            <td className={`w-1/6 font-bold ${styles.tableData}`}>Title</td>
            <td className={` ${styles.tableData} text-black`}>{submission.title}</td>
          </tr>
          <tr>
            <td className={`w-1/6 font-bold ${styles.tableData}`}>Abstract</td>
            <td className={` ${styles.tableData} text-black`}>{submission.abstract}</td>
          </tr>
        </tbody>
      </table>
      <h2 className={styles.title}>SEAAIR Guidelines for evaluation of Quality of Abstract</h2>
      <AbstractScoreGuidelineSection />
      <EvaluationSection submission={submission} paperType={SHARED.PAPER_TYPE.ABS} />
    </div>
  )
}

export default SubmissionAbstractSection
