import React from 'react'
import CreatableSelect from 'react-select/creatable'
import { CreatableSelectInputProps, FieldProps } from '.'

const MyCreatableSelect = ({ data, options: selectOptions, handleChange, values }: CreatableSelectInputProps) => {
  const { name } = data as FieldProps
  console.log('select options', values, data)
  const defaultValues = data.options?.length > 0 ? data.options : values.sub_theme
  console.log('defaultValues 11', defaultValues)

  return (
    <>
      <label htmlFor={name} className="block text-sm font-semibold leading-6 text-gray-500">
        {data.label}
        {data.required === false && <i>(optional)</i>}
      </label>
      <CreatableSelect
        defaultValue={defaultValues}
        getOptionLabel={(option: any) => option.name || option.label}
        getOptionValue={(option: any) => option.id || option.value}
        onChange={(value) => handleChange(name, value)}
        options={selectOptions}
        isClearable={true}
        isMulti
        placeholder="Type something and press enter..."
      />
    </>
  )
}

export default MyCreatableSelect
