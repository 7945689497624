import React from 'react'

const FullPaperScoreGuidelineSection = () => {
  const styles = {
    ul: 'list-disc list-outside',
    tableRow: 'border border-gray-100',
    tableData: 'p-4 border-b border-gray-100 text-black',
  }
  return (
    <table className="w-full mt-6 border border-gray-200 rounded-lg border-separate">
      <tbody>
        <tr className={styles.tableRow}>
          <td className={`w-1/8 font-bold ${styles.tableData}`}>Level</td>
          <td className={`w-1/8 font-bold ${styles.tableData}`}>Percentage</td>
          <td className={`w-6/8 font-bold ${styles.tableData}`}>Guidelines for evaluation of Quality of Abstract</td>
        </tr>
        <tr>
          <td className={`w-1/8 font-bold ${styles.tableData}`}>Reject</td>
          <td className={`w-1/8 ${styles.tableData}`}>Below 50%</td>
          <td className={`w-6/8 ${styles.tableData}`}>
            <ul className={`${styles.ul}`}>
              <li>Not within Education Agenda</li>
              <li>Completely outside scope of Conference Theme</li>
            </ul>
          </td>
        </tr>
        <tr>
          <td className={`w-1/8 font-bold ${styles.tableData}`}>1</td>
          <td className={`w-1/8 ${styles.tableData}`}>50-59%</td>
          <td className={`w-6/8 ${styles.tableData}`}>
            <ul className={`${styles.ul}`}>
              <li>
                <b>Below Average</b> Research Logic & Justification
              </li>
              <li>
                <b>Below Average</b> (with issues) Literature Reviews & Synthesis
              </li>
              <li>
                <b>Needs MAJOR reviews</b> of Research Methodology requirements
              </li>
              <li>
                <b>Needs MAJOR reviews</b> of Analysis and Discussion of Findings, with <b>MAJOR reviews</b> of recommendations and
                conclusions
              </li>
            </ul>
          </td>
        </tr>
        <tr>
          <td className={`w-1/8 font-bold ${styles.tableData}`}>2</td>
          <td className={`w-1/8 ${styles.tableData}`}>60-69%</td>
          <td className={`w-6/8 ${styles.tableData}`}>
            <ul className={`${styles.ul}`}>
              <li>
                <b>Average</b> Research Logic & Justification
              </li>
              <li>
                <b>Average</b> (with issues) Literature Reviews & Synthesis
              </li>
              <li>
                <b>Needs reviews</b> of Research Methodology requirements
              </li>
              <li>
                <b>Average</b> (with issues) Analysis and Discussion of Findings, with <b>Acceptable</b> (with issues) recommendations and
                conclusions
              </li>
            </ul>
          </td>
        </tr>
        <tr>
          <td className={`w-1/8 font-bold ${styles.tableData}`}>3</td>
          <td className={`w-1/8 ${styles.tableData}`}>70-79%</td>
          <td className={`w-6/8 ${styles.tableData}`}>
            <ul className={`${styles.ul}`}>
              <li>
                <b>Acceptable</b> Research Logic & Justification
              </li>
              <li>
                <b>Acceptable</b> Literature Reviews & Synthesis
              </li>
              <li>
                <b>Follow Basic</b> of Research Methodology requirements
              </li>
              <li>
                <b>Acceptable</b> Analysis and Discussion of Findings, with <b>Acceptable</b> recommendations and conclusions
              </li>
            </ul>
          </td>
        </tr>
        <tr>
          <td className={`w-1/8 font-bold ${styles.tableData}`}>4</td>
          <td className={`w-1/8 ${styles.tableData}`}>80-89%</td>
          <td className={`w-6/8 ${styles.tableData}`}>
            <ul className={`${styles.ul}`}>
              <li>
                <b>Good</b> Research Logic & Justification
              </li>
              <li>
                <b>Well</b> Literature Reviews & Synthesis
              </li>
              <li>
                <b>Stringent</b> of Research Methodology requirements
              </li>
              <li>
                <b>Good</b> Analysis and Discussion of Findings, with <b>sound</b> recommendations and conclusions
              </li>
            </ul>
          </td>
        </tr>
        <tr>
          <td className={`w-1/8 font-bold ${styles.tableData}`}>5</td>
          <td className={`w-1/8 ${styles.tableData}`}>90-100%</td>
          <td className={`w-6/8 ${styles.tableData}`}>
            <ul className={`${styles.ul}`}>
              <li>
                <b>Excellent</b> Research Logic & Justification
              </li>
              <li>
                <b>Very Well</b> Literature Reviews & Synthesis
              </li>
              <li>
                <b>Stringent</b> of Research Methodology requirements
              </li>
              <li>
                <b>Excellent</b> Analysis and Discussion of Findings, with <b>very sound</b> recommendations and conclusions
              </li>
            </ul>
          </td>
        </tr>
      </tbody>
    </table>
  )
}
export default FullPaperScoreGuidelineSection
