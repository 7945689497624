import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import ReviewerService from '../service/reviewer-list.service'
import { ReviewerState, STATE, TReviewer } from '../data'
import { setMessage } from 'src/packages/product/notification/state'
import { HTTP_STATUS } from 'src/shared/http/data'

export const getReviewerList = createAsyncThunk(STATE.REVIEWER_LIST, async (_, thunkAPI) => {
  try {
    return (await ReviewerService.getReviewerList()).data
  } catch (error: any) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    thunkAPI.dispatch(setMessage({ message: message, origin: 'reviewer', status: 'error' }))
    return thunkAPI.rejectWithValue(message)
  }
})

export const getReviewerDetail = createAsyncThunk(STATE.REVIEWER_DETAIL, async (data: number, thunkAPI) => {
  try {
    const details = (await ReviewerService.getReviewerDetail(data)).data
    return details
  } catch (error: any) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    thunkAPI.dispatch(setMessage({ message: message, origin: 'reviewer', status: 'error' }))
    return thunkAPI.rejectWithValue(message)
  }
})

export const getReviewerActivity = createAsyncThunk(STATE.REVIEWER_ACTIVITY_LIST, async (_, thunkAPI) => {
  try {
    const activity = await ReviewerService.getReviewerActivityList()
    return activity
  } catch (error: any) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    thunkAPI.dispatch(setMessage({ message: message, origin: 'reviewer', status: 'error' }))
    return thunkAPI.rejectWithValue(message)
  }
})

export const reviewerSlice = createSlice({
  name: 'reviewer',
  initialState: ReviewerState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getReviewerList.fulfilled, (state: TReviewer, action: PayloadAction<any, any>) => {
        state.data = action.payload.data
        state.meta = action.payload.meta
        state.http.status = HTTP_STATUS.SUCCESS
      })
      .addCase(getReviewerList.rejected, (state) => {
        state.http.status = HTTP_STATUS.FAIL
      })
      .addCase(getReviewerDetail.fulfilled, (state: TReviewer, action: PayloadAction<any, any>) => {
        state.form = action.payload
        state.http.status = HTTP_STATUS.SUCCESS
      })
      .addCase(getReviewerDetail.rejected, (state: TReviewer) => {
        state.http.status = HTTP_STATUS.FAIL
      })
      .addCase(getReviewerActivity.fulfilled, (state: TReviewer, action: PayloadAction<any, any>) => {
        console.log('reviewer list', action.payload.data)
        state.activityList = action.payload.data
        state.http.status = HTTP_STATUS.SUCCESS
      })
      .addCase(getReviewerActivity.pending, (state: TReviewer) => {
        state.activityList = []
        state.http.status = HTTP_STATUS.LOADING
      })
  },
})

const { reducer } = reviewerSlice
export default reducer
