import { PayloadAction, createSlice } from '@reduxjs/toolkit'

const alertDialogSlice = createSlice({
  name: 'alertDialog',
  initialState: { message: '', open: false, errorCode: '', error: '' }, // count of active requests
  reducers: {
    openAlertModal: (state, action: PayloadAction<{ message?: string; open: boolean; errorCode?: string; error?: string }>) => {
      console.log('open alert', action.payload, state)
      state.error = action.payload.error ?? ''
      state.message = action.payload.message ?? ''
      state.open = action.payload.open
      state.errorCode = action.payload.errorCode ?? ''
      return state
    },
  },
})

export const { openAlertModal } = alertDialogSlice.actions

export default alertDialogSlice.reducer
