import { AddressSchema, RoleSchema } from 'src/pages/user/data/user.data'
import { HttpResponseSchema } from 'src/shared/http/data'
import { MetaSchema } from 'src/shared/http/data/meta-data'
import { z } from 'zod'

const module = 'client-users'

const ReviewerProfileSchema = z.object({
  id: z.number().nullable().default(null),
  createdAt: z.string().optional().default(''),
  updatedAt: z.string().optional().default(''),
  type: z.string().default(''),
  code: z.string().default(''),
  firstName: z.string().default(''),
  lastName: z.string().default(''),
  phone: z.string().optional().default(''),
  email: z.string().default(''),
  affiliation: z.string().optional().default(''),
  userRole: z.array(RoleSchema).default([]),
  addressBook: z.array(AddressSchema).optional().default([]),
})

const ReviewerActivityItem = z.object({
  user_id: z.number().nullable().default(null),
  user_name: z.string().default(''),
  totalABSAssigned: z.string().default(''),
  totalABSReviewed: z.string().default(''),
  totalFPAssigned: z.string().default(''),
  totalFPReviewed: z.string().default(''),
})

export const ReviewerSchema = z.object({
  data: z.array(ReviewerProfileSchema).default([]),
  meta: MetaSchema.default({}),
  http: HttpResponseSchema.default({}),
  form: ReviewerProfileSchema.default({}),
  activityList: z.array(ReviewerActivityItem).default([]),
})

export const ReviewerAssignmentReqData = z.object({
  paperId: z.number().nullable().default(null),
  type: z.string().default('all'), // matching | all. matching will only give reviews that match paperId
})

export type TReviewerActivityItem = z.infer<typeof ReviewerActivityItem>
export type TReviewer = z.infer<typeof ReviewerSchema>
export type TReviewerProfile = z.infer<typeof ReviewerProfileSchema>
export type TReviewerAssignmentReqData = z.infer<typeof ReviewerAssignmentReqData>
export const ReviewerState: TReviewer = ReviewerSchema.parse({})

export const ENDPOINT = {
  REVIEWER_LIST: `${module}`,
  REVIEWER_DETAIL: `${module}`,
  REVIEWER_ACTIVITY_LIST: `${module}/reviewer/activity`,
}

export const STATE = {
  REVIEWER_LIST: 'reviewer/management/list',
  REVIEWER_DETAIL: 'reviewer/management/detail',
  REVIEWER_ACTIVITY_LIST: 'reviewer/management/activity/list',
}
