import { useAppDispatch, useAppSelector } from 'src/store/hooks'
import { clearMessage } from '@hubz/product-notification/state'
import { useEffect } from 'react'
import { TRegister } from 'src/pages/register/data'
import { doRegister } from 'src/pages/register/state'
import { finishLoading, startLoading } from '@/packages/product/full-page-loader/state'

const useHook = () => {
  const dispatch = useAppDispatch()
  const useNotification = useAppSelector((state) => state.message)
  const { status } = useAppSelector((state) => state.auth)

  const useClearMessageOnUnmount = () => {
    useEffect(() => {
      return () => {
        dispatch(clearMessage())
      }
    }, [])
  }

  const useSubmit = async (user: TRegister) => {
    dispatch(startLoading())
    await dispatch(doRegister(user))
    dispatch(finishLoading())
  }

  return { useClearMessageOnUnmount, useSubmit, useNotification, status }
}

const RegisterHook = {
  useHook,
}

export default RegisterHook
