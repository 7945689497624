import { API_URL, FILTER_ORDER } from 'src/shared/data'
import { HttpService } from 'src/shared/http/service'
import { ENDPOINT, TEvaluationItem, TFullPaperFileUpload, TSubmissionItemUpdate } from '../data'

const http = new HttpService()

const getSubmissionList = async () => {
  return await http.get(`${API_URL}/${ENDPOINT.SUBMISSION_LIST}?order=${FILTER_ORDER.DESC}`)
}

const getSubmissionDetail = async (id: number) => {
  return await http.get(`${API_URL}/${ENDPOINT.SUBMISSION_DETAIL}/${id}`)
}

const createSubmission = async (data: any) => {
  return await http.post(`${API_URL}/${ENDPOINT.SUBMISSION_DETAIL}`, data)
}

const updateSubmission = async (data: TSubmissionItemUpdate) => {
  const { id, ...rest } = data
  return await http.patch(`${API_URL}/${ENDPOINT.SUBMISSION_DETAIL}/${id}`, rest)
}

const updateSubmissionScore = async (data: TEvaluationItem) => {
  const { paperId, ...rest } = data
  const endpoint = ENDPOINT.SUBMISSION_ABSTRACT_REVIEW_DETAIL.replace(':id', paperId.toString())
  return await http.patch(`${API_URL}/${endpoint}`, rest)
}

const updateFullPaperSubmissionScore = async (data: TEvaluationItem) => {
  const { paperId, ...rest } = data
  const endpoint = ENDPOINT.SUBMISSION_FULL_PAPER_REVIEW_DETAIL.replace(':id', paperId.toString())
  return await http.patch(`${API_URL}/${endpoint}`, rest)
}

const getAbstractSubmissionScore = async (id: number) => {
  return await http.get(`${API_URL}/${ENDPOINT.SUBMISSION_ABSTRACT_REVIEW_DETAIL.replace(':id', id.toString())}`)
}

const getFullPaperSubmissionScore = async (id: number) => {
  return await http.get(`${API_URL}/${ENDPOINT.SUBMISSION_FULL_PAPER_REVIEW_DETAIL.replace(':id', id.toString())}`)
}

const setFullPaperFile = async (data: TFullPaperFileUpload) => {
  const formData = new FormData()
  formData.append('file', data.file)
  formData.append('paperId', data.paperId.toString())
  return await http.post(`${API_URL}/${ENDPOINT.SUBMISSION_FULL_PAPER_UPLOAD}`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  })
}

const getFullPaperFiles = async (paperId: number) => {
  return await http.get(`${API_URL}/${ENDPOINT.SUBMISSION_FULL_PAPER_UPLOAD}/?paperId=${paperId}&order=DESC`)
}

const getSubmissionStatusList = async () => {
  return await http.get(`${API_URL}/${ENDPOINT.SUBMISSION_STATUS_LIST}`)
}

const getRevisedFullPaperFiles = async (paperId: number) => {
  return await http.get(`${API_URL}/${ENDPOINT.SUBMISSION_REVISED_FULL_PAPER_FILE_LIST}/?paperId=${paperId}&order=DESC`)
}

const setRevisedFullPaperFile = async (data: TFullPaperFileUpload) => {
  const formData = new FormData()
  formData.append('file', data.file)
  formData.append('paperId', data.paperId.toString())
  return await http.post(`${API_URL}/${ENDPOINT.SUBMISSION_REVISED_FULL_PAPER_UPLOAD}`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  })
}

export const SubmissionService = {
  getSubmissionList,
  getSubmissionDetail,
  createSubmission,
  updateSubmission,
  updateSubmissionScore,
  getAbstractSubmissionScore,
  setFullPaperFile,
  getFullPaperFiles,
  getFullPaperSubmissionScore,
  updateFullPaperSubmissionScore,
  getSubmissionStatusList,
  getRevisedFullPaperFiles,
  setRevisedFullPaperFile,
}
