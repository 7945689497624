import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { setMessage } from 'src/packages/product/notification/state'
import ConferenceService from '../service/conference.service'
import { ConferenceState, STATE, TConference, TConferenceItemCreate, TConferenceItemUpdate } from '../data'
import { HTTP_STATUS } from 'src/shared/http/data'
import { setToast } from '@/packages/product/toast/state'

export const getConferences = createAsyncThunk(STATE.CONFERENCE_LIST, async (data: any, thunkAPI) => {
  try {
    return (await ConferenceService.getConferences(data)).data
  } catch (error: any) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    thunkAPI.dispatch(setMessage({ message: message, origin: 'conference', status: 'error' }))
    return thunkAPI.rejectWithValue(message)
  }
})

export const getConferenceDetail = createAsyncThunk(STATE.CONFERENCE_DETAIL, async (data: number, thunkAPI) => {
  try {
    return (await ConferenceService.getConferenceDetail(data)).data
  } catch (error: any) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    console.log('get conference detail error', message)
    thunkAPI.dispatch(setMessage({ message: message, origin: 'conference', status: 'error' }))
    return thunkAPI.rejectWithValue(message)
  }
})

export const createConference = createAsyncThunk(STATE.CONFERENCE_CREATE_DETAIL, async (data: TConferenceItemCreate, thunkAPI) => {
  try {
    const res = (await ConferenceService.createConference(data)).data
    return res
  } catch (error: any) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    console.log('error message', message, error)
    thunkAPI.dispatch(setMessage({ message: message, origin: 'conference', status: 'error' }))
    return thunkAPI.rejectWithValue(message)
  }
})

export const updateConference = createAsyncThunk(STATE.CONFERENCE_UPDATE_DETAIL, async (data: TConferenceItemUpdate, thunkAPI) => {
  try {
    const res = (await ConferenceService.updateConference(data)).data
    return res
  } catch (error: any) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    thunkAPI.dispatch(setMessage({ message: message, origin: 'conference', status: 'error' }))
    return thunkAPI.rejectWithValue(message)
  }
})

export const deleteConference = createAsyncThunk(STATE.CONFERENCE_DELETE, async (data: number | null, thunkAPI) => {
  try {
    if (data === null) return thunkAPI.rejectWithValue('No user id provided')
    const res = (await ConferenceService.deleteConference(data)).data
    thunkAPI.dispatch(setMessage({ message: 'Conference deleted successfully', origin: 'conference', status: 'success' }))
    return res
  } catch (error: any) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    thunkAPI.dispatch(setMessage({ message: message, origin: 'conference', status: 'error' }))
    return thunkAPI.rejectWithValue(message)
  }
})

export const conferenceSlice = createSlice({
  name: 'conference',
  initialState: ConferenceState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getConferences.fulfilled, (state: TConference, action: PayloadAction<any, any>) => {
        state.data = action.payload.data
        state.meta = action.payload.meta
        state.http.status = HTTP_STATUS.SUCCESS
      })
      .addCase(updateConference.fulfilled, (state: TConference, action: PayloadAction<any, any>) => {
        console.log('update conference state', action)
        state.http.status = HTTP_STATUS.SUCCESS
        state.form = action.payload.data
      })
      .addCase(updateConference.rejected, (state: TConference) => {
        state.http.status = HTTP_STATUS.FAIL
      })
      .addCase(getConferenceDetail.fulfilled, (state: TConference, action: PayloadAction<any, any>) => {
        state.http.status = HTTP_STATUS.SUCCESS
        state.form = action.payload
      })
      .addCase(getConferenceDetail.rejected, (state: TConference) => {
        state.http.status = HTTP_STATUS.FAIL
      })
      .addCase(createConference.fulfilled, (state: TConference) => {
        state.http.status = HTTP_STATUS.SUCCESS
      })
      .addCase(createConference.pending, (state: TConference, action: PayloadAction<any, any>) => {
        console.log('conference pending', action)
        state.http.status = HTTP_STATUS.LOADING
        state.form = action.payload
      })
      .addCase(createConference.rejected, (state: TConference, action: PayloadAction<any, any>) => {
        console.log('conference rejected', action)
        state.http.status = HTTP_STATUS.FAIL
      })
  },
})

const { reducer } = conferenceSlice
export default reducer
