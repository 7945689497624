import { setMessage } from 'src/packages/product/notification/state'
import SharedService from '../service/shared.service'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { SharedState } from '../data'
import { TSharedSchema } from '../data'
import { getSubThemeList, getSubThemeListReducer } from 'src/pages/sub-theme/state'

export const getCountryList = createAsyncThunk('country', async (_, thunkAPI) => {
  try {
    return (await SharedService.getCountryList()).data
  } catch (error: any) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    thunkAPI.dispatch(setMessage({ message: message, origin: 'shared', status: 'error' }))
    return thunkAPI.rejectWithValue(message)
  }
})

export const sharedSlice = createSlice({
  name: 'shared',
  initialState: SharedState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCountryList.fulfilled, (state: TSharedSchema, action: any) => {
        state.countryList = action.payload
      })
      .addMatcher(
        (action) => action.type.endsWith('/fulfilled') || action.type.endsWith('/rejected'),
        (state: any, action: any) => {
          getSubThemeListReducer()[action.type]?.(state, action?.payload)
        }
      )
  },
})

const { reducer } = sharedSlice
export default reducer
