import { Layout } from '@hubz/core-layout'
import React, { useEffect } from 'react'
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom'
import Sidebar from '@hubz/product-sidebar'
import LoginPage from './pages/login'
import RegisterPage from './pages/register'
import MyRolesPage from './pages/user/views/my-roles'
import { useAppDispatch, useAppSelector } from './store/hooks'
import { ROUTE_PATH, SHARED } from './shared/data'
import ErrorPage from './pages/error'
import LogoutPage from './pages/logout'
import { TRole } from './pages/user/data/user.data'
import {
  LockClosedIcon,
  FolderOpenIcon,
  ArrowLeftCircleIcon,
  UserGroupIcon,
  VideoCameraIcon,
  UserIcon,
  ExclamationCircleIcon,
  ClipboardDocumentListIcon,
  AtSymbolIcon,
} from '@heroicons/react/24/outline'
import Icon from 'src/packages/core/icon'
import SubmissionPage from './pages/submission/list'
import ReviewerPage from './pages/user/views/reviewer/list'
import ReviewerDetailPage from './pages/user/views/reviewer/detail'
import ConferenceListPage from './pages/conference/list'
import ConferenceDetailPage from './pages/conference/detail'
import RedirectPage from './pages/redirect'
import SubmissionDetailPage from './pages/submission/detail'
import { getCountryList } from './shared/state'
import { getSubThemeList } from './pages/sub-theme/state'
import SubmissionReviewerDetail from './pages/submission/views/reviewer/reviewer-detail'
import SubmissionReviewedPage from './pages/submission/views/user/submission-reviewed'
import SubmissionSummaryPage from './pages/submission/views/admin/admin-detail'
import AccountManagementPage from './pages/user/views/profile/account-management'
import { getConferences } from './pages/conference/state'
import SubmissionStatusPage from './pages/submission/views/admin/submission-status'
import ReportListPage from './pages/report/list'
import { TMenuList } from '@hubz/product-sidebar/data'
import ReviewerActivityPage from './pages/user/views/reviewer/activity'
import ForgotPasswordPage from './pages/forgot-password'
import ResetPasswordPage from './pages/reset-password'
import FullPageLoader from '@hubz/product-full-page-loader'
import AlertModal from '@hubz/product-alert-dialog'
import { openAlertModal } from '@hubz/product-alert-dialog/state'
import Toast from '@hubz/product-toast'
import EmailTemplatePage from './pages/user/views/email-template'

const menuList = (role: TRole): TMenuList[] => {
  const list: TMenuList[] = [{ name: 'My Roles', link: ROUTE_PATH.MY_ROLES, icon: <Icon icon={<LockClosedIcon className="w-5 mr-2" />} /> }]

  switch (role?.code) {
    case SHARED.ROLES.USER:
      list.push({ name: 'Submission', link: ROUTE_PATH.SUBMISSION_LIST, icon: <Icon icon={<FolderOpenIcon className="w-5 mr-2" />} /> })
      break
    case SHARED.ROLES.ADMIN:
      list.push(
        { name: 'Submission', link: ROUTE_PATH.SUBMISSION_LIST, icon: <Icon icon={<FolderOpenIcon className="w-5 mr-2" />} /> },
        {
          name: 'Reviewer Management',
          icon: <Icon icon={<UserGroupIcon className="w-5 mr-2" />} css={'w-7'} />,
          children: [
            {
              name: 'Manage Reviewer',
              link: ROUTE_PATH.REVIEWER_MANAGEMENT,
            },
            {
              name: 'Reviewer Activity',
              link: ROUTE_PATH.REVIEWER_ACTIVITY,
            },
          ],
        },
        { name: 'Report Module', link: ROUTE_PATH.REPORT_LIST, icon: <Icon icon={<ClipboardDocumentListIcon className="w-5 mr-2" />} /> },
        {
          name: 'Submission Status',
          link: ROUTE_PATH.SUBMISSION_STATUS,
          icon: <Icon icon={<ExclamationCircleIcon className="w-5 mr-2" />} />,
        },
        { name: 'Conference Management', link: ROUTE_PATH.CONFERENCE_LIST, icon: <Icon icon={<VideoCameraIcon className="w-5 mr-2" />} /> },
        { name: 'Email Templates', link: ROUTE_PATH.EMAIL_TEMPLATE, icon: <Icon icon={<AtSymbolIcon className="w-5 mr-2" />} /> }
      )
      break
    case SHARED.ROLES.REVIEWER:
      list.push({ name: 'Submission', link: ROUTE_PATH.SUBMISSION_LIST, icon: <Icon icon={<FolderOpenIcon className="w-5 mr-2" />} /> })
      break
    default:
      break
  }

  list.push(
    { name: 'Account Management', link: ROUTE_PATH.ACCOUNT_MANAGEMENT, icon: <Icon icon={<UserIcon className="w-5 mr-2" />} /> },
    { name: 'Logout', link: ROUTE_PATH.LOGOUT, icon: <Icon icon={<ArrowLeftCircleIcon className="w-5 mr-2" />} /> }
  )

  return list
}

const PrivateRoutes = () => {
  const { isAuth } = useAppSelector((state: any) => state.auth)
  const { activeRole, user } = useAppSelector((state: any) => state.user)
  const { countryList, subThemeList } = useAppSelector((state: any) => state.shared)
  const { data } = useAppSelector((state: any) => state.conference)
  const dispatch = useAppDispatch()
  useEffect(() => {
    menuList(activeRole)

    if (countryList.data.length === 0) {
      dispatch(getCountryList())
    }
    if (subThemeList?.data?.length === 0) {
      dispatch(getSubThemeList())
    }
    if (data.length === 0) {
      dispatch(getConferences({ year: new Date().getFullYear() }))
    }
  }, [activeRole, countryList, data.length, subThemeList?.data?.length, countryList.data.length, dispatch])
  return isAuth ? (
    <Layout sideBar={<Sidebar menus={menuList(activeRole)} user={{ user: user, role: activeRole }} />} />
  ) : (
    <Navigate to="login" replace />
  )
}

function App() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const invalidTokenAction = () => {
    navigate('/logout')
    dispatch(openAlertModal({ open: false }))
  }
  const { open, message, error, errorCode } = useAppSelector((state: any) => state.alertDialog)
  const alertModalButton = {
    title: `${error} - ${errorCode}`,
    message: message,
    actionButton: {
      text: 'OK',
      onClick: invalidTokenAction,
    },
  }
  // TODO: validation of permission and conference year
  return (
    <div>
      <FullPageLoader />
      <AlertModal open={open} data={alertModalButton} />
      <Toast />
      <Routes>
        <Route path="/" element={<RedirectPage />} />
        <Route path={ROUTE_PATH.LOGIN} element={<LoginPage />} />
        <Route path={ROUTE_PATH.REGISTER} element={<RegisterPage />} />
        <Route path={ROUTE_PATH.FORGOT_PASSWORD} element={<ForgotPasswordPage />} />
        <Route path={ROUTE_PATH.RESET_PASSWORD} element={<ResetPasswordPage />} />
        <Route path={ROUTE_PATH.LOGOUT} element={<LogoutPage />} />
        <Route element={<PrivateRoutes />}>
          <Route path={ROUTE_PATH.MY_ROLES} element={<MyRolesPage />} />
          <Route path={ROUTE_PATH.SUBMISSION_LIST} element={<SubmissionPage />} />
          <Route path={ROUTE_PATH.SUBMISSION_DETAIL} element={<SubmissionDetailPage />} />
          <Route path={ROUTE_PATH.SUBMISSION_REVIEWER_DETAIL} element={<SubmissionReviewerDetail />} />
          <Route path={ROUTE_PATH.SUBMISSION_ABSTRACT_REVIEWED} element={<SubmissionReviewedPage />} />
          <Route path={ROUTE_PATH.SUBMISSION_SUMMARY} element={<SubmissionSummaryPage />} />
          <Route path={ROUTE_PATH.SUBMISSION_STATUS} element={<SubmissionStatusPage />} />
          <Route path={ROUTE_PATH.REVIEWER_MANAGEMENT} element={<ReviewerPage />} />
          <Route path={ROUTE_PATH.REVIEWER_ACTIVITY} element={<ReviewerActivityPage />} />
          <Route path={ROUTE_PATH.REVIEWER_ADD} element={<ReviewerDetailPage />} />
          <Route path={ROUTE_PATH.REVIEWER_DETAIL} element={<ReviewerDetailPage />} />
          <Route path={ROUTE_PATH.CONFERENCE_LIST} element={<ConferenceListPage />} />
          {/* <Route path={ROUTE_PATH.REVIEWER_ADD} element={<ConferenceDetailPage />} /> */}
          <Route path={ROUTE_PATH.CONFERENCE_DETAIL} element={<ConferenceDetailPage />} />
          <Route path={ROUTE_PATH.EMAIL_TEMPLATE} element={<EmailTemplatePage />} />
          <Route path={ROUTE_PATH.ACCOUNT_MANAGEMENT} element={<AccountManagementPage />} />
          <Route path={ROUTE_PATH.REPORT_LIST} element={<ReportListPage />} />
        </Route>
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </div>
  )
}

export default App
