import { TDefaultValues } from 'src/shared/data'
import { HttpResponseSchema } from 'src/shared/http/data'
import { MetaSchema } from 'src/shared/http/data/meta-data'
import { z } from 'zod'

const module = 'conferences'
const today = new Date()
const yesterday = new Date(today)
yesterday.setDate(today.getDate() - 1)

const SubThemeSchema = z.object({
  id: z.number().optional().nullable().default(null),
  name: z.string().default(''),
  description: z.string().optional().nullable().default(null),
})

const SubThemeCreateSchema = z.object({
  id: z.string().optional().nullable().default(null),
  name: z.string().default(''),
})

export const ConferenceItemSchema = z.object({
  id: z.number().optional().nullable().default(null),
  // createdAt: z.string().default(''),
  // updatedAt: z.string().optional().default(''),
  code: z.string().default(''),
  name: z.string().default(''),
  description: z.string().default(''),
  // conference_date: z.transform(str => new Date(str)),
  conference_date: z.string().default(''),
  webpage: z.string().default(''),
  address: z.string().default(''),
  city: z.string().default(''),
  country: z.string().default(''),
  email: z.string().default(''),
  phone: z.string().default(''),
  submission_instruction: z.boolean().nullable().default(null),
  submission_additional_field: z.boolean().nullable().default(null),
  submission_template: z.boolean().nullable().default(null),
  submission_presenter: z.boolean().nullable().default(null),
  submission_multiple_author: z.boolean().nullable().default(null),
  submission_abstract_length: z.number().min(0).optional().default(0),
  submission_postal_address: z.boolean().nullable().default(null),
  submission_file_upload_field: z.boolean().nullable().default(null),
  sub_theme: z.array(z.any()).nullable().default([]),
  sub_theme_min_topic: z.number().optional().default(0),
  sub_theme_max_topic: z.number().optional().default(0),
  // submission_allow_new: z.boolean().nullable().default(null),
  submission_allow_updates: z.boolean().nullable().default(null),
  abstract_open_date: z.string().default(''),
  // abstract_open_date: z.string().transform((str) => new Date(str)),
  // abstract_open_date: z.coerce.date().default(yesterday),
  abstract_close_date: z.string().default(''),
  full_paper_open_date: z.string().default(''),
  full_paper_close_date: z.string().default(''),
})

export interface OptionType {
  value: string
  label: string
}

export const ConferenceSchema = z.object({
  data: z.array(ConferenceItemSchema).default([]),
  meta: MetaSchema.default({}),
  http: HttpResponseSchema.default({}),
  form: ConferenceItemSchema.default({}),
})

export type TSubTheme = z.infer<typeof SubThemeSchema>
export type TConference = z.infer<typeof ConferenceSchema>
export const ConferenceItemCreateRecipe = ConferenceItemSchema.omit({ id: true, sub_theme: true }).extend({
  sub_theme: z.array(SubThemeCreateSchema).default([]),
})
export type TConferenceItemCreate = z.infer<typeof ConferenceItemCreateRecipe>
export type TConferenceItemUpdate = z.infer<typeof ConferenceItemSchema>
export const ConferenceState: TConference = ConferenceSchema.parse({})

export const ENDPOINT = {
  CONFERENCE: `${module}`,
}

export const STATE = {
  CONFERENCE_LIST: 'conferences/list',
  CONFERENCE_DETAIL: 'conferences/detail',
  CONFERENCE_CREATE_DETAIL: 'conferences/create/detail',
  CONFERENCE_UPDATE_DETAIL: 'conferences/update/detail',
  CONFERENCE_DELETE: 'conferences/delete',
}

// export const defaultValues: any = {}
export const defaultValues: TConferenceItemCreate = {
  code: '',
  name: '',
  description: '',
  conference_date: '',
  webpage: '',
  address: '',
  city: '',
  country: '',
  email: '',
  phone: '',
  submission_instruction: false,
  submission_additional_field: false,
  submission_template: false,
  submission_presenter: false,
  submission_multiple_author: false,
  submission_abstract_length: 0,
  submission_postal_address: false,
  submission_file_upload_field: false,
  submission_allow_updates: true,
  sub_theme: [],
  sub_theme_min_topic: 0,
  sub_theme_max_topic: 0,
  // submission_allow_new: false,
  // submission_allow_updates: false,
  abstract_open_date: '',
  abstract_close_date: '',
  full_paper_open_date: '',
  full_paper_close_date: '',
}

// export const defaultValues: TConferenceItemCreate = {
//   code: 'SF-2024',
//   name: 'SEAAIR CONFERENCE 2024',
//   description: 'seaair conference 2024',
//   conference_date: '2024-01-01',
//   webpage: 'https://google.com',
//   address: 'address 1',
//   city: 'cty line 1',
//   country: 'MY',
//   email: 'admin@seaair.com',
//   phone: '+60192333',
//   submission_instruction: false,
//   submission_additional_field: false,
//   submission_template: false,
//   submission_presenter: false,
//   submission_multiple_author: false,
//   submission_abstract_length: 250,
//   submission_postal_address: false,
//   submission_file_upload_field: false,
//   sub_theme: [
//     {
//       id: '2024',
//       name: '2024-1',
//     },
//     {
//       id: '2024',
//       name: '2024-2',
//     },
//     {
//       id: '2024',
//       name: '2024-3',
//     },
//   ],
//   sub_theme_min_topic: 1,
//   sub_theme_max_topic: 3,
//   // submission_allow_new: false,
//   // submission_allow_updates: false,
//   abstract_open_date: '2024-01-01',
//   abstract_close_date: '2024-01-05',
//   full_paper_open_date: '2024-02-01',
//   full_paper_close_date: '2024-02-31',
// }
