import React from 'react'
import { Table } from 'src/packages/core/table'
import NotificationBar from 'src/packages/product/notification'
import { ConferenceListHook } from 'src/pages/conference/service'
import { TConferenceItemUpdate } from './data'
import ConferenceTopBar from './top-bar'
import Button from 'src/packages/core/button'
import { Modal } from '@hubz/product-modal'

const ConferenceListPage = () => {
  const { useHook, table } = ConferenceListHook
  const { handleRowClick, useNotification, useFetchData, useModalHook, data, setSearchTerm, searchTerm, useClearMessageOnUnmount } =
    useHook()
  const { isOpen, handleButtonClick, handleModalClose, handleConfirm, items } = useModalHook()
  const { columns } = table({ data: data, removeRecord: handleButtonClick })

  useFetchData()
  useClearMessageOnUnmount()
  const handleSearchSubmit = (searchTerm: string) => {
    setSearchTerm(searchTerm)
  }
  const filteredRes = data?.filter((item: TConferenceItemUpdate) => {
    console.log('item: ', item)
    return item.name.toLowerCase().includes(searchTerm.toLowerCase()) || item.email.toLowerCase().includes(searchTerm.toLowerCase())
  })

  return (
    <>
      <ConferenceTopBar onSearch={handleSearchSubmit} />
      <NotificationBar notification={useNotification} includeOrigin="conference" />
      {filteredRes && filteredRes.length > 0 && (
        <Table
          data={filteredRes}
          columns={columns}
          onRowClick={(item: any) => handleRowClick(item)}
          selectable={false}
          rowIdKey={(item: any) => item.id}
        />
      )}

      <Modal isOpen={isOpen} onClose={handleModalClose} hideOnClickOutside={false}>
        <h1 className="font-bold">Confirm Delete?</h1>
        <p>Are you sure you want to delete the following item(s)?</p>
        <ul className="list-disc ml-4">
          {items?.map((item: any) => (
            <li key={item.id}>{item.name}</li>
          ))}
        </ul>

        <div className="flex">
          <Button onClick={handleModalClose} type="button" fill="outline" className="mt-10 mr-2">
            Cancel
          </Button>
          <Button onClick={handleConfirm} type="button" className="mt-10 mr-2">
            Submit
          </Button>
        </div>
      </Modal>
    </>
  )
}

export default ConferenceListPage
