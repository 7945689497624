import { API_URL } from 'src/shared/data'
import { HttpService } from 'src/shared/http/service'
import { ENDPOINT, TRegister } from '../data'

const http = new HttpService()

const register = async (data: TRegister) => {
  return await http.post(`${API_URL}/${ENDPOINT.LOCAL_REGISTER}`, data)
}

const RegisterService = {
  register,
}

export default RegisterService
