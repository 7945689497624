import { ENDPOINT, TLogin } from 'src/pages/login/data'
import { API_URL } from 'src/shared/data'
import { HttpService } from 'src/shared/http/service'

const http = new HttpService()

const login = async (data: TLogin) => {
  return await http.post(`${API_URL}/${ENDPOINT.LOCAL_SIGN_IN}`, data)
}

const LoginService = {
  login,
}

export default LoginService
