// import { useRouteError } from "react-router-dom"
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { ROUTE_PATH } from 'src/shared/data'

const RedirectPage = () => {
  const navigate = useNavigate()
  // navigate(ROUTE_PATH.LOGIN, { replace: true })
  useEffect(() => {
    navigate(ROUTE_PATH.LOGIN, { replace: true })
  }, [])
  return <></>
}

export default RedirectPage
