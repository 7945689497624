import React, { useEffect, useState } from 'react'
import Button from 'src/packages/core/button'
import { Table } from 'src/packages/core/table'
import { Modal } from '@hubz/product-modal'
import NotificationBar from 'src/packages/product/notification'
import { TSubmissionItem } from './data'
import { ReviewerAssignmentHook, SubmissionListHook } from './service'
import SubmissionTopBar from './top-bar'
import ReviewerAssignmentModal from './views/reviewer-assignment-modal'

import { useMemo } from 'react'
import PaperStatusLegend from './views/user/paper-status-legend'
import moment from 'moment'
import { useAppSelector } from 'src/store/hooks'
import { SHARED } from 'src/shared/data'
import ReviewStatusLegend from './views/admin/review-status-legend'

const SubmissionPage = () => {
  const { useHook, useTable } = SubmissionListHook
  const { searchTerm, setSearchTerm, useFetchData, data, useNotification, handleRowClick, useClearMessageOnUnmount, useModalHook } =
    useHook()
  const { useAssignmentHook } = ReviewerAssignmentHook
  const { handleAssigneeModalToggle, isAssigneeOpen, handleAssigneeSubmit, selectedPaper, useFormObject } = useAssignmentHook()
  const { isOpen, handleModalClose, handleConfirm, items } = useModalHook()
  const { columns } = useTable({ data: data, handleAssigneeModalToggle: handleAssigneeModalToggle })
  const { activeRole } = useAppSelector((state) => state.user)
  const [selectedColumn, setSelectedColumn] = useState('')

  useFetchData()
  useClearMessageOnUnmount()

  const handleSearchSubmit = (column: string, value: string) => {
    setSelectedColumn(column)
    setSearchTerm(value)
    console.log('hand search submit', column, value)
  }

  const filteredRes = useMemo(() => {
    console.log('do filter res')
    if (data && data.data?.length > 0) {
      console.log('do filter res 1')

      const mappedData = data.data.map((item: TSubmissionItem) => ({
        ...item,
        submitted_date: moment(item.submitted_date).format('MMM DD, YYYY. hh:mm A'),
      }))

      if (selectedColumn === 'all' || !selectedColumn) {
        console.log('data.data', data.data)
        return mappedData
      } else {
        console.log('do filter res 2')
        return mappedData.filter((item: TSubmissionItem) => {
          if (!item.review) return false
          if (selectedColumn === 'reviewStatus') {
            return item.review.some((review: any) => review.reviewStatus.code === searchTerm)
          } else if (selectedColumn === 'type') {
            return item.paperType.code === searchTerm
          } else {
            return item
          }
        })
      }
    }
    return null
  }, [data, searchTerm, selectedColumn])

  return (
    <div id="reviewer-page">
      <SubmissionTopBar onSearch={handleSearchSubmit} activeRole={activeRole} />
      <NotificationBar notification={useNotification} includeOrigin="submission" />
      {activeRole.code === SHARED.ROLES.USER ? <PaperStatusLegend /> : <ReviewStatusLegend />}
      {filteredRes && (
        <Table
          data={filteredRes}
          columns={columns}
          onRowClick={(item: any) => handleRowClick(item)}
          selectable={true}
          rowIdKey={(item: any) => item.id}
          tableClass="mt-2"
        />
      )}

      <Modal isOpen={isOpen} onClose={handleModalClose} hideOnClickOutside={false}>
        <h1 className="font-bold">Confirm Delete?</h1>
        <p>Are you sure you want to delete the following item(s)?</p>
        <ul className="list-disc ml-4">
          {items?.map((item: any) => (
            <li key={item.id}>{item.name}</li>
          ))}
        </ul>

        <div className="flex">
          <Button onClick={handleModalClose} type="button" fill="outline" className="mt-10 mr-2">
            Cancel
          </Button>
          <Button onClick={handleConfirm} type="button">
            Submit
          </Button>
        </div>
      </Modal>

      <ReviewerAssignmentModal
        handleAssigneeModalToggle={handleAssigneeModalToggle}
        isOpen={isAssigneeOpen}
        handleAssigneeSubmit={handleAssigneeSubmit}
        selectedPaper={selectedPaper}
        useFormObject={useFormObject}
      />
    </div>
  )
}

export default SubmissionPage
