import React from 'react'
import { useAppDispatch, useAppSelector } from 'src/store/hooks'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { authSlice } from 'src/shared/auth/state'
const LogoutPage = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { isAuth } = useAppSelector((state) => state.auth)

  useEffect(() => {
    dispatch(authSlice.actions.logout('RESET_ALL_STATE'))
  }, [dispatch])

  useEffect(() => {
    console.log('use effect logout', isAuth)
    navigate('/login')
  }, [isAuth, navigate])

  return <></>
}

export default LogoutPage
