import React, { ReactNode, useState } from 'react'
import { Outlet } from 'react-router-dom'

interface LayoutProps {
  topBar?: ReactNode
  sideBar?: ReactNode
  children?: React.ReactNode
}

export const Layout = ({ topBar, sideBar, children }: LayoutProps) => {
  return (
    <div className="flex">
      {sideBar}
      <div className="container p-5">
        {topBar}
        {children}
        <Outlet />
      </div>
    </div>
  )
}
