import React from 'react'
import { PlusIcon } from '@heroicons/react/24/outline'
import Select from 'react-select'
import Button from 'src/packages/core/button'
import Icon from 'src/packages/core/icon'

type DynamicAuthorsProps = {
  styles: any
  useFieldArrayObject: {
    fields: any
    append: any
    remove: any
  }
  useFormObject: {
    register: any
    setValue: any
  }
  values: {
    country: any
    submission: any
  }
  disableInput: boolean
  conference: any
}

const DynamicAuthors = ({
  styles,
  useFieldArrayObject: { fields, append, remove },
  useFormObject: { register, setValue },
  values: { country, submission },
  disableInput,
  conference,
}: DynamicAuthorsProps) => {
  const handleAddAuthor = (event: any) => {
    event.preventDefault()
    if (fields.length >= 3) return
    const blankAuthor = {
      firstName: '',
      lastName: '',
      email: '',
      affiliation: '',
      country: '',
      isCorresponding: false,
      isPresenter: false,
      isMainAuthor: false,
    }
    append(blankAuthor)
    console.log('submit fields', fields)
  }

  return (
    <section>
      <div className={styles.addAuthorForm}>
        <div>
          <h2 className={styles.title}>First Author</h2>
          {conference?.submission_multiple_author && <p className={styles.caption}>Maximum of 3 authors per paper.</p>}
        </div>
        {conference?.submission_multiple_author && (
          <div className="w-full">
            {!disableInput && (
              <Button type="button" className="ml-6" onClick={(event: React.MouseEvent<HTMLButtonElement>) => handleAddAuthor(event)}>
                <Icon icon={<PlusIcon className="w-5 mt-0.5" />} css={'w-7'} /> Add Author
              </Button>
            )}
          </div>
        )}
      </div>
      <div className="w-4/5">
        {fields.map((field: any, index: number) => {
          return (
            <div key={field.id} className="grid grid-flow-row-dense grid-cols-2 gap-4">
              <div className="flex justify-between items-center col-span-2">
                <div>
                  {index == 1 && <h2 className={styles.title}>Second Author</h2>}
                  {index == 2 && <h2 className={styles.title}>Third Author</h2>}
                </div>
                {index > 0 && !disableInput && (
                  <Button type="button" fill="outline" className="px-2" onClick={() => remove(index)}>
                    Remove Participant
                  </Button>
                )}
              </div>
              <div className={styles.div}>
                <label htmlFor={`paperAuthor.${index}.firstName`} className={styles.label}>
                  First Name
                </label>
                <input {...register(`paperAuthor.${index}.firstName`)} className={styles.input} placeholder="" disabled={disableInput} />
              </div>
              <div className={styles.div}>
                <label htmlFor={`paperAuthor.${index}.lastName`} className={styles.label}>
                  Last Name
                </label>
                <input {...register(`paperAuthor.${index}.lastName`)} className={styles.input} placeholder="" disabled={disableInput} />
              </div>

              <div className={styles.div}>
                <label htmlFor={`paperAuthor.${index}.email`} className={styles.label}>
                  Email
                </label>
                <input {...register(`paperAuthor.${index}.email`)} className={styles.input} placeholder="" disabled={disableInput} />
              </div>
              <div className={styles.div}>
                <label htmlFor={`paperAuthor.${index}.country`} className={styles.label}>
                  Country
                </label>
                <Select
                  name={`paperAuthor.${index}.country`}
                  closeMenuOnSelect={true}
                  options={country}
                  placeholder="Select your country"
                  getOptionLabel={(option: any) => option.name}
                  getOptionValue={(option: any) => option.name}
                  value={country.find((option: any) => option.name === field.country)}
                  onChange={(selectedOption) => {
                    setValue(`paperAuthor.${index}.country`, selectedOption.name)
                  }}
                  isDisabled={disableInput}
                />
              </div>
              <div className={styles.div}>
                <label htmlFor={`paperAuthor.${index}.affiliation`} className={styles.label}>
                  Affiliation
                </label>
                <input {...register(`paperAuthor.${index}.affiliation`)} className={styles.input} placeholder="" disabled={disableInput} />
              </div>

              <div className="align-middle inline-block gap-4">
                <label className={styles.checkboxLabel}>
                  <input
                    type="checkbox"
                    className="form-checkbox"
                    defaultChecked={submission?.paperAuthor[index]?.isCorresponding ?? false}
                    {...register(`paperAuthor.${index}.isCorresponding`)}
                    disabled={disableInput}
                  />
                  <span className="ml-2 mr-4">Corresponding</span>
                </label>
                <label className={styles.checkboxLabel}>
                  <input
                    type="checkbox"
                    className="form-checkbox"
                    defaultChecked={submission?.paperAuthor[index]?.isPresenter ?? false}
                    {...register(`paperAuthor.${index}.isPresenter`)}
                    disabled={disableInput}
                  />
                  <span className="ml-2 mr-4">Presenter</span>
                </label>
                <label className={styles.checkboxLabel}>
                  <input
                    type="checkbox"
                    className="form-checkbox"
                    defaultChecked={submission?.paperAuthor[index]?.isMainAuthor ?? false}
                    {...register(`paperAuthor.${index}.isMainAuthor`)}
                    disabled={disableInput}
                  />
                  <span className="ml-2 mr-4">Author</span>
                </label>
              </div>

              {fields.length - 1 !== index && <hr className="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700 col-span-2" />}
            </div>
          )
        })}
      </div>
    </section>
  )
}

export default DynamicAuthors
