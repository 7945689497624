import React from 'react'
import { API_URL } from 'src/shared/data'
import { HttpService } from 'src/shared/http/service'
import { ENDPOINT, TConferenceItemCreate, TConferenceItemUpdate } from '../data'

const http = new HttpService()
const getConferences = async (filter?: any) => {
  return await http.get(`${API_URL}/${ENDPOINT.CONFERENCE}?year=${filter.year ?? ''}`)
}
const getConferenceDetail = async (id: number) => {
  return await http.get(`${API_URL}/${ENDPOINT.CONFERENCE}/${id}`)
}
const createConference = async (data: TConferenceItemCreate) => {
  return await http.post(`${API_URL}/${ENDPOINT.CONFERENCE}`, data)
}
const updateConference = async (data: TConferenceItemUpdate) => {
  const { id, ...rest } = data
  return await http.patch(`${API_URL}/${ENDPOINT.CONFERENCE}/${id}`, rest)
}
const deleteConference = async (id: number) => {
  return await http.delete(`${API_URL}/${ENDPOINT.CONFERENCE}/${id}`)
}

const ConferenceService = {
  getConferences,
  getConferenceDetail,
  createConference,
  updateConference,
  deleteConference,
}
export default ConferenceService
