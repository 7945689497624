import { API_URL } from 'src/shared/data'
import { HttpService } from 'src/shared/http/service'
import { ENDPOINT } from '../data'

const http = new HttpService()

const forgotPassword = async (email: string) => {
  return await http.get(`${API_URL}/${ENDPOINT.FORGOT_PASSWORD}/?email=${email}`)
}

export const ForgotPasswordService = {
  forgotPassword,
}
