import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { ResetPasswordState, STATE, TResetPasswordPayload, TResetPasswordSchema } from '../data'
import { ResetPasswordService } from '../service'
import { setMessage } from 'src/packages/product/notification/state'
import { HTTP_STATUS } from 'src/shared/http/data'

export const resetPassword = createAsyncThunk(STATE.RESET_PASSWORD, async (data: TResetPasswordPayload, thunkAPI) => {
  try {
    return (await ResetPasswordService.resetPassword(data)).data
  } catch (error: any) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    thunkAPI.dispatch(setMessage({ message: message, origin: 'resetPassword', status: 'error' }))
    return thunkAPI.rejectWithValue(message)
  }
})

export const resetPasswordSlice = createSlice({
  name: 'resetPassword',
  initialState: ResetPasswordState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(resetPassword.fulfilled, (state: TResetPasswordSchema, action: PayloadAction<any, any>) => {
        state.http.status = HTTP_STATUS.SUCCESS
        state.token = action.payload
      })
      .addCase(resetPassword.rejected, (state: TResetPasswordSchema) => {
        state.http.status = HTTP_STATUS.FAIL
        state.token = ''
      })
  },
})

const { reducer } = resetPasswordSlice
export default reducer
