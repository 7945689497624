import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { setMessage } from '@hubz/product-notification/state'
import { getConferences } from 'src/pages/conference/state'
import { useAppDispatch, useAppSelector } from 'src/store/hooks'
import { TConferenceItemUpdate } from 'src/pages/conference/data'
import { TRole } from '../data/user.data'
import { getRoles } from 'src/pages/role/state'
import { getUserAccountStatusList } from '../state/account-status'
import { TUserAccountStatus } from '../data'
import { createUserAccount, updateAccountPassword, updateUserAccount } from '../state/profile'
import { useNavigate, useParams } from 'react-router-dom'
import { TAccountPassword } from 'src/pages/user/data/account-password.data'
import { ROUTE_PATH, SHARED } from 'src/shared/data'
import { HTTP_STATUS } from 'src/shared/http/data'
import { finishLoading, startLoading } from '@/packages/product/full-page-loader/state'
import { setToast } from '@/packages/product/toast/state'

const buildDataBody = (data: any) => {
  const body = {
    firstName: data.firstName,
    lastName: data.lastName,
    email: data.email,
    gender: Number(data.gender),
    phone: data.phone,
    affiliation: data.affiliation,
    addressBook: [
      {
        label: 'default',
        addressLine1: data.addressLine1,
        addressLine2: data.addressLine2,
        city: data.city,
        postcode: data.postcode,
        country: data.country,
        id: data.addressId,
      },
    ],
    userRole: data.roleManagement.filter((item: any) => item.conference != '' || item.role != '' || item.userAccountStatus != ''),
  }
  console.log('build data body', body)
  return body
}
export const useHook = () => {
  const dispatch = useAppDispatch()
  const [conference, setConference] = useState<TConferenceItemUpdate[]>([])
  const [roles, setRoles] = useState<TRole[]>([])
  const [userAccountStatus, setUserAccountStatus] = useState<TUserAccountStatus[]>([])
  const [selectedConference, setSelectedConference] = useState('')
  const [selectedRole, setSelectedRole] = useState('')
  const [selectedUserAccountStatus, setSelectedUserAccountStatus] = useState('')
  const [rolesArray, setRolesArray] = useState<Array<object>>([])
  const [disableAddRole, setDisableAddRole] = useState<boolean>(true)
  const navigate = useNavigate()
  const { id } = useParams()
  const userState = useAppSelector((state) => state.user)
  const {
    form: { addressBook },
    http: reviewerHttp,
  } = useAppSelector((state) => state.reviewer)

  const useFetchData = () => {
    useEffect(() => {
      let conference: any
      let roles: any
      let userAccountStatus: any
      const getConferencesAsync = async () => {
        conference = await dispatch(getConferences({}))
        if (!conference.payload) dispatch(setMessage('No records found'))
        setConference(conference.payload.data)
        return conference.payload
      }

      const getRolesAsync = async () => {
        roles = await dispatch(getRoles())
        if (!roles.payload) dispatch(setMessage('No records found'))
        setRoles(roles.payload.data)
        return roles.payload
      }

      const getUserAccountStatusAsync = async () => {
        userAccountStatus = await dispatch(getUserAccountStatusList())
        if (!userAccountStatus.payload) dispatch(setMessage('No records found'))
        setUserAccountStatus(userAccountStatus.payload.data)
        return userAccountStatus.payload
      }

      getConferencesAsync()
      getRolesAsync()
      getUserAccountStatusAsync()

      return () => {
        conference
        roles
        userAccountStatus
      }
    }, [dispatch])
  }

  useEffect(() => {
    if (selectedConference && selectedRole && selectedUserAccountStatus) {
      setDisableAddRole(false)
    } else {
      setDisableAddRole(true)
    }
  }, [selectedConference, selectedRole, selectedUserAccountStatus])

  useEffect(() => {
    rolesArray
  }, [rolesArray])

  const changeConference = (event: ChangeEvent<HTMLSelectElement>) => {
    setSelectedConference(event.target.value)
  }
  const changeRole = (event: ChangeEvent<HTMLSelectElement>) => {
    setSelectedRole(event.target.value)
  }
  const changeUserAccountStatus = (event: ChangeEvent<HTMLSelectElement>) => {
    setSelectedUserAccountStatus(event.target.value)
  }

  const resetSelections = () => {
    setSelectedConference('')
    setSelectedRole('')
    setSelectedUserAccountStatus('')
  }

  const addRole = () => {
    setRolesArray([...rolesArray, { conference: selectedConference, role: selectedRole, userAccountStatus: selectedUserAccountStatus }])
    resetSelections()
  }

  const removeRole = (index: number) => {
    setRolesArray(rolesArray.filter((_, i) => i !== index))
  }

  const useBack = () => {
    navigate(-1)
  }

  const useUpdate = useCallback(async (data: any) => {
    console.log('get param id', data)
    try {
      dispatch(startLoading())
      console.log('address book data')
      const body = buildDataBody({ ...data, addressId: addressBook[0]?.id })
      // const body = buildDataBody({ ...data })
      console.log('send update', body)
      const response = await dispatch(updateUserAccount({ ...body, userId: id }))
      console.log('update user account ', response)
      if (response.payload.http.status === HTTP_STATUS.SUCCESS && userState.activeRole.code != SHARED.ROLES.USER) {
        dispatch(setToast({ message: 'User account updated successfully', variant: 'success' }))
        navigate(ROUTE_PATH.REVIEWER_MANAGEMENT)
      } else {
        dispatch(setToast({ message: response.payload.http.message, variant: 'error' }))
      }
    } catch (error) {
      console.log('error callback', error)
    } finally {
      dispatch(finishLoading())
    }
  }, [])

  const useCreate = useCallback(
    async (data: any) => {
      try {
        dispatch(startLoading())
        const body = buildDataBody(data)
        const response = await dispatch(createUserAccount(body))
        if (response.payload.http.status === HTTP_STATUS.SUCCESS) {
          dispatch(setToast({ message: 'User account created successfully', variant: 'success' }))
          navigate(ROUTE_PATH.REVIEWER_MANAGEMENT)
        } else {
          if (typeof response.payload.http.message === 'object' && Object.values(response.payload.http.message).length > 0) {
            response.payload.http.message.forEach((message: any) => {
              dispatch(setToast({ message: message, variant: 'error' }))
            })
          } else if (response.payload.http.message) {
            dispatch(setToast({ message: response.payload.http.message, variant: 'error' }))
          }
        }
      } catch (error) {
        console.log('error callback', error)
      } finally {
        dispatch(finishLoading())
      }
    },
    [dispatch, navigate]
  )

  return {
    useFetchData,
    conference,
    selectedConference,
    setSelectedConference,
    changeConference,
    roles,
    selectedRole,
    setSelectedRole,
    changeRole,
    userAccountStatus,
    selectedUserAccountStatus,
    setSelectedUserAccountStatus,
    changeUserAccountStatus,
    addRole,
    removeRole,
    rolesArray,
    disableAddRole,
    useUpdate,
    useCreate,
    useBack,
  }
}

export const useAccountPassword = () => {
  const dispatch = useAppDispatch()
  // const { user } = useAppSelector((state) => state.user)
  const { id } = useParams()
  const useSubmit = useCallback(
    async (data: TAccountPassword) => {
      try {
        const body = { password: data.password, passwordConfirm: data.passwordConfirm }
        console.log('ProfileHook update password', id)
        const response = await dispatch(updateAccountPassword({ ...body, userId: id }))
        if (response.payload.http.status === HTTP_STATUS.SUCCESS) {
          dispatch(setToast({ message: 'Password Updated', variant: 'success' }))
        } else {
          dispatch(setToast({ message: response.payload.http.message, variant: 'error' }))
        }
      } catch (error) {
        console.log('account password error', error)
      }
    },
    [dispatch]
  )

  return { useSubmit }
}

const ProfileHook = {
  useHook,
  useAccountPassword,
}
export default ProfileHook
