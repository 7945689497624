import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import React from 'react'
import { setMessage } from '@hubz/product-notification/state'
import { STATE, TUser, UserState } from '../data/user.data'
import { HTTP_STATUS } from 'src/shared/http/data'
import UserService from '../service'
import { createUserProfileReducer } from './profile'

export const getUserDetails = createAsyncThunk(STATE.USER_DETAILS, async (_, thunkAPI) => {
  try {
    return (await UserService.getUserDetails()).data
  } catch (error: any) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    thunkAPI.dispatch(setMessage({ message: message, origin: 'user', status: 'error' }))
    return thunkAPI.rejectWithValue(message)
  }
})

export const userSlice = createSlice({
  name: 'user',
  initialState: UserState,
  reducers: {
    setUserRoles: (state: TUser, action: PayloadAction<any, any>) => {
      console.log('set userROles', action.payload)
      state.activeRole = action.payload.role
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserDetails.fulfilled, (state: TUser, action: any) => {
        // state.http.status = HTTP_STATUS.SUCCESS
        const { userRole, ...rest } = action.payload
        state.user = rest
        state.role = userRole
      })
      .addCase(getUserDetails.rejected, (state, action) => {
        state.http.status = HTTP_STATUS.FAIL
        console.log('error msg yse detail', action.error)
      })
      .addMatcher(
        (action) => action.type.endsWith('/fulfilled') || action.type.endsWith('/rejected'),
        (state: any, action: any) => {
          createUserProfileReducer()[action.type]?.(state, action?.payload)
        }
      )
  },
})

const { reducer } = userSlice
export default reducer
