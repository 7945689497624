import { useEffect } from "react";
import { useAppDispatch } from 'src/store/hooks'
import { getEmailTemplate } from "../state";

const useEmailTemplateHook = () => {
    const dispatch = useAppDispatch()


    const useFetchData = () => {
        useEffect(() => {
            let emailTemplate: any;

            const getEmailTemplateAsync = async () => {
                emailTemplate = await dispatch(getEmailTemplate());
            };
            getEmailTemplateAsync();
            return () => {
                emailTemplate;
            };
        }, []);
    };

    
    return {
        useFetchData,
    };
}


const EmailTemplateHook = {
    useEmailTemplateHook,
}

export default EmailTemplateHook